import {
  faTrashAlt,
  faWindowMaximize,
} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowsAlt,
  faCaretDown,
  faCaretRight,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { arrayMoveMutable } from "array-move";
import { computed, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { useDrag } from "react-dnd";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import styled from "styled-components";
import { GENERIC_COMPONENT } from "./Component";
import {
  ComponentItemProps,
  DeleteItemButton,
  ExpandButton,
  TreeItem,
  TreeItemList,
} from "./ComponentTree";
import { IEditableComponent } from "./EditableComponent";
import { NewItem } from "./NewComponents";
import {
  BaseTabsHeader,
  TabContent,
  TabItemContainer,
  TabsContainer,
  TabSpec,
  TabsSpec,
  TABS_COMPONENT,
} from "./Tabs";
import { newEmptyVertical } from "./Vertical";
import { Vertical, VerticalItem } from "./VerticalEdit";

const TabTitle = styled.span`
  padding-left: 5px;
  padding-right: 5px;
`;

const InlineEditTextbox = styled.input`
  display: inherit;
  position: relative;
  min-width: inherit;
  max-width: inherit;
  vertical-align: top;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit;
  font: inherit;
  resize: none;
  background: none;
  outline: none;
  border: none;
  padding: 0;
`;

const TabsHeader = SortableContainer<any>(BaseTabsHeader);

const DragHandle = SortableHandle((props: {}) => (
  <FontAwesomeIcon icon={faArrowsAlt} style={{ cursor: "grab" }} />
));

export function makeNewTab() {
  return structuredClone({ name: "New Tab", content: newEmptyVertical() });
}

export const EditComponent = observer((props: { spec: TabsSpec }) => {
  const [selectedTab, setSelectedTab] = useState(props.spec.tabs[0]);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: any) => {
      runInAction(() => {
        arrayMoveMutable(props.spec.tabs, oldIndex, newIndex);
      });
    },
    [props.spec.tabs]
  );

  return (
    <TabsContainer>
      <TabsHeader onSortEnd={onSortEnd} axis="x" useDragHandle>
        {props.spec.tabs.map((tab, i) => (
          <EditableTab
            spec={tab}
            index={i}
            selected={selectedTab === tab}
            onClick={() => setSelectedTab(tab)}
            onDelete={() => {
              runInAction(() => {
                if (props.spec.tabs.length === 1) {
                  props.spec.tabs[0] = makeNewTab();
                } else {
                  props.spec.tabs.splice(i, 1);
                }
                if (selectedTab === tab) {
                  setSelectedTab(props.spec.tabs[0]);
                }
              });
            }}
          />
        ))}
        <TabItemContainer
          selected={false}
          onClick={() => {
            props.spec.tabs.push(makeNewTab());
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </TabItemContainer>
      </TabsHeader>
      <TabContent>
        <Vertical spec={selectedTab.content} />
      </TabContent>
    </TabsContainer>
  );
});

export const EditableTab = SortableElement<any>(
  observer(
    (props: {
      spec: TabSpec;
      selected: boolean;
      onClick?: () => void;
      onDelete?: () => void;
    }) => {
      const [isCompEditing, setIsEditing] = useState(false);
      return (
        <TabItemContainer selected={props.selected} onClick={props.onClick}>
          <DragHandle />
          {isCompEditing ? (
            <TabTitle>
              <InlineEditTextbox
                type="text"
                value={props.spec.name}
                onChange={(event) =>
                  (props.spec.name = event.currentTarget.value)
                }
                onBlur={() => setIsEditing(false)}
              />
            </TabTitle>
          ) : (
            <TabTitle
              onClick={() => {
                if (props.selected) setIsEditing(true);
              }}
            >
              {props.spec.name}
            </TabTitle>
          )}
          <FontAwesomeIcon
            onClick={(e) => {
              e.stopPropagation();
              props.onDelete?.();
            }}
            icon={faTrash}
          />
        </TabItemContainer>
      );
    }
  )
);

const Properties = observer((props: { spec: TabsSpec }) => {
  return <div>This component has no properties</div>;
});

const Item = observer((props: ComponentItemProps<TabsSpec>) => {
  const [isExpanded, setExpanded] = useState(true);
  const parentRefs = computed(() => [...props.parentRefs, props.spec]);
  return (
    <>
      <TreeItem onClick={props.onSelect} isActive={props.selected} isExpandable>
        <ExpandButton onClick={() => setExpanded(!isExpanded)}>
          <FontAwesomeIcon icon={isExpanded ? faCaretDown : faCaretRight} />
        </ExpandButton>
        {"  "}
        <FontAwesomeIcon icon={faWindowMaximize} />
        <span> Tabs </span>
        <DeleteItemButton
          onClick={() => {
            props.onDelete();
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </DeleteItemButton>
      </TreeItem>
      {isExpanded ? (
        <TreeItemList paddingLeft={8}>
          {props.spec.tabs.map((tab, i) => (
            <VerticalItem
              spec={tab.content}
              name={tab.name}
              treeState={props.treeState}
              parentRefs={parentRefs.get()}
            />
          ))}
        </TreeItemList>
      ) : null}
    </>
  );
});

const New = (props: {}) => {
  const [, drag] = useDrag(() => ({
    type: GENERIC_COMPONENT,
    item: {
      spec: {
        type: TABS_COMPONENT,
        tabs: [{ name: "New Tab", content: newEmptyVertical() }],
      },
    },
  }));
  return (
    <NewItem isActive={false} ref={drag}>
      <FontAwesomeIcon icon={faWindowMaximize} />
      <span> Tabs </span>
    </NewItem>
  );
};

export const TabsComponent: IEditableComponent<TabsSpec> = {
  component: EditComponent,
  properties: Properties,
  item: Item,
  new: New,
};
