"use client";
import { default as React, useContext } from "react";
import styled from "styled-components";
import { IComponent } from "../components/Component";
import {
  Vertical,
  VerticalDI,
  verticalDI,
  VerticalSpec,
} from "../components/Vertical";
import { Container } from "react-bootstrap";
import { PageContext } from "../PageContext";

export const PAGE_NORMAL = "page_normal";
export interface PageNormalSpec {
  type: typeof PAGE_NORMAL;
  content: VerticalSpec;
}

const EditButtonContainer = styled.div`
  position: absolute;
  z-index: 1000;
  right: 100px;
  top: 170px;
  @media (max-width: 1096px) {
    display: none;
  }
`;

const EditButton = styled.button`
  background-color: blue;
  color: white;
  border-width: 0;
  outline: none;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 100px;
  height: 30px;
`;

export const PageNormalRender = (props: VerticalDI<PageNormalSpec>) => {
  const VerticalDIComp = props.Vertical;
  const { canEdit, setEditing, isEditing } = useContext(PageContext);

  return (
    <Container>
      <EditButtonContainer>
        {canEdit && !isEditing ? (
          <EditButton onClick={() => setEditing(true)}>Editieren</EditButton>
        ) : null}
      </EditButtonContainer>
      <VerticalDIComp spec={props.spec.content} />
    </Container>
  );
};

export const PageNormal: IComponent<PageNormalSpec> = {
  component: verticalDI(PageNormalRender, Vertical),
};
