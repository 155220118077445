import React from "react";

const VereinsmaterialPage = () => {
  return (
    <div className="container mb-5 pt-5">
      <h1 className="text-primary mb-4">Vereinsmaterial</h1>

      <div className="row">
        <div className="col-md-6">
          <h3>Verfügbares Material</h3>
          <ul className="list-unstyled">
            <li>• Gitter Rechteckig</li>
            <li>• Gitter Quadratisch</li>
            <li>• Gitter Ecke</li>
            <li>• A3 Halter</li>
            <li>• Verlängerungskabel</li>
            <li>• Steckerleisten</li>
          </ul>

          <div className="mt-5">
            <h3>Kontaktperson</h3>
            <p>
              Wolfram Fiedler
              <br />
              <a href="mailto:wolfram.fiedler@lwzo.ch">
                wolfram.fiedler@lwzo.ch
              </a>
            </p>
          </div>
        </div>

        <div className="col-md-6">
          <img
            src="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2022/Vereinsmaterial/Gitterueberblick.jpg"
            alt="Übersicht Gitter"
            className="img-fluid rounded shadow"
          />
        </div>
      </div>
    </div>
  );
};

export default VereinsmaterialPage;