import { faNewspaper, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React from "react";
import { useDrag } from "react-dnd";
import { GENERIC_COMPONENT } from "./Component";
import {
  ComponentItemProps,
  DeleteItemButton,
  TreeItem,
} from "./ComponentTree";
import { DragSpec, IEditableComponent } from "./EditableComponent";
import { NewItem } from "./NewComponents";
import { BarNumberProperty, BarTextProperty } from "./PropertiesBar";
import { Component, NewsSpec, NEWS_COMPONENT } from "./News";

const EditComponent = observer(Component);

const Properties = observer((props: { spec: NewsSpec }) => {
  return (
    <div>
      <BarTextProperty
        name="News folder path"
        value={props.spec.prefix}
        onChange={(newValue) => (props.spec.prefix = newValue)}
      />
      <BarNumberProperty
        name="Maximum number of news articles"
        value={props.spec.limit}
        onChange={(newValue) => (props.spec.limit = newValue)}
      />
    </div>
  );
});

const Item = observer((props: ComponentItemProps<NewsSpec>) => {
  return (
    <>
      <TreeItem onClick={props.onSelect} isActive={props.selected}>
        <FontAwesomeIcon icon={faNewspaper} />
        <span> News </span>
        <DeleteItemButton
          onClick={() => {
            props.onDelete();
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </DeleteItemButton>
      </TreeItem>
    </>
  );
});

const New = (props: {}) => {
  const [, drag] = useDrag<DragSpec, unknown, unknown>(() => ({
    type: GENERIC_COMPONENT,
    item: {
      spec: {
        type: NEWS_COMPONENT,
        prefix: "/news/",
        limit: 5,
      },
    },
  }));
  return (
    <NewItem
      isActive={false}
      ref={(elem) => {
        drag(elem);
      }}
    >
      <FontAwesomeIcon icon={faNewspaper} />
      <span> News </span>
    </NewItem>
  );
};

export const NewsComponent: IEditableComponent<NewsSpec> = {
  component: EditComponent,
  properties: Properties,
  item: Item,
  new: New,
};
