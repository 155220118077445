import React from "react";
import styled from "styled-components";
import { IComponent } from "./Component";
import { NotAuthorizedComponentSpec } from "./standardcomponents";
import { signIn, useUser } from "../../utils/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";

const Container = styled.div`
  margin-top: 5vh;
  text-align: center;
`;

const LargeHeader = styled.h1`
  font-size: 40px;
`;

const LargeNumber = styled.div`
  font-size: 150px;
  color: #888;
`;

const LoginButton = () => {
  return (
    <a
      onClick={(e) => {
        e.preventDefault();
        signIn();
      }}
      style={{ cursor: "pointer" }}
    >
      <FontAwesomeIcon icon={faUser} /> Login
    </a>
  );
};

const Component = (props: { spec: NotAuthorizedComponentSpec }) => {
  const user = useUser();
  return (
    <Container>
      <LargeNumber>403</LargeNumber>
      <LargeHeader>Zugriff verboten</LargeHeader>
      <div>Sie haben keine Berechtigung auf diese Seite zuzugreifen</div>
      <div style={{ marginBottom: 30 }} />
      {user === null ? <LoginButton /> : null}
    </Container>
  );
};

export const NotAuthorizedComponent: IComponent<NotAuthorizedComponentSpec> = {
  component: Component,
};
