import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense, useState } from "react";
import styled from "styled-components";
import { IComponent } from "../../Component";

const ShakaPlayer = React.lazy(() => import("./shaka-player"));

export const VIDEO_COMPONENT = "video";
export interface VideoSpec {
  type: typeof VIDEO_COMPONENT;
  dashManifest: string | null;
  customMaxWidth?: number;
  customMaxHeight?: number;
  videoURL: string | null;
}

export const VideoContainer = styled.div<{
  maxWidth?: number;
  maxHeight?: number;
}>`
  display: block;
  margin-top: 15px;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth + "px" : "100%")};
  picture {
    display: block;
  }
  img {
    max-height: ${(props) =>
      props.maxHeight ? props.maxHeight + "px" : "100%"};
    max-width: 100%;
  }
`;

export const VideoPlaceholder = styled.div`
  border: 2px dashed #888;
  border-radius: 15px;
  background: white;
  width: 200px;
  height: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
`;

const Component = (props: { spec: VideoSpec }) => {
  const [error, setError] = useState<string | null>(null);

  if (error) {
    return (
      <VideoPlaceholder>
        <div>
          <div>
            <FontAwesomeIcon
              icon={faTimes}
              size="3x"
              style={{ marginBottom: "10px" }}
            />
          </div>
          <div>Es ist ein Fehler beim laden des Videos aufgetreten</div>
        </div>
      </VideoPlaceholder>
    );
  }

  if (props.spec.dashManifest) {
    return (
      <VideoContainer
        maxWidth={props.spec.customMaxWidth}
        maxHeight={props.spec.customMaxHeight}
      >
        <Suspense>
          <ShakaPlayer src={props.spec.dashManifest} onError={setError} />
        </Suspense>
      </VideoContainer>
    );
  }
  if (props.spec.videoURL) {
    return (
      <VideoContainer
        maxWidth={props.spec.customMaxWidth}
        maxHeight={props.spec.customMaxHeight}
      >
        <Suspense>
          <ShakaPlayer src={props.spec.videoURL + ".mpd"} onError={setError} />
        </Suspense>
      </VideoContainer>
    );
  }
  return <h1>Kein Video angegeben</h1>;
};

export const VideoComponent: IComponent<VideoSpec> = {
  component: Component,
};
