import { PublicServiceClientImpl, GrpcWebImpl, User, Image } from "./generated/src/api/proto/api";

let rpc;
if (typeof window !== "undefined") {
  rpc = new GrpcWebImpl(window.location.origin + "/api", {
    debug: false,
  });
}

export const PubAPI = new PublicServiceClientImpl(rpc);

export const GetProfessionName = (user: User | undefined): string => {
  if (user === undefined || user.Profession === undefined) {
    return "Unknown";
  }
  if (user.Gender === "Male") {
    return user.Profession.MaleTitle;
  } else if (user.Gender === "Female") {
    return user.Profession.FemaleTitle;
  } else {
    return user.Profession.MaleTitle + " / " + user.Profession.FemaleTitle;
  }
}

export function getAppropriateImage(image: Image, width: number, height: number): string {
  if(!image.DimensionsProcessed || image.DimensionsProcessed.length === 0) {
    return image.BaseURL;
  }
  const aspectRatio = image.Width / image.Height;
  const drivingDimension =
    (width > height ? width : height) * window.devicePixelRatio;
  const selectedDimension = image.DimensionsProcessed.find(
    (x) => {
      let imgWidth: number;
      let imgHeight: number;
      if (aspectRatio > 1) {
        // x is width
        imgWidth = x;
        imgHeight = x / aspectRatio;
      } else {
        // x is height
        imgHeight = x;
        imgWidth = x / aspectRatio;
      }

      if (width > height) {
        return imgWidth >= (width* window.devicePixelRatio);
      } else {
        return  imgHeight >= (height * window.devicePixelRatio);
      }
    } 
  ) ?? image.DimensionsProcessed[image.DimensionsProcessed.length - 1];
  return image.BaseURL + "-" + selectedDimension;
}