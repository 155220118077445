import React from "react";
import styled from "styled-components";

const IframeContainer = styled.div`
  width: 100%;
  height: calc(100vh - 115px); // Subtracting combined height of nav bars
  position: relative;
`;

const FullIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const BackendPage = () => {
  return (
    <>
      <IframeContainer>
        <FullIframe
          src="https://backend.lwzo.ch"
          title="LWZO Backend"
          allow="fullscreen"
          referrerPolicy="unsafe-url"
          sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts allow-popups"
        />
      </IframeContainer>
    </>
  );
};

export default BackendPage;