import React from "react";
import logo from "../assets/logo.png";
import styled from "styled-components";
import {
  Container,
  Nav,
  NavItem,
  NavDropdown,
  Button,
  Navbar,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useResizeDetector } from "react-resize-detector";
import { signIn, signOut, useUser } from "../utils/auth";
import { Link } from "../utils/router";

const TopNavbar = styled(Navbar)`
  background-color: #f8f9fa;
  height: 35px;
  border-bottom: 1px solid #dee2e6;
  .quick-links {
    display: flex;
    align-items: center;
  }
  .right-links {
    display: flex;
    align-items: center;
  }
  @media (max-width: 767.98px) {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  padding: 0.5rem;
`;

const NavContainer = styled(Nav)`
  // @media (min-width: 768px) and (max-width: 989.98px) {
  //   &.left-nav {
  //     margin-right: auto;
  //   }

  //   &.right-nav {
  //     margin-left: auto;
  //   }
  // }
`;

function Navigation() {
  const { width, ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: "throttle",
    refreshRate: 100,
  });

  // 990 or 976
  const showProjectsDropdown = width ? width < 977 : false;

  const user = useUser();

  return (
    <div ref={ref} className="mb-4">
      <TopNavbar bg="light" expand="md">
        <Container>
          <Nav className="quick-links">
            <Nav.Link as="span">
              <Link href="/fuer-aussteller">Für Aussteller</Link>
            </Nav.Link>
            <Nav.Link as="span">
              <Link href="/besucher/infos-fuer-schulklassen">Schulklassen</Link>
            </Nav.Link>
            <Nav.Link as="span">
              <Link href="/teilnehmer/lehrbetriebe">Lehrbetriebe</Link>
            </Nav.Link>
            <Nav.Link as="span">
              <Link href="/ueber-uns/presse-und-downloads">Medien</Link>
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto right-links">
            <Nav.Link as="span">
              <Link href="https://backend.lwzo.ch">Backend</Link>
            </Nav.Link>
            <Nav.Link as="span">
              {user === null ? (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    signIn();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faUser} /> Login
                </a>
              ) : (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    signOut();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon icon={faUser} /> {user.name}
                </a>
              )}
            </Nav.Link>
          </Nav>
        </Container>
      </TopNavbar>

      <Navbar bg="light" data-bs-theme="light" expand="md">
        <Container>
          <div className="d-md-none">
            <Nav.Link as="span">
              <Link href="/">
                <img src={logo} height="50" alt="logo" />
              </Link>
            </Nav.Link>
          </div>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse
            className="justify-content-between"
            id="left-navbar-nav"
          >
            <NavContainer className="left-nav">
              <NavDropdown title="Besucher" id="basic-nav-dropdown">
                <NavDropdown.Item as="span">
                  <Link href="/besucher/informationen">Informationen</Link>
                </NavDropdown.Item>
                <NavDropdown.Item as="span">
                  <Link href="/besucher/lehrstelleninfos">
                    Berufs/Lehrstelleninfos
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item as="span">
                  <Link href="/besucher/infos-fuer-schulklassen">
                    Infos für Schulklassen
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Teilnehmende" id="teilnehmende-dropdown">
                <NavDropdown.Item as="span">
                  <Link href="/teilnehmer/zeig-was-du-kannst">
                    Zeig was du kannst!
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item as="span">
                  <Link href="/teilnehmer/bewertung-und-preise">
                    Bewertung und Preise
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item as="span">
                  <Link href="/teilnehmer/reglement">Reglement</Link>
                </NavDropdown.Item>
                <NavDropdown.Item as="span">
                  <Link href="/teilnehmer/anmeldung">Anmeldung</Link>
                </NavDropdown.Item>
                <NavDropdown.Item as="span">
                  <Link href="/teilnehmer/lehrbetriebe">Lehrbetriebe</Link>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Über uns" id="about-dropdown">
                <NavDropdown.Item as="span">
                  <Link href="/ueber-uns/das-ist-der-lwzo">
                    Das ist der LWZO
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item as="span">
                  <Link href="/ueber-uns/galerie">Galerie</Link>
                </NavDropdown.Item>
                <NavDropdown.Item as="span">
                  <Link href="/ueber-uns/wir-suchen">Wir suchen...</Link>
                </NavDropdown.Item>
                <NavDropdown.Item as="span">
                  <Link href="/ueber-uns/vereinsmaterial">Vereinsmaterial</Link>
                </NavDropdown.Item>
                <NavDropdown.Item as="span">
                  <Link href="/ueber-uns/presse-und-downloads">
                    Presse und Downloads
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item as="span">
                  <Link href="/ueber-uns/sponsoren">Sponsoren</Link>
                </NavDropdown.Item>
                <NavDropdown.Item as="span">
                  <Link href="/ueber-uns/kontakt">Kontakt</Link>
                </NavDropdown.Item>
              </NavDropdown>
            </NavContainer>

            <LogoWrapper className="d-none d-md-block">
              <Nav.Link as="span">
                <Link href="/">
                  <img src={logo} height="50" alt="logo" />
                </Link>
              </Nav.Link>
            </LogoWrapper>

            <NavContainer className="right-nav">
              {showProjectsDropdown ? (
                <NavDropdown title="Projekte" id="projects-dropdown">
                  <NavDropdown.Item as="span">
                    <Link href="/projekte">Projekte</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item as="span">
                    <Link href="/projekte/lageplan">Lageplan</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item as="span">
                    <Link href="/projekte/personen">Lernende</Link>
                  </NavDropdown.Item>
                  <NavDropdown.Item as="span">
                    <Link href="/projekte/mix-ansicht">Mix-Ansicht</Link>
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <>
                  <NavItem>
                    <Nav.Link as="span">
                      <Link href="/projekte">Projekte</Link>
                    </Nav.Link>
                  </NavItem>
                  <NavItem>
                    <Nav.Link as="span">
                      <Link href="/projekte/lageplan">Lageplan</Link>
                    </Nav.Link>
                  </NavItem>
                  <NavItem>
                    <Nav.Link as="span">
                      <Link href="/projekte/personen">Lernende</Link>
                    </Nav.Link>
                  </NavItem>
                  <NavItem>
                    <Nav.Link as="span">
                      <Link href="/projekte/mix-ansicht">Mix-Ansicht</Link>
                    </Nav.Link>
                  </NavItem>
                </>
              )}
            </NavContainer>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navigation;
