import React from "react";
import headerImage from "../assets/headerimage_001.png";
import { Link } from "../utils/router";

const HomePage = () => {
  return (
    <div>
      <div className="container mb-5">
        <div
          className="w-100 position-relative mb-4"
          style={{ height: "300px" }}
        >
          <img
            src={headerImage}
            alt="LWZO Header"
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
          />
        </div>
        <h1 className="text-primary mb-4">Herzlich willkommen beim LWZO</h1>

        <h2 className="h2">Save the date</h2>
        <p>
          Der nächste Lehrlingswettbewerb Züri-Oberland findet vom 5. bis 8.
          November 2025 in Uster statt.
        </p>

        <h2 className="h2">Rückblick</h2>
        <p>
          Einblick in die präsentierten Projekte der Lernenden gibt es weiterhin
          unter <Link href="/projekte">Projekte</Link>. Impressionen der
          Ausstellungstage und Preisträger sind in der Historie 2024 zu finden.
        </p>
        <Link href="/ueber-uns/galerie">Galerie / Historie</Link>

        <h2>Informiert bleiben</h2>
        <p> Den Newsletter hier abonnieren und informiert bleiben.</p>

        <h2>Wir suchen ... dich?!</h2>
        <p>
          Weitere Informationen unter{" "}
          <Link href="/ueber-uns/wir-suchen">Wir suchen...</Link>
        </p>

        <h2>Feedback erwünscht</h2>
        <p>Wir freuen uns über Ihre Nachricht!</p>
      </div>
    </div>
  );
};

export default HomePage;
