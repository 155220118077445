import React, { JSX } from "react";
import styled from "styled-components";
import { getNewComponents } from "./editable_index";
import { TreeItemList } from "./ComponentTree";

export const NewItem = styled.div<{ isActive: boolean }>`
  padding: 7px 12px;
  background-color: ${(props) => (props.isActive ? "#999" : "#3a4348")};
  border-radius: 25px;
  margin-right: 7px;
  margin-bottom: 7px;
  display: inline-block;
  box-sizing: content-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: grab;
`;

export function NewComponents(props: {}): JSX.Element {
  return (
    <TreeItemList paddingLeft={0}>
      {getNewComponents().map((NewComponent, i) => (
        <NewComponent key={i} />
      ))}
    </TreeItemList>
  );
}
