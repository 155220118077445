import React, { useEffect } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import NewsletterPage from "./pages/NewsletterPage";
import DatenschutzPage from "./pages/DatenschutzPage";
import MvPage from "./pages/MvPage";
import ProjectsPage from "./pages/projekte/ProjectsPage";
import BackendPage from "./pages/BackendPage";
import BesucherInfoPage from "./pages/besucher/BesucherInfoPage";
import InfosFuerSchulklassenPage from "./pages/besucher/InfosFuerSchulklassenPage";
import LehrstellenInfoPage from "./pages/besucher/LehrstellenInfoPage";
import DasIstDerLWZOPage from "./pages/ueber-uns/DasIstDerLWZOPage";
import KontaktPage from "./pages/ueber-uns/KontaktPage";
import SponsorenPage from "./pages/ueber-uns/SponsorenPage";
import GaleriePage from "./pages/ueber-uns/GaleriePage";
import VereinsmaterialPage from "./pages/ueber-uns/VereinsmaterialPage";
import PresseUndDownloadsPage from "./pages/ueber-uns/PresseUndDownloadsPage";
import WirSuchenPage from "./pages/ueber-uns/WirSuchenPage";
import BewertungUndPreisePage from "./pages/teilnehmer/BewertungUndPreisePage";
import LehrbetriebePage from "./pages/teilnehmer/LehrbetriebePage";
import ReglementPage from "./pages/teilnehmer/ReglementPage";
import ZeigWasDuKannstPage from "./pages/teilnehmer/ZeigWasDuKannstPage";
import AnmeldungPage from "./pages/teilnehmer/AnmeldungPage";
import PersonenPage from "./pages/projekte/personen/PersonenPage";
import MixAnsichtPage from "./pages/projekte/mix-ansicht/MixAnsichtPage";
import LageplanPage from "./pages/projekte/lageplan/LageplanPage";
import ProjectDetailPage from "./pages/projekte/ProjectDetailPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./main.css";
import { goToLocation, goToLocationReplace, useLocation } from "./utils/router";
import { processResponse } from "./utils/auth";
import RenderPage from "./cms/RenderPage";
import { SiteMap } from "./cms/SiteMap";

export default function App() {
  const location = useLocation();

  // check if old url is used: /projects* -> /projekte*
  if (location.startsWith("/projects")) {
    setTimeout(
      () => goToLocationReplace(location.replace("/projects", "/projekte")),
      1
    );
    return <div>Redirecting...</div>;
  }

  const projectDetail = ProjectRoutes(location);
  if (projectDetail) {
    return (
      <>
        <Navbar />
        {projectDetail}
        <Footer />
      </>
    );
  }

  // Static routes
  const Route = Routes[location];
  if (Route) {
    return (
      <>
        <Navbar />
        <Route />
        <Footer />
      </>
    );
  }

  return (
    <>
      <Navbar />
      <RenderPage path={location} />
      <Footer />
    </>
  );
}

const Routes: Record<string, React.ComponentType<any>> = {
  // Static routes
  "/": HomePage,
  "/login/callback": CallbackPage,
  "/sitemap": SiteMap,
  "/newsletter": NewsletterPage,
  "/datenschutz": DatenschutzPage,
  "/projekte": ProjectsPage,
  "/backend": BackendPage,

  // Besucher routes
  "/besucher/informationen": BesucherInfoPage,
  "/besucher/infos-fuer-schulklassen": InfosFuerSchulklassenPage,
  "/besucher/lehrstelleninfos": LehrstellenInfoPage,

  // Ueber-uns routes
  "/ueber-uns/das-ist-der-lwzo": DasIstDerLWZOPage,
  "/ueber-uns/kontakt": KontaktPage,
  "/ueber-uns/sponsoren": SponsorenPage,
  "/ueber-uns/galerie": GaleriePage,
  "/ueber-uns/vereinsmaterial": VereinsmaterialPage,
  "/ueber-uns/presse-und-downloads": PresseUndDownloadsPage,
  "/ueber-uns/wir-suchen": WirSuchenPage,

  // Teilnehmer routes
  "/teilnehmer/bewertung-und-preise": BewertungUndPreisePage,
  "/teilnehmer/lehrbetriebe": LehrbetriebePage,
  "/teilnehmer/reglement": ReglementPage,
  "/teilnehmer/zeig-was-du-kannst": ZeigWasDuKannstPage,
  "/teilnehmer/anmeldung": AnmeldungPage,

  // Projekte sub-routes
  "/projekte/personen": PersonenPage,
  "/projekte/mix-ansicht": MixAnsichtPage,
  "/projekte/lageplan": LageplanPage,
};

function CallbackPage() {
  useEffect(() => {
    // Process the login callback from the OIDC provider
    processResponse();
  }, []);

  return (
    <div style={{ padding: "40px", textAlign: "center" }}>
      <h2>Processing login...</h2>
      <p>Please wait while we complete your authentication.</p>
    </div>
  );
}

// Check if the location is a project detail page
function ProjectRoutes(location: string) {
  const projectDetailMatch = location.match(/\/projekte\/([^\/]+)/);
  if (projectDetailMatch) {
    const projectId = projectDetailMatch[1];
    switch (projectId) {
      case "lageplan":
        return <LageplanPage />;
      case "personen":
        return <PersonenPage />;
      case "mix-ansicht":
        return <MixAnsichtPage />;
      default:
        return <ProjectDetailPage projectID={projectId} />;
    }
  }
}
