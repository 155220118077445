import {
  faQuestionCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  ComponentItemProps,
  DeleteItemButton,
  TreeItem,
} from "./ComponentTree";
import { IEditableComponent } from "./EditableComponent";
import { Component, UnknownSpec } from "./Unknown";

const ComponentEdit = observer(Component);

const Properties = observer((props: { spec: UnknownSpec }) => {
  return <div>Unknown component: {props.spec.type ?? "unknown"}</div>;
});

const Item = observer((props: ComponentItemProps<UnknownSpec>) => {
  return (
    <>
      <TreeItem onClick={props.onSelect} isActive={props.selected}>
        <FontAwesomeIcon icon={faQuestionCircle} />
        <span> Unknown "{props.spec.type ?? "unknown"}" </span>
        <DeleteItemButton
          onClick={() => {
            props.onDelete();
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </DeleteItemButton>
      </TreeItem>
    </>
  );
});

export const UnknownComponent: IEditableComponent<UnknownSpec> = {
  component: ComponentEdit,
  properties: Properties,
  item: Item,
};
