import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import getYouTubeID from "get-youtube-id";
import { useResizeDetector } from "react-resize-detector";
import {
  Company,
  ProjectDetail as ProjectDetailType,
  Image,
} from "../../lib/generated/src/api/proto/api";
import { PubAPI, getAppropriateImage } from "../../lib/api";
import { UserCard } from "./UserCard";
import { ImageComponent } from "./Image";
import { QRCode } from "react-qrcode-logo";

const Text = styled.p`
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

const Title = styled.h1`
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

const LoadingPlaceholder = styled.div`
  height: 100vh;
  width: 100vw;
`;

// Define proper props interface
interface ProjectDetailPageProps {
  projectID: string;
}

const ProjectDetailPage = ({ projectID }: ProjectDetailPageProps) => {
  const [projectDetail, setProjectDetail] = useState<
    ProjectDetailType | undefined
  >(undefined);

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await PubAPI.GetProjectDetail({ id: projectID });
        if (!ignore) {
          setProjectDetail(res.project);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, [projectID]);

  const { height, ref } = useResizeDetector();
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState("Kopieren");
  const qrCode = useRef<QRCode>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowShareDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (projectDetail === undefined) {
    return <LoadingPlaceholder />;
  }

  const userElement = projectDetail.Users.map((u) => (
    <UserCard
      user={u}
      dynamicSize={projectDetail.Users.length === 1}
      key={`usercard-${u.ID}`}
    ></UserCard>
  ));

  return (
    <div className="container py-4" key={projectDetail.ID} id="top">
      <h4>{projectDetail.Year}</h4>
      <div className="d-flex align-items-center">
        <Title className="mr-2">{projectDetail.Title}</Title>
        <div className="dropdown" ref={dropdownRef}>
          <button
            className="btn btn-link text-secondary"
            type="button"
            onClick={() => setShowShareDropdown(!showShareDropdown)}
          >
            <FontAwesomeIcon
              icon={faShareAlt}
              size="xl"
              style={{ opacity: 0.75 }}
            />
          </button>
          <div
            className={`dropdown-menu p-3 ${showShareDropdown ? "show" : ""}`}
            style={{ minWidth: "300px" }}
          >
            <div className="text-center mb-3">
              <QRCode
                value={window.location.href}
                size={512}
                quietZone={15}
                ref={qrCode}
                style={{ width: "200px", height: "200px" }}
              />
              <button
                className="btn btn-sm btn-outline-secondary mt-2"
                onClick={() => {
                  qrCode.current?.download();
                }}
              >
                QR Code Herunterladen
              </button>
            </div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                value={window.location.href}
                readOnly
              />
              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    setCopyButtonText("Kopiert!");
                    setTimeout(() => {
                      setCopyButtonText("Kopieren");
                    }, 2000);
                  }}
                >
                  {copyButtonText}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col" ref={ref}>
          <Text>{projectDetail.Description}</Text>
          <div>
            <PDFComponent pdf={projectDetail.FileUrl} />
          </div>
          <div>
            <VideoComponent video={projectDetail.VideoUrl} />
          </div>
        </div>
        <DiashowComponent img={projectDetail.Image} rowHeight={height} />
      </div>
      {userElement.length > 0 ? (
        <>
          <hr />
          <div className="row">
            <div className="col">
              <h3>Ein Projekt von ...</h3>
            </div>
          </div>
          <div className="row album pt-3">{userElement}</div>
        </>
      ) : (
        <div className="pb-5" />
      )}
      {projectDetail.Companies.length > 0 ? (
        <>
          <hr />
          <div className="row">
            <div className="col">
              <h3>Lehrfirma</h3>
              <CompanyComponent companys={projectDetail.Companies} />
            </div>
          </div>
        </>
      ) : (
        <div className="pb-5" />
      )}
      <hr />
    </div>
  );
};

const ImageContainer = styled(ImageGallery)`
  height: 350px;
  display: box;
`;

const DiashowComponent = (props: {
  img: Image[];
  rowHeight: number | undefined;
}) => {
  const img = props.img;
  const galleryRef = React.useRef<ImageGallery | null>(null);

  const images = useMemo<ReactImageGalleryItem[]>(() => {
    return img.map((i) => {
      return {
        original: getAppropriateImage(i, 720, 0),
        thumbnail: getAppropriateImage(i, 150, 150),
        fullscreen: getAppropriateImage(
          i,
          window.screen.width,
          window.screen.height
        ),
      };
    });
  }, [img]);

  if (img.length === 0 || images === undefined) {
    return <></>;
  }

  return (
    <div className="col-md-6">
      <ImageContainer
        ref={galleryRef}
        items={images}
        showThumbnails={true}
        lazyLoad
        showPlayButton={false}
        showIndex
        showFullscreenButton
        useBrowserFullscreen={false}
        slideDuration={600} // 6 sec
      />
    </div>
  );
};

// Create PDF Component -> Renders Title and PDF Links
const PDFComponent = (props: { pdf: string[] | string }) => {
  if (Array.isArray(props.pdf) && props.pdf.length > 0) {
    const PDFList = props.pdf.map((p) => (
      <div className="row" key={`pdflist-${p}`}>
        <div className="col">
          <FontAwesomeIcon
            className="text-primary mr-2"
            icon={faExternalLinkAlt}
          ></FontAwesomeIcon>
          <a href={p} target="_blank" rel="noreferrer">
            Dokumentation
          </a>
        </div>
      </div>
    ));

    return (
      <div className="mb-3">
        <h5>Weitere Dokumentation:</h5>
        {PDFList}
      </div>
    );
  }

  if (typeof props.pdf === "string" && props.pdf !== "") {
    return (
      <div className="mb-3">
        <h5>Weitere Dokumentation:</h5>
        <div className="row" key={`pdflist-${props.pdf}`}>
          <div className="col">
            <FontAwesomeIcon
              className="text-primary mr-2"
              icon={faExternalLinkAlt}
            ></FontAwesomeIcon>
            <a href={props.pdf} target="_blank" rel="noreferrer">
              Dokumentation
            </a>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

const VideoContainer = styled.div`
  max-width: 700px;
`;

const VideoComponent = (props: { video: string[] | string }) => {
  let VideoList: string[] = [];
  if (Array.isArray(props.video) && props.video.length > 0) {
    VideoList = props.video
      .map((v) => {
        var id = getYouTubeID(v);
        if (id === null) {
          console.log("URL invalid:", v);
        } else {
          return id;
        }
        return null;
      })
      .filter((x) => x) as string[];
  } else if (typeof props.video === "string" && props.video !== "") {
    var id = getYouTubeID(props.video);
    if (id === null) {
      console.log("URL invalid:", props.video);
    } else {
      VideoList = [id];
    }
  }

  if (VideoList.length === 0) {
    return <></>;
  }

  return (
    <div>
      <h5>Videos:</h5>
      {VideoList.map((id) => (
        <div className="row" key={id}>
          <div className="col">
            <VideoContainer className="mb-3 ratio ratio-16x9" key={id}>
              <iframe
                className=""
                src={"https://www.youtube-nocookie.com/embed/" + id}
                allowFullScreen
                title={id}
              ></iframe>
            </VideoContainer>
          </div>
        </div>
      ))}
    </div>
  );
};

const CompanyImageBox = styled.div`
  align-self: center;
`;

const CompanyComponent = (props: { companys: Company[] }) => {
  return (
    <div>
      {props.companys.map((c) => (
        <div key={c.ID}>
          <div className="row p-5 mb-4 bg-body-tertiary rounded-3">
            <div className="col">
              <h5>{c.Name}</h5>
              <p>{c.Description}</p>
              <p>
                {c.WebsiteUrl !== "" ? (
                  <a href={c.WebsiteUrl} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon
                      className="text-primary mr-2"
                      icon={faExternalLinkAlt}
                    ></FontAwesomeIcon>
                    {c.Name}
                  </a>
                ) : null}
              </p>
            </div>
            {c.WebsiteUrl !== "" &&
            c.Image !== undefined &&
            c.Image?.BaseURL !== "" ? (
              <CompanyImageBox className="col-md-5">
                <a href={c.WebsiteUrl} target="_blank" rel="noreferrer">
                  <ImageComponent
                    img={c.Image}
                    customMaxHeight={"350px"}
                    customMaxWidth={"500px"}
                  />
                </a>
              </CompanyImageBox>
            ) : c.WebsiteUrl === "" &&
              c.Image !== undefined &&
              c.Image?.BaseURL !== "" ? (
              <CompanyImageBox className="col-md-5">
                <ImageComponent
                  img={c.Image}
                  customMaxHeight={"350px"}
                  customMaxWidth={"500px"}
                />
              </CompanyImageBox>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectDetailPage;
