import { useLocalStore } from "mobx-react-lite";
import React, { JSX } from "react";
import styled from "styled-components";
import { Page } from "./api";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useInhibitNavigation } from "../utils/router";
import { useInhibitLogout } from "../utils/oidc";
import { Component } from "./components/EditableComponent";
import { PropertiesBar } from "./components/PropertiesBar";

const EditingContainer = styled.div`
  display: grid;
  grid-template-columns: auto 400px;
  min-height: calc(100vh - 141px);
`;

const ComponentContainer = styled.div`
  width: calc(100vw - 400px);
`;

export default function EditPage(props: { page: Page }): JSX.Element {
  const page = useLocalStore<Page>(() => props.page);
  useInhibitNavigation("Please close the editor before going to another page");
  useInhibitLogout("Please close the editor before logging out");
  return (
    <DndProvider backend={HTML5Backend}>
      <EditingContainer>
        <ComponentContainer>
          <Component spec={page.content} />
        </ComponentContainer>
        <PropertiesBar page={page} />
      </EditingContainer>
    </DndProvider>
  );
}
