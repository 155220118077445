import {
  faArrowsAlt,
  faExternalLinkAlt,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { arrayMoveMutable } from "array-move";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useDrag } from "react-dnd";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import styled from "styled-components";
import { GENERIC_COMPONENT } from "./Component";
import { ComponentItemProps, DefaultItem } from "./ComponentTree";
import { DragSpec, IEditableComponent } from "./EditableComponent";
import { NewItem } from "./NewComponents";
import { BarBoolProperty } from "./PropertiesBar";
import {
  Container,
  Link,
  LinkContainer,
  LinkIcon,
  LinkListSpec,
  LINK_LIST,
  NameContainer,
} from "./LinkList";
import { isGoodURL } from "../../utils/router";

const DragHandle = SortableHandle((props: {}) => (
  <FontAwesomeIcon icon={faArrowsAlt} style={{ cursor: "grab" }} />
));

const ContainerSortable = SortableContainer<any>(Container);

const EditTextbox = styled.input`
  display: block;
  position: relative;
  min-width: inhert;
  max-width: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit;
  font: inherit;
  resize: none;
  background: none;
  outline: none;
  border: none;
  padding: 0;
`;

const ExtLinkEditable = SortableElement<any>(
  observer(
    (props: {
      spec: Link;
      onDelete: () => void;
      dense: boolean;
      internal?: boolean;
    }) => {
      const [isCompEditing, setIsEditing] = useState(false);

      return isCompEditing ? (
        <LinkContainer dense={props.dense} internal={props.internal}>
          {props.internal ? null : <LinkIcon />}
          <div style={{ display: "inline-block" }}>
            <EditTextbox
              type="text"
              value={props.spec.name}
              onChange={(event) =>
                (props.spec.name = event.currentTarget.value)
              }
              onBlur={() => setIsEditing(false)}
            />
            <EditTextbox
              type="text"
              value={props.spec.url}
              onChange={(event) => (props.spec.url = event.currentTarget.value)}
              onBlur={() => {
                if (!isGoodURL(props.spec.url)) {
                  alert("Invalid URL entered");
                } else {
                  setIsEditing(false);
                }
              }}
              placeholder="https://..."
            />
          </div>
        </LinkContainer>
      ) : (
        <LinkContainer dense={props.dense} internal={props.internal}>
          <NameContainer onClick={() => setIsEditing(true)}>
            <DragHandle /> {props.internal ? null : <LinkIcon />}
            {props.spec.name}{" "}
            <FontAwesomeIcon
              onClick={(e) => {
                e.stopPropagation();
                props.onDelete?.();
              }}
              icon={faTrash}
            />
          </NameContainer>
        </LinkContainer>
      );
    }
  )
);

const EditComponent = observer((props: { spec: LinkListSpec }) => {
  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: any) => {
      runInAction(() => {
        arrayMoveMutable(props.spec.links, oldIndex, newIndex);
      });
    },
    [props.spec.links]
  );

  return (
    <ContainerSortable
      vertical={props.spec.vertical}
      onSortEnd={onSortEnd}
      axis="x"
      useDragHandle
    >
      {props.spec.links.map((link, i) => (
        <ExtLinkEditable
          index={i}
          spec={link}
          onDelete={() => {
            runInAction(() => {
              props.spec.links.splice(i, 1);
            });
          }}
          dense={props.spec.dense}
          internal={props.spec.internal}
        />
      ))}
      <LinkContainer
        onClick={() =>
          props.spec.links.push({
            name: "Neuer Link",
            url: "",
          })
        }
        dense={props.spec.dense}
      >
        <FontAwesomeIcon icon={faPlus} />
      </LinkContainer>
    </ContainerSortable>
  );
});

const Properties = observer((props: { spec: LinkListSpec }) => {
  return (
    <div>
      <BarBoolProperty
        name="Vertical Items"
        value={props.spec.vertical}
        onChange={(newVal) => (props.spec.vertical = newVal)}
      />

      <BarBoolProperty
        name="Dense"
        value={props.spec.dense}
        onChange={(newVal) => (props.spec.dense = newVal)}
      />
      <BarBoolProperty
        name="Internal Style"
        value={props.spec.internal ?? false}
        onChange={(newVal) => (props.spec.internal = newVal)}
      />
    </div>
  );
});

const Item = observer((props: ComponentItemProps<LinkListSpec>) => {
  return <DefaultItem {...props} name="Link List" icon={faExternalLinkAlt} />;
});

const New = (props: {}) => {
  const [, drag] = useDrag<DragSpec, unknown, unknown>(() => ({
    type: GENERIC_COMPONENT,
    item: {
      spec: {
        type: LINK_LIST,
        links: [],
        vertical: false,
        dense: false,
      },
    },
  }));
  return (
    <NewItem
      isActive={false}
      ref={(elem) => {
        drag(elem);
      }}
    >
      <FontAwesomeIcon icon={faExternalLinkAlt} />
      <span> Link List </span>
    </NewItem>
  );
};

export const LinkList: IEditableComponent<LinkListSpec> = {
  component: EditComponent,
  properties: Properties,
  item: Item,
  new: New,
};
