import React from "react";
import styled from "styled-components";

const VideoContainer = styled.div`
  position: relative;
  width: 80%;
  padding-bottom: 45%; /* 80% of 56.25% (16:9 Aspect Ratio) */
  height: 0;
  overflow: hidden;
  margin-bottom: 2rem;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ZeigWasDuKannstPage = () => {
  return (
    <div className="container mb-5 pt-5">
      <h1 className="text-primary mb-4">Zeig was du kannst</h1>

      <div className="row">
        <div className="col-12">
          <p>Zeig was du kannst!</p>
          <p>
            Wenn du in der beruflichen Grundbildung bist, im Zürcher Oberland
            wohnst, arbeitest oder zur Berufsfachschule gehst, hast du die
            Gelegenheit, deine eigenen Ideen mit einer frei gewählten Arbeit aus
            deinem Lehrberuf zu verwirklichen und öffentlich zu präsentieren. Es
            sind auch Teamarbeiten und berufsübergreifende Projekte möglich.
          </p>
          <p>
            Profitiere doppelt: Im Vorfeld hast du die Möglichkeit, den
            kostenlosen Präsentations- und Kommunikationskurs
            "Auftrittskompetenz?!"zu besuchen. Als Teilnehmer kannst du dich
            nicht nur profilieren, was bessere Berufschancen verspricht, sondern
            auch verschiedene attraktive Preise gewinnen. Die Teilnahme ist
            kostenlos.
          </p>
        </div>
      </div>

      <VideoContainer>
        <iframe
          src="https://www.youtube-nocookie.com/embed/QmNZsL6OxuI"
          title="YouTube video"
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </VideoContainer>
    </div>
  );
};

export default ZeigWasDuKannstPage;
