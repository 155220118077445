import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer, useLocalStore } from "mobx-react-lite";
import React, { JSX, useContext, useState } from "react";
import styled from "styled-components";
import { Page, setPage } from "../api";
import { useLocation } from "../../utils/router";
import { PageContext } from "../PageContext";
import { ComponentTree, TreeState } from "./ComponentTree";
import { ComponentProps } from "./EditableComponent";
import { NewComponents } from "./NewComponents";

const Container = styled.div`
  background-color: #1f2427;
  height: 100vh;
  width: 400px;
  color: white;
  box-shadow: 0 0 12px 0px #1f1c1c;
  padding: 10px;
  position: fixed;
  box-sizing: border-box;
  right: 0;
  top: 0;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 6px;
    border: 3px solid #1f2427;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.2em;
  margin-top: 0;
  margin-bottom: 10px;
`;

const CloseButtonContainer = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  color: white;
  border: none;
  :hover {
    background: rgba(255, 255, 255, 0.2);
  }
  :active {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const BarButton = styled.button`
  background: #0080ff;
  border: none;
  border-radius: 20px;
  padding: 7px 12px;
  color: white;
  outline: unset;
  margin-bottom: 5px;
  margin-right: 7px;
  :hover {
    background: #1d77d0;
    outline: unset;
  }
  :active {
    background: #3e9dfb;
    outline: unset;
  }
`;

const BarInput = styled.input`
  background: #3a4348;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  outline: unset;
  width: calc(100% - 10px);
  :focus {
    box-shadow: #0080ff 0 0 6px 1px;
    outline: unset;
  }
`;

const SelectInput = styled.select`
  background: #3a4348;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  outline: unset;
  width: calc(100% - 10px);
  :focus {
    box-shadow: #0080ff 0 0 6px 1px;
    outline: unset;
  }
`;

const BarTextarea = styled.textarea`
  background: #3a4348;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  outline: unset;
  width: calc(100% - 10px);
  :focus {
    box-shadow: #0080ff 0 0 6px 1px;
    outline: unset;
  }
`;

const InputLabel = styled.div`
  margin-top: 8px;
  font-size: 13px;
  color: #adadad;
`;

const BarPropContainer = styled.div`
  margin-bottom: 8px;
`;

export const BarTextProperty = observer(
  (props: {
    name: string;
    value: string;
    onChange: (newValue: string) => void;
  }): JSX.Element => (
    <BarPropContainer>
      <InputLabel>{props.name}:</InputLabel>
      <BarInput
        value={props.value}
        onChange={(event) => props.onChange(event.currentTarget.value)}
      />
    </BarPropContainer>
  )
);

export const BarNumberProperty = observer(
  (props: {
    name: string;
    max?: number;
    min?: number;
    value: number;
    onChange: (newValue: number) => void;
  }): JSX.Element => (
    <BarPropContainer>
      <InputLabel>{props.name}:</InputLabel>
      <BarInput
        type="number"
        max={props.max?.toString()}
        min={props.min?.toString()}
        value={props.value.toString()}
        onChange={(event) =>
          props.onChange(parseInt(event.currentTarget.value))
        }
      />
    </BarPropContainer>
  )
);

interface SelectionOption {
  value: string;
  displayName: string;
}

export const SelectProperty = observer(
  <T extends string>(props: {
    name: string;
    value: T;
    option: Record<T, string>;
    onChange: (newValue: T) => void;
  }): JSX.Element => {
    return (
      <BarPropContainer>
        <InputLabel>{props.name}:</InputLabel>
        <SelectInput
          value={props.value}
          onChange={(event) => props.onChange(event.currentTarget.value as T)}
        >
          {Object.keys(props.option).map((k) => (
            <option value={k}>{props.option[k as T]}</option>
          ))}
        </SelectInput>
      </BarPropContainer>
    );
  }
);

const BarCheckboxLabel = styled.label`
  display: inline-block;
  padding-right: 10px;
  white-space: nowrap;
`;

export const BarBoolProperty = observer(
  (props: {
    name: string;
    value: boolean;
    onChange: (newValue: boolean) => void;
  }): JSX.Element => (
    <BarPropContainer>
      <BarCheckboxLabel>
        <input
          type="checkbox"
          checked={props.value}
          onChange={(event) => props.onChange(event.currentTarget.checked)}
          style={{ verticalAlign: "middle" }}
        />{" "}
        <span style={{ verticalAlign: "middle" }}>{props.name}</span>
      </BarCheckboxLabel>
    </BarPropContainer>
  )
);

const BarTagContainer = styled.div`
  background-color: #007fff;
  border-radius: 5px;
  padding: 3px 5px;
  margin-right: 5px;
  font-size: 14px;
  white-space: nowrap;
`;

const BarTag = observer((props: { value: string; onDelete: () => void }) => (
  <BarTagContainer>
    {props.value} <FontAwesomeIcon icon={faTimes} onClick={props.onDelete} />
  </BarTagContainer>
));

const TagInputContainer = styled.div`
  background: #3a4348;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  outline: unset;
  width: calc(100% - 10px);
  display: flex;
  :focus-within {
    box-shadow: #0080ff 0 0 6px 1px;
    outline: unset;
  }
`;

const TagInput = styled.input`
  background: none;
  outline: unset;
  border: none;
  padding: none;
  margin: none;
  color: inherit;
  width: 100%;
  min-width: 50px;
`;

export const BarTagsProperty = observer(
  (props: { name: string; value: string[] }): JSX.Element => {
    const [newTag, setNewTag] = useState("");
    return (
      <BarPropContainer>
        <InputLabel>{props.name}:</InputLabel>
        <TagInputContainer>
          {props.value.map((item, i) => (
            <BarTag value={item} onDelete={() => props.value.splice(i, 1)} />
          ))}
          <TagInput
            value={newTag}
            onChange={(e) => setNewTag(e.currentTarget.value)}
            onBlur={() => {
              if (newTag !== "") props.value.push(newTag);
              setNewTag("");
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (newTag !== "") props.value.push(newTag);
                setNewTag("");
              }
            }}
          />
        </TagInputContainer>
      </BarPropContainer>
    );
  }
);

export const PropertiesBar = observer((props: { page: Page }) => {
  const treeState = useLocalStore<TreeState>(() => ({
    selectedComponent: null,
  }));
  const location = useLocation();
  const { setEditing } = useContext(PageContext);
  const [isSaving, setIsSaving] = useState(false);
  const saveAndPublish = async () => {
    setIsSaving(true);
    try {
      await setPage({
        page: props.page,
        path: location,
      });
    } catch (err) {
      alert("" + err);
    }
    setIsSaving(false);
    setEditing(false);
  };
  return (
    <Container>
      <CloseButtonContainer onClick={() => setEditing(false)}>
        <FontAwesomeIcon icon={faTimes} />
      </CloseButtonContainer>
      <SectionTitle>Page</SectionTitle>
      <div>
        <BarButton disabled={isSaving} onClick={saveAndPublish}>
          {isSaving ? <FontAwesomeIcon spin icon={faSpinner} /> : null} Save
          &amp; Publish
        </BarButton>
      </div>
      <div>
        <InputLabel>Title:</InputLabel>
        <BarInput
          type="text"
          placeholder="Title"
          value={props.page.title}
          onChange={(event) => (props.page.title = event.currentTarget.value)}
        />
      </div>
      <div>
        <InputLabel>Description:</InputLabel>
        <BarTextarea
          placeholder="Description"
          value={props.page.description}
          onChange={(event) =>
            (props.page.description = event.currentTarget.value)
          }
        />
      </div>
      <BarTagsProperty
        name="Groups that can view"
        value={props.page.accessPolicy!.allowViewFromGroups}
      />
      <BarTagsProperty
        name="Groups that can edit"
        value={props.page.accessPolicy!.allowEditFromGroups}
      />
      <SectionTitle>New Components</SectionTitle>
      <NewComponents />
      <SectionTitle>Component Tree</SectionTitle>
      <ComponentTree spec={props.page.content} treeState={treeState} />
      <SectionTitle>Properties</SectionTitle>
      {treeState.selectedComponent !== null ? (
        <ComponentProps spec={treeState.selectedComponent} />
      ) : (
        <></>
      )}
    </Container>
  );
});
