import React from "react";

const SponsorenPage = () => {
  return (
    <div className="container mb-5 pt-5">
      <h1 className="text-primary mb-4">Sponsoren</h1>

      <div className="row">
        <div className="col-md-12">
          <h2 className="h4 mb-3">Werde Sponsor?!</h2>
          <p>
            Der Lehrlingswettbewerb bedankt sich herzlich bei allen Sponsoren
            und Partnern. Wir wissen deren Engagement zu schätzen. Von ihrem
            Sponsoring profitieren alle Lernenden. Wir sind bestrebt unsere
            Vereinsmitglieder und Besucher zu sensibilisieren, dass sie unsere
            Sponsoren berücksichtigen. Natürlich sind wir auch für neue
            Sponsoren offen, die uns unterstützen möchten. Verlangen Sie unsere
            Sponsorendokumentation unter{" "}
            <a href="mailto:info@lwzo.ch">info@lwzo.ch</a>
          </p>

          <div className="my-4">
            <h3 className="h5">Unsere Bankverbindung:</h3>
            <p className="mb-1">Bank Avera, 8620 Wetzikon</p>
            <p className="mb-1">IBAN CH80 0685 0016 6768 4420 4</p>
            <p>zugunsten Lehrlingswettbewerb Züri-Oberland</p>
          </div>

          <div className="mb-4">
            <a
              href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2024/20240626_Einzahlungsschein_vom_LWZO.pdf"
              className="btn btn-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Einzahlungsschein herunterladen
            </a>
          </div>

          <div className="mt-5">
            <h3 className="h5">Kontakt</h3>
            <p className="mb-1">Simon Boller</p>
            <p className="mb-1">
              <a href="tel:+41793283238">079 328 32 38</a>
            </p>
            <p>
              <a href="mailto:simon.boller@lwzo.ch">simon.boller@lwzo.ch</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SponsorenPage;