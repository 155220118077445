import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useDrag } from "react-dnd";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { GENERIC_COMPONENT } from "./Component";
import { ComponentItemProps, DefaultItem } from "./ComponentTree";
import { DragSpec, IEditableComponent } from "./EditableComponent";
import { NewItem } from "./NewComponents";
import { BarBoolProperty, BarButton, BarNumberProperty } from "./PropertiesBar";
import {
  AspectRatioContainer,
  ImageContainer,
  ImageSpec,
  IMAGE_COMPONENT,
} from "./Image";
import { ImageRenderer } from "./ImageRenderer";

const ImagePlaceholder = styled.div`
  border: 2px dashed #888;
  border-radius: 15px;
  background: white;
  width: 200px;
  height: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
`;

export const EditComponent = observer((props: { spec: ImageSpec }) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach(async (f) => {
      const url = await (await fetch("/api/upload")).text();
      // TODO: Placeholder, retry and status
      const res = await fetch(url, { method: "PUT", body: f });
      // TODO check res
      console.log(res);
      const downloadURL = url.split("?")[0];
      props.spec.baseURL = downloadURL;
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
  });
  if (props.spec.baseURL) {
    return (
      <ImageContainer
        style={{ cursor: props.spec.lightboxEnabled ? "pointer" : "auto" }}
      >
        <ImageRenderer
          image={props.spec as any}
          maxWidth={
            props.spec.customMaxWidth === 0
              ? undefined
              : props.spec.customMaxWidth
          }
          maxHeight={
            props.spec.customMaxHeight === 0
              ? undefined
              : props.spec.customMaxHeight
          }
        />
      </ImageContainer>
    );
  }

  return (
    <ImagePlaceholder {...getRootProps()}>
      <div>
        <div>
          <FontAwesomeIcon
            icon={faImage}
            size="3x"
            style={{ marginBottom: "10px" }}
          />
        </div>
        <div>Select or drag an image here</div>
        <input {...getInputProps()} />
      </div>
    </ImagePlaceholder>
  );
});

const Properties = observer((props: { spec: ImageSpec }) => {
  return (
    <div>
      <BarNumberProperty
        name="Max With"
        value={props.spec.customMaxWidth ?? 0}
        onChange={(newVal) => (props.spec.customMaxWidth = newVal)}
      />
      <BarNumberProperty
        name="Max Height"
        value={props.spec.customMaxHeight ?? 0}
        onChange={(newVal) => (props.spec.customMaxHeight = newVal)}
      />
      <BarBoolProperty
        name="Enable Lightbox"
        value={props.spec.lightboxEnabled ?? false}
        onChange={(newVal) => (props.spec.lightboxEnabled = newVal)}
      />
      <BarButton
        onClick={() => (props.spec.baseURL = null)}
        style={{ backgroundColor: "red", marginTop: "10px" }}
      >
        Remove Image
      </BarButton>
    </div>
  );
});

const Item = observer((props: ComponentItemProps<ImageSpec>) => {
  return <DefaultItem {...props} icon={faImage} name="Image" />;
});

const New = (props: {}) => {
  const [, drag] = useDrag<DragSpec, unknown, unknown>(() => ({
    type: GENERIC_COMPONENT,
    item: {
      spec: {
        type: IMAGE_COMPONENT,
        baseURL: null,
        customMaxWidth: 0,
        customMaxHeight: 0,
        aspectRatioDenom: 0,
        aspectRatioNum: 0,
      },
    },
  }));
  return (
    <NewItem
      isActive={false}
      ref={(elem) => {
        drag(elem);
      }}
    >
      <FontAwesomeIcon icon={faImage} />
      <span> Image </span>
    </NewItem>
  );
};

export const ImageComponent: IEditableComponent<ImageSpec> = {
  component: EditComponent,
  properties: Properties,
  item: Item,
  new: New,
};
