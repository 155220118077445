import { computed, IComputedValue, observable, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import React, {
  JSX,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ConnectDropTarget,
  DropTargetMonitor,
  useDrag,
  useDrop,
  XYCoord,
} from "react-dnd";
import styled from "styled-components";
import { ComponentSpec } from ".";
import { GENERIC_COMPONENT, GENERIC_ITEM } from "./Component";
import { TreeItemList, TreeState } from "./ComponentTree";
import { Component, ComponentItem, DragSpec } from "./EditableComponent";
import { Container, VerticalSpec } from "./Vertical";

const TargetContainer = styled.div<{ show: boolean }>`
  ${(props) => (props.show ? "border: 1px dashed gray;" : "")}
  display: ${(props) => (props.show ? "block" : "none")};
  border-radius: 10px;
  padding: 10px;
  color: gray;
`;

export const Vertical = observer(
  (props: { spec: VerticalSpec; name?: string }) => {
    const [collectedProps, drop] = useDrop({
      accept: GENERIC_COMPONENT,
      collect: (monitor) => ({
        dragging: monitor.canDrop(),
        hovering: monitor.isOver(),
        // This is safe, if you're dragging a GENERIC_COMPONENT source it needs to have a spec
        // object that is a valid ComponentSpec
        spec: monitor.getItem()?.spec as ComponentSpec | null,
      }),
      drop: (item: DragSpec) => {
        runInAction(() => {
          props.spec.children.push(observable(structuredClone(item.spec)));
        });
      },
    });
    return (
      <Container key={props.spec.id}>
        {props.spec.children.map((spec) => (
          <div>
            <Component spec={spec} />
          </div>
        ))}
        <TargetContainer
          ref={(elem) => {
            drop(elem);
          }}
          show={collectedProps.dragging}
        >
          {collectedProps.dragging
            ? props.name
              ? "Append to " + props.name
              : "Append to vertical layout"
            : ""}
        </TargetContainer>
      </Container>
    );
  }
);

const VerticalName = styled.div`
  color: #adadad;
  font-size: 13px;
`;

/*
IMPORTANT: The code below may look a bit convoluted, but it tries very hard not to create or remove
any Drag and Drop zones while performing moves. This is to avoid triggering crashes in react-dnd.
*/

interface DragState {
  isDragging: boolean;
}

enum Relative {
  Above,
  Below,
}

interface HoverState {
  position: number;
  relative: Relative;
}

function DraggableItem(props: {
  spec: ComponentSpec;
  children: React.ReactNode;
  onDelete: (s?: DropSpec) => void;
}): JSX.Element {
  const [{ isDragging }, drag] = useDrag<DragSpec, unknown, DragState>(() => ({
    type: GENERIC_ITEM,
    item: { spec: props.spec },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        const res = monitor.getDropResult<DropSpec | undefined>();
        if (res !== null) {
          props.onDelete(res);
        }
      }
    },
  }));
  return (
    <div
      ref={(elem) => {
        drag(elem);
      }}
      style={{ opacity: isDragging ? 0.5 : 1.0, marginLeft: 10 }}
    >
      {props.children}
    </div>
  );
}

function DnDInsertPlaceholder(): JSX.Element {
  return (
    <div style={{ position: "relative", height: 0, width: "100%" }}>
      <div
        style={{
          position: "absolute",
          height: 2,
          top: -1,
          width: "100%",
          backgroundColor: "#0080ff",
          zIndex: 2,
        }}
      />
    </div>
  );
}

function calculateHoverState(
  mouseState: XYCoord,
  childRefs: React.RefObject<Element | null>[]
): HoverState {
  let currentOptimalDistance = 2 ** 32;
  let currentOptimalHoverState: HoverState = {
    position: 0,
    relative: Relative.Above,
  };
  for (const [i, childRef] of childRefs.entries()) {
    const hoverBoundingRect = childRef.current?.getBoundingClientRect();
    if (hoverBoundingRect === undefined) {
      continue;
    }
    const absMidYCoord =
      hoverBoundingRect.top +
      (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const cursorToMidYDistance = mouseState.y - absMidYCoord;
    if (Math.abs(cursorToMidYDistance) < currentOptimalDistance) {
      currentOptimalDistance = Math.abs(cursorToMidYDistance);
      currentOptimalHoverState = {
        position: i,
        relative: cursorToMidYDistance > 0 ? Relative.Below : Relative.Above,
      };
    }
  }
  return currentOptimalHoverState;
}

interface DropSpec {
  target: VerticalSpec;
  targetIdx: number;
}

export const VerticalItem = observer(
  (props: {
    spec: VerticalSpec;
    name?: string;
    treeState: TreeState;
    parentRefs: ComponentSpec[];
  }) => {
    if (!props.spec.id) {
      props.spec.id = nanoid(5);
    }

    const childRefs = useMemo(
      () => props.spec.children.map(() => React.createRef<HTMLDivElement>()),
      [props.spec.children.length]
    );

    const [mouseState, setMouseState] = useState<XYCoord | null>(null);

    const [collectedProps, drop] = useDrop<
      DragSpec,
      DropSpec,
      { dragging: boolean; hovering: boolean }
    >({
      accept: GENERIC_ITEM,
      collect: (monitor) => ({
        dragging: monitor.canDrop(),
        hovering: monitor.isOver({ shallow: true }),
      }),
      canDrop: (item) => props.parentRefs.indexOf(item.spec) === -1,
      drop: (item, monitor) => {
        const didDrop = monitor.didDrop();
        if (didDrop) {
          return; // Prevent dropping onto multiple dropzones at the same time
        }
        setDropClock((x) => x + 1);
        const clientOffset = monitor.getClientOffset();
        if (clientOffset === null) {
          alert("ERROR: No mouse data for drop operation");
          return;
        }
        const hoverState = calculateHoverState(clientOffset, childRefs);
        const targetIdx =
          hoverState.position +
          (hoverState.relative === Relative.Below ? 1 : 0);
        runInAction(() => {
          props.spec.children.splice(targetIdx, 0, item.spec);
        });
        return { target: props.spec, targetIdx };
      },
      hover: (props, monitor) => {
        // HACK: Regular mouse tracking doesn't work while using HTML5 DnD
        setMouseState(monitor.getClientOffset());
      },
    });

    const [dropClock, setDropClock] = useState(0);

    const [hoverState, setHoverState] = useState<HoverState | null>(null);

    useEffect(() => {
      if (!collectedProps.dragging || !collectedProps.hovering) {
        setHoverState(null);
        return;
      }
      if (mouseState === null) {
        setHoverState(null);
        return;
      }

      const newHoverState = calculateHoverState(mouseState, childRefs);
      setHoverState((old) => {
        if (
          old?.position !== newHoverState.position ||
          old.relative !== newHoverState.relative
        )
          return newHoverState;
        else return old;
      });
    }, [collectedProps.dragging, collectedProps.hovering, mouseState]);

    return (
      <ChildRender
        {...props}
        hoverState={hoverState}
        dropClock={dropClock}
        childRefs={childRefs}
        drop={drop}
      />
    );
  }
);
const ChildRender = React.memo(
  observer(
    (props: {
      spec: VerticalSpec;
      name?: string | undefined;
      treeState: TreeState;
      parentRefs: ComponentSpec[];
      hoverState: HoverState | null;
      dropClock: number;
      childRefs: React.RefObject<HTMLDivElement | null>[];
      drop: ConnectDropTarget;
    }) => {
      let items: JSX.Element[] = [];
      if (
        props.spec.children.length === 0 &&
        props.hoverState !== null &&
        props.hoverState.position === 0 &&
        props.hoverState.relative === Relative.Above
      ) {
        items.push(<DnDInsertPlaceholder />);
      }
      for (const [i, child] of props.spec.children.entries()) {
        if (
          props.hoverState !== null &&
          props.hoverState.position === i &&
          props.hoverState.relative == Relative.Above
        ) {
          items.push(<DnDInsertPlaceholder />);
        }
        items.push(
          <DraggableItem
            key={`${props.dropClock}-${i}`}
            onDelete={(item) => {
              runInAction(() => {
                let currentGuessedIndex = props.spec.children.indexOf(child);
                if (
                  props.spec === item?.target &&
                  currentGuessedIndex === item.targetIdx
                ) {
                  currentGuessedIndex = props.spec.children.lastIndexOf(child);
                }
                props.spec.children.splice(currentGuessedIndex, 1);
              });
            }}
            spec={child}
          >
            <div ref={props.childRefs[i]}>
              <ComponentItem
                spec={child}
                onSelect={() => (props.treeState.selectedComponent = child)}
                selected={props.treeState.selectedComponent === child}
                onDelete={() => {
                  runInAction(() => {
                    props.spec.children.splice(i, 1);
                  });
                }}
                treeState={props.treeState}
                parentRefs={props.parentRefs}
              />
            </div>
          </DraggableItem>
        );
        if (
          props.hoverState !== null &&
          props.hoverState.position === i &&
          props.hoverState.relative == Relative.Below
        ) {
          items.push(<DnDInsertPlaceholder />);
        }
      }

      return (
        <TreeItemList paddingLeft={0}>
          {" "}
          <div
            key={props.spec.id}
            ref={(elem) => {
              props.drop(elem);
            }}
          >
            <VerticalName>{props.name ?? ""}</VerticalName>
            {items}
          </div>
        </TreeItemList>
      );
    }
  )
);
