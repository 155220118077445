"use client";

import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { default as React, useMemo } from "react";
import {
  ComponentItemProps,
  TreeItem,
  TreeItemList,
} from "../components/ComponentTree";
import { IEditableComponent } from "../components/EditableComponent";
import { PageHomeRender, PageHomeSpec } from "./PageHome";
import { verticalDI } from "../components/Vertical";
import { Vertical, VerticalItem } from "../components/VerticalEdit";

export const PageHomeRenderEdit = observer(
  verticalDI(PageHomeRender, Vertical)
);

const Properties = observer((props: { spec: PageHomeSpec }) => {
  return <div>Component has no properties</div>;
});

const Item = observer((props: ComponentItemProps<PageHomeSpec>) => {
  const parentRefs = computed(() => [...props.parentRefs, props.spec]);
  return (
    <>
      <TreeItem onClick={props.onSelect} $isActive={props.selected}>
        <FontAwesomeIcon icon={faFile} />
        <span> Home Page </span>
      </TreeItem>
      <TreeItemList $paddingLeft={12}>
        <VerticalItem
          spec={props.spec.content}
          treeState={props.treeState}
          parentRefs={parentRefs.get()}
        />
      </TreeItemList>
    </>
  );
});

export const PageHome: IEditableComponent<PageHomeSpec> = {
  component: PageHomeRenderEdit,
  properties: Properties,
  item: Item,
};
