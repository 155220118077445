import React from "react";
import styled from "styled-components";
import { IComponent } from "./Component";
import { Vertical, VerticalSpec } from "./Vertical";

export const SECTION_COMPONENT = "section";
export interface SectionComponentSpec {
  type: typeof SECTION_COMPONENT;
  title: string;
  child: VerticalSpec;
}

export const Container = styled.section``;

const SectionRender = (props: { spec: SectionComponentSpec }) => {
  return (
    <Container>
      <h1>{props.spec.title}</h1>
      <Vertical spec={props.spec.child} name={props.spec.title} />
    </Container>
  );
};

export const SectionComponent: IComponent<SectionComponentSpec> = {
  component: SectionRender,
};
