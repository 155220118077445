import React from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import styled from "styled-components";

// Import sponsor images
import gebauerStiftung from "../assets/sponsor/sponsor_gebauer-stiftung.png";
import kantonZuerichBildungsdirektion from "../assets/sponsor/sponsor_kanton-zuerich-bildungsdirektion.png";
import kgv from "../assets/sponsor/sponsor_kgv.png";
import zueriOberland from "../assets/sponsor/sponsor_zuerioberland.png";
import zueriOst from "../assets/sponsor/sponsor_zueriost.png";
import { Link } from "../utils/router";

const secondarySponsorImages = [
  {
    imageSrc: "/sponsor/sponsor_forum-berufsbildung.png",
    sponsorUrl: "https://www.forum-berufsbildungzo.ch/",
    alt: "Forum Berufsbildung",
  },
  {
    imageSrc: "/sponsor/sponsor_krebs-ag.png",
    sponsorUrl: "https://www.krebsag.ch/",
    alt: "Krebs AG",
  },
  {
    imageSrc: "/sponsor/sponsor_bgv-uster.png",
    sponsorUrl: "https://www.bgu-net.ch/",
    alt: "BGV Uster",
  },
  {
    imageSrc: "/sponsor/sponsor_bgv-hinwil.png",
    sponsorUrl: "https://www.bgv-hinwil.ch/",
    alt: "BGV Hinwil",
  },
  {
    imageSrc: "/sponsor/sponsor_berufsschule-rueti.png",
    sponsorUrl: "https://www.bsrueti.ch/",
    alt: "Berufsschule Rüti",
  },
  {
    imageSrc: "/sponsor/sponsor_gewerbliche-berufsschule-wetzikon.png",
    sponsorUrl: "https://www.gbwetzikon.ch/",
    alt: "Gewerbliche Berufsschule Wetzikon",
  },
  {
    imageSrc: "/sponsor/sponsor-bildungszentrum-uster.png",
    sponsorUrl: "https://www.bfsu.ch/",
    alt: "Bildungszentrum Uster",
  },
  {
    imageSrc: "/sponsor/sponsor_bgv-pfaeffikon-zh.png",
    sponsorUrl: "https://www.bgv-pfaeffikon.ch/",
    alt: "BGV Pfäffikon ZH",
  },
  {
    imageSrc: "/sponsor/sponsor_gewerbeverein-pfaeffikon-zh.png",
    sponsorUrl: "https://perle-am-see.ch/",
    alt: "Gewerbeverein Pfäffikon ZH",
  },
  {
    imageSrc: "/sponsor/sponsor_pfaeffikon-zh.png",
    sponsorUrl: "https://www.pfaeffikon.ch/",
    alt: "Pfäffikon ZH",
  },
  {
    imageSrc: "/sponsor/sponsor_bank-avera.png",
    sponsorUrl: "https://www.bank-avera.ch/",
    alt: "Bank AVERA",
  },
  {
    imageSrc: "/sponsor/sponsor_avzo.png",
    sponsorUrl: "https://avzo.ch/",
    alt: "AVZO",
  },
  {
    imageSrc: "/sponsor/sponsor_hubersuhner.png",
    sponsorUrl: "https://www.hubersuhner.com/de",
    alt: "Huber+Suhner",
  },
  {
    imageSrc: "/sponsor/sponsor_ferag.png",
    sponsorUrl: "https://www.ferag.com/",
    alt: "Ferag",
  },
  {
    imageSrc: "/sponsor/sponsor_hostpoint.png",
    sponsorUrl: "https://www.hostpoint.ch/",
    alt: "Hostpoint",
  },
  {
    imageSrc: "/sponsor/sponsor_mock.png",
    sponsorUrl: "https://www.mockschriften.ch/",
    alt: "Mock",
  },
  {
    imageSrc: "/sponsor/sponsor_ehm.png",
    sponsorUrl: "https://ehm.ch/",
    alt: "EHM",
  },
  {
    imageSrc: "/sponsor/sponsor_hustech.png",
    sponsorUrl: "https://www.hustech.ch/",
    alt: "Hustech",
  },
  {
    imageSrc: "/sponsor/sponsor_swissmem.png",
    sponsorUrl: "https://www.swissmem.ch/",
    alt: "Swissmem",
  },
  {
    imageSrc: "/sponsor/sponsor_neukomoptik.png",
    sponsorUrl: "https://neukom-optik.ch/",
    alt: "Neukom Optik",
  },
  {
    imageSrc: "/sponsor/sponsor_crealine.png",
    sponsorUrl: "https://crealine.net/",
    alt: "Crealine",
  },
];

const mainSponsors = [
  {
    imageSrc: gebauerStiftung,
    sponsorUrl: "http://www.gebauerstiftung.ch/",
    alt: "Sponsor Gebauer Stiftung",
  },
  {
    imageSrc: kantonZuerichBildungsdirektion,
    sponsorUrl: "http://www.zh.ch/berufsbildungsfonds",
    alt: "Sponsor Kanton Zürich Bildungsdirektion",
  },
  {
    imageSrc: kgv,
    sponsorUrl: "https://www.kgv.ch/",
    alt: "Sponsor KGV",
  },
  {
    imageSrc: zueriOberland,
    sponsorUrl: "https://www.zuerioberland-wirtschaft.ch/wirtschaftsregion/",
    alt: "Sponsor Zürioberland Wirtschaft",
  },
  {
    imageSrc: zueriOst,
    sponsorUrl: "http://zueriost.ch/",
    alt: "Sponsor Züriost",
  },
];

const FooterWrapper = styled.footer`
  background-color: #212529;
  color: #fff;
  padding: 3rem 0 1rem 0;
`;

const FooterSection = styled.div`
  margin-bottom: 2rem;
`;

const FooterTitle = styled.h5`
  font-weight: bold;
  margin-bottom: 1rem;
  color: #fff;
`;

const FooterList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FooterLink = styled.span`
  a {
    color: #adb5bd;
    text-decoration: none;
    font-size: 0.9rem;
    &:hover {
      color: #fff;
    }
    padding-right: 1rem;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;

  a {
    color: #fff;
    font-size: 1.5rem;
    &:hover {
      color: #0d6efd;
    }
  }
`;

const BottomBar = styled.div`
  border-top: 1px solid #495057;
  padding-top: 1rem;
  margin-top: 2rem;
  font-size: 0.9rem;
`;

const SponsorsContainer = styled.div`
  background-color: white;
  border: 2px solid #0d6efd;
  border-radius: 1rem;
  padding: 1.5rem;
  margin-bottom: 1rem;

  ${FooterTitle} {
    color: #212529;
  }
`;

const SponsorLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
`;

const Footer = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % secondarySponsorImages.length
      );
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  return (
    <FooterWrapper>
      <div className="container">
        <div className="row">
          {/* Sponsors Section */}
          <div className="col-md-12">
            <FooterSection>
              <SponsorsContainer>
                <FooterTitle>Sponsoren</FooterTitle>
                <div className="d-flex flex-wrap gap-4 mb-3">
                  {mainSponsors.map((sponsor, index) => (
                    <SponsorLink
                      key={index}
                      href={sponsor.sponsorUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="my-3 align-self-center mx-2"
                    >
                      <img
                        src={sponsor.imageSrc}
                        alt={sponsor.alt}
                        width={150}
                        height={50}
                        style={{ objectFit: "contain" }}
                      />
                    </SponsorLink>
                  ))}
                </div>
              </SponsorsContainer>
            </FooterSection>
          </div>

          {/* Navigation Links */}
          <div className="col-md-5">
            <div className="row">
              <div className="col-sm-6">
                <FooterSection>
                  <FooterTitle>Besucher</FooterTitle>
                  <FooterList>
                    <li>
                      <FooterLink>
                        <Link href="/besucher/informationen">
                          Informationen
                        </Link>
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink>
                        <Link href="/besucher/lehrstelleninfos">
                          Berufs/Lehrstelleninfos
                        </Link>
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink>
                        <Link href="/besucher/infos-fuer-schulklassen">
                          Infos für Schulklassen
                        </Link>
                      </FooterLink>
                    </li>
                  </FooterList>
                </FooterSection>

                <FooterSection>
                  <FooterTitle>Teilnehmende</FooterTitle>
                  <FooterList>
                    <li>
                      <FooterLink>
                        <Link href="/teilnehmer/zeig-was-du-kannst">
                          Zeig was du kannst!
                        </Link>
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink>
                        <Link href="/teilnehmer/bewertung-und-preise">
                          Bewertung und Preise
                        </Link>
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink>
                        <Link href="/teilnehmer/reglement">Reglement</Link>
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink>
                        <Link href="/teilnehmer/anmeldung">Anmeldung</Link>
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink>
                        <Link href="/teilnehmer/lehrbetriebe">
                          Lehrbetriebe
                        </Link>
                      </FooterLink>
                    </li>
                  </FooterList>
                </FooterSection>
              </div>

              <div className="col-sm-6">
                <FooterSection>
                  <FooterTitle>Über uns</FooterTitle>
                  <FooterList>
                    <li>
                      <FooterLink>
                        <Link href="/ueber-uns/das-ist-der-lwzo">
                          Das ist der LWZO
                        </Link>
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink>
                        <Link href="/ueber-uns/galerie">Galerie</Link>
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink>
                        <Link href="/ueber-uns/wir-suchen">Wir suchen</Link>
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink>
                        <Link href="/ueber-uns/vereinsmaterial">
                          Vereinsmaterial
                        </Link>
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink>
                        <Link href="/ueber-uns/presse-und-downloads">
                          Presse & Downloads
                        </Link>
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink>
                        <Link href="/ueber-uns/sponsoren">Sponsoren</Link>
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink>
                        <Link href="/ueber-uns/kontakt">Kontakt</Link>
                      </FooterLink>
                    </li>
                  </FooterList>
                </FooterSection>

                <FooterSection>
                  <FooterTitle>Projekte</FooterTitle>
                  <FooterList>
                    <li>
                      <FooterLink>
                        <Link href="/projekte/lageplan">Lageplan</Link>
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink>
                        <Link href="/projekte">Projekte</Link>
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink>
                        <Link href="/projekte/personen">Lernende</Link>
                      </FooterLink>
                    </li>
                    <li>
                      <FooterLink>
                        <Link href="/projekte/mix-ansicht">Mix-Ansicht</Link>
                      </FooterLink>
                    </li>
                  </FooterList>
                </FooterSection>
              </div>
            </div>
          </div>

          {/* Social Media Links */}
          <div className="col-md-3">
            <FooterSection>
              <FooterTitle>Social Media</FooterTitle>
              <SocialLinks>
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://youtube.com" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
                <a href="https://linkedin.com" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </SocialLinks>
            </FooterSection>

            {/* New Newsletter Section */}
            <FooterSection>
              <FooterTitle>Newsletter</FooterTitle>
              <FooterList>
                <li>
                  <FooterLink>
                    <Link href="/newsletter">Jetzt anmelden</Link>
                  </FooterLink>
                </li>
              </FooterList>
            </FooterSection>
          </div>
        </div>

        {/* Bottom Bar */}
        <BottomBar className="d-flex justify-content-between">
          <div>
            <FooterLink>
              <Link href="/datenschutz">Datenschutz</Link>
            </FooterLink>
            <FooterLink>
              <Link href="/ueber-uns/kontakt">Kontakt</Link>
            </FooterLink>
          </div>
          <div>© {new Date().getFullYear()} LWZO. Alle Rechte vorbehalten.</div>
        </BottomBar>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
