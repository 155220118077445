import React from "react";

const ReglementPage = () => {
  return (
    <div className="container mb-5 pt-5">
      <h1 className="text-primary mb-4">Reglement</h1>

      <section className="mb-4">
        <h2 className="h4">1. Ziele des Lehrlingswettbewerb Züri-Oberland</h2>
        <p>Der Lehrlingswettbewerb Züri-Oberland (LWZO)</p>
        <ul>
          <li>
            ist eine gute Möglichkeit zur persönlichen Standortbestimmung für
            das Qualifikationsverfahren (QV, ehemals Lehrabschlussprüfung)
          </li>
          <li>
            gibt Lernenden eine grossartige Gelegenheit, ihr berufliches Können
            zu zeigen
          </li>
          <li>
            gibt Einblick in die Bestrebungen der Wirtschaft zur
            Nachwuchsförderung
          </li>
          <li>gewährt Oberstufenklassen Einblick in verschiedene Berufe</li>
        </ul>
      </section>

      <section className="mb-4">
        <h2 className="h4">2. Teilnahmeberechtigung</h2>
        <p>
          Teilnahmeberechtigt sind alle Lernenden während der beruflichen
          Grundbildung vom 1. bis 4. Lehrjahr.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="h4">3. Wettbewerbsarbeiten</h2>
        <p>
          Die Wettbewerbsarbeit – ein Projekt ergänzt z. B. mit Modellen,
          Zeichnungen, Grafiken – muss aus dem zu erlernenden Beruf sein. Sie
          soll dem Lehrjahr und dem Ausbildungsstand angemessen sein. Zugelassen
          sind:
        </p>
        <ul>
          <li>Einzelarbeiten</li>
          <li>
            Teamarbeiten innerhalb des Berufes oder berufsübergreifend mit max.
            6 Teilnehmenden; bei grösseren Teams entscheidet die Standortleitung
          </li>
        </ul>

        <h3 className="h5 mt-3">3.1</h3>
        <p>
          Die Arbeiten sind von den Teilnehmenden selbständig zu erstellen.
          Beratende Unterstützung durch die Ausbildungsbetriebe ist erwünscht,
          jedoch ohne aktive Mitarbeit am Projekt.
        </p>

        <h3 className="h5 mt-3">3.2</h3>
        <p>
          Jede Arbeit muss durch eine schriftliche- und eine Video-Dokumentation
          ergänzt werden.
        </p>

        <p className="mt-3">
          <strong>
            In der schriftlichen Dokumentation müssen folgende Angaben enthalten
            sein:
          </strong>
        </p>
        <ul>
          <li>
            Angaben über die Entstehung: von der Idee bis zur fertigen Arbeit
          </li>
          <li>
            Beschreibung des verwendeten Materials, der Maschinen, Geräte etc.
            (Gewinnung, Beschaffung, Eigenschaften usw.)
          </li>
          <li>
            Arbeitsübersicht mit Angaben über Arbeitsstunden, Hilfs- und
            Lehrmittel und Beratertätigkeit
          </li>
        </ul>

        <p className="mt-3">
          <strong>
            Die Video-Dokumentation soll auf der Online-Plattform einen
            Überblick über die erstellte Arbeit geben. Inhalt:
          </strong>
        </p>
        <ul>
          <li>Kurze Vorstellung der Teilnehmer</li>
          <li>
            Angaben/Bilder über die Entstehung: von der Idee bis zum fertigen
            Projekt
          </li>
          <li>Präsentation der fertigen Arbeit</li>
          <li>Fazit/Schlusswort</li>
          <li>Dauer: 2 bis max. 5 Minuten</li>
        </ul>

        <h3 className="h5 mt-3">3.3</h3>
        <p>
          Die Teilnehmenden präsentieren ihre Projekte möglichst persönlich und
          vor Ort. Insbesondere wird die Anwesenheit während den Klassenbesuchen
          der Oberstufenschüler am Donnerstag und Freitag, sowie während
          möglichst vielen weiteren Ausstellungszeiten, erwartet.
        </p>

        <h3 className="h5 mt-3">3.4</h3>
        <p>Die Arbeiten werden durch Fachexperten bewertet.</p>
        <p>
          Ausgenommen davon sind Projekte von reinen Erstlehrjahrgruppen oder
          Einzelarbeiten im ersten Lehrjahr. Diese Projekte erhalten von einer
          Berufsfachperson eine mündliche, notenfreie Rückmeldung.
        </p>
        <p>Alle Teilnehmenden erhalten ein Teilnahmezertifikat.</p>
      </section>

      <section className="mb-4">
        <h2 className="h4">4. Werbung Ausbildungsbetriebe</h2>
        <p>
          Für Ausbildungsbetriebe ist am Projektstand Werbung von maximal 2
          Quadratmetern zugelassen. Die Standortleitung entscheidet vor Ort über
          die Korrektheit der Werbung.
        </p>
        <p>⇒ Hinweis: Die Projekte sollen im Vordergrund stehen!</p>
        <p>
          Auf der Online-Plattform sind die Ausbildungsbetriebe im Rahmen der
          Wettbewerbsarbeit mit einer Kurzvorstellung inkl. Logo präsent.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="h4">5. Ablauf/Bekanntgabe Termine</h2>
        <p>
          Die genauen Angaben über Ort und Termin der Ablieferung der Arbeiten,
          Bewertung durch die Experten, Eröffnungsfeier, Ausstellung der
          Arbeiten und Zertifikatsabgabe werden rechtzeitig schriftlich bekannt
          gegeben.
        </p>
        <p>
          Folgende Termine sind verbindlich und gelten für das jeweilige
          Ausstellungsjahr:
        </p>
        <ul>
          <li>Anmeldeschluss für Teilnehmende ⇒ Ende August</li>
          <li>Vollständige Abgabe der Standinformationen ⇒ Ende September</li>
          <li>Vollständige Abgabe aller Projektdaten ⇒ Ende Oktober</li>
        </ul>
      </section>

      <section className="mb-4">
        <h2 className="h4">6. An-/Abmeldung</h2>
        <p>Die Anmeldung erfolgt online auf www.lwzo.ch/anmeldung.</p>
        <p>
          Die vollständigen und korrekt eingetragenen Angaben sind Voraussetzung
          für die Teilnahme.
        </p>
        <p>
          ⇒ Hinweis: Daten werden wie erfasst für die Ausstellungbeschriftung,
          Teilnahmezertifikat etc. übernommen!
        </p>
        <p>
          Abmeldungen aus wichtigen Gründen (Unfall, Krankheit, Militärdienst
          usw.), müssen rechtzeitig und schriftlich erfolgen.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="h4">7. Korrespondenz</h2>
        <p>
          Korrespondenzen und Anfragen, die den Lehrlingswettbewerb betreffen,
          sind an das Sekretariat des LWZO zu richten.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="h4">8. Bewertung/Wettbewerbspreise</h2>
        <p>
          Über die Bewertung der Arbeiten und Wettbewerbspreise wird keine
          Korrespondenz geführt.
        </p>
      </section>

      <section className="mb-4">
        <h2 className="h4">9. Versicherung</h2>
        <p>Die Versicherung ist Sache der Teilnehmenden.</p>
      </section>

      <section className="mb-4">
        <h2 className="h4">10. Video-/Bildmaterial</h2>
        <p>
          Das im Rahmen des LWZO gemachte Video- und Bildmaterial darf für
          Werbezwecke und Berichterstattung des Vereins (z. B. Flyer,
          Vereinswebseite, Sonderbeilage der Zeitung) verwendet werden.
        </p>
      </section>

      <p className="text-muted mt-5">Stand: 01.04.2024</p>
    </div>
  );
};

export default ReglementPage;
