import React from "react";

const GaleriePage = () => {
  return (
    <div className="container mb-5 pt-5">
      <h1 className="text-primary mb-4">Galerie / Historie</h1>
      <p>
        Tauche ein in die Vergangenheit des Lehrlingswettbewerbs. Zu jedem Jahr
        gibt es viele spannende und inspirierende Bilder.
      </p>

      <div className="mb-4">
        <h3>LWZO 2024</h3>
        <p>
          Der Lehrlingswettbewerb Züri-Oberland 2024 fand vom 6. bis 9. November
          2024 in Pfäffikon ZH statt. Alle Projekte mit Bildern und Videos sind
          unter{" "}
          <a
            href="http://www.lwzo.ch/online"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.lwzo.ch/online
          </a>{" "}
          zu finden. Hier sind noch einige{" "}
          <a
            href="https://photos.app.goo.gl/JHiy6rkP4M59QQXH8"
            target="_blank"
            rel="noopener noreferrer"
          >
            Impressionen
          </a>{" "}
          der Ausstellungstage zu finden.
        </p>
        <div className="row">
          <div className="col-12">
            <figure className="figure">
              <a
                href="https://photos.google.com/share/AF1QipNM7jIp3VYXF_IzRWtj3opMcVEbwC70I1Lbz6-eHIyM7N9enXtoQGNhLT8Qu9dg_A?key=TE5semx0MjVCODYzTkF3aDd5MUdSVlRoczJHeXhB"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://lehrlingswettbewerb.ch/fileadmin/_processed_/6/5/csm_20241108_LWZO_2024_3818ff2266.png"
                  className="figure-img img-fluid"
                  alt=""
                />
              </a>
            </figure>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <h4>Preisträger vom "Preis der Jury"</h4>
        <ul>
          <li>
            <a
              href="https://digital.lwzo.ch/projekte/442ddedb-d94c-4b06-b8f0-2dcfc71068db"
              target="_blank"
              rel="noopener noreferrer"
            >
              Wagenheber für Motorrad
            </a>
          </li>
          <li>
            <a
              href="https://digital.lwzo.ch/projekte/67f9950d-4c62-41dc-89e9-62f514376c03"
              target="_blank"
              rel="noopener noreferrer"
            >
              Heilende Kunst
            </a>
          </li>
          <li>
            <a
              href="https://digital.lwzo.ch/projekte/5b7bacb8-11ad-42f3-b9ca-da6a65de53d8"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kälteflizer
            </a>
          </li>
          <li>
            <a
              href="https://digital.lwzo.ch/projekte/c2707bdb-98f4-4ee0-8765-cbedc73c727b"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bariesta Möbel
            </a>
          </li>
          <li>
            <a
              href="https://digital.lwzo.ch/projekte/c7b3dfd5-32c6-47e2-9847-bd1a3331369a"
              target="_blank"
              rel="noopener noreferrer"
            >
              OZ Racing PC
            </a>
          </li>
        </ul>
        <p>
          Zusätzlich sind drei Social Media Preise und 15 Sofortpreise verliehen
          worden.
        </p>
      </div>

      <div className="mb-4">
        <h3>LWZO 2023</h3>
        <p>
          Der Lehrlingswettbewerb Züri-Oberland 2023 fand vom 8. bis 11.
          November 2023 in Wetzikon statt. Alle Projekte mit Bildern und Videos
          sind unter{" "}
          <a
            href="http://www.lwzo.ch/online"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.lwzo.ch/online
          </a>{" "}
          zu finden. Hier sind noch einige{" "}
          <a
            href="https://photos.app.goo.gl/VBZ4Ryhd4SLcbTLS7"
            target="_blank"
            rel="noopener noreferrer"
          >
            Impressionen
          </a>{" "}
          der Ausstellungstage zu finden.
        </p>
        <div className="row">
          <div className="col-12">
            <figure className="figure">
              <a
                href="https://photos.app.goo.gl/VBZ4Ryhd4SLcbTLS7"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://lehrlingswettbewerb.ch/fileadmin/_processed_/6/b/csm_20231111_LWZO_2023_4efb4e7d46.png"
                  className="figure-img img-fluid"
                  alt=""
                />
              </a>
            </figure>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <h4>Preisträger</h4>
        <p>Den "Preis der Jury" wurde von der Jury vergeben an die Projekte:</p>
        <ul>
          <li>Teslaspule</li>
          <li>Der vierstöckige Advent</li>
          <li>Nichtmetallische Objekte veredeln</li>
          <li>OAK - DESK</li>
          <li>Ecolibs</li>
        </ul>
        <p>
          Der "Teilnehmerpreis" wurde vergeben durch die anderen Teilnehmenden
          an die Projekte:
        </p>
        <ul>
          <li>Cool &amp; Clever</li>
          <li>Ecolibs</li>
          <li>Feuerzeug</li>
          <li>
            Es hat Beine und kann nicht gehen und du erweckst es zum Leben
          </li>
          <li>No Food 4 U</li>
          <li>Rc Auto mit pneumatischem Antrieb</li>
          <li>Swiss Fort Knox</li>
          <li>Teslaspule</li>
          <li>Lieferdrohne</li>
          <li>Supraleitende Schwebebahn</li>
        </ul>
      </div>

      <div className="mb-4">
        <h3>LWZO 2022</h3>
        <p>
          Der Lehrlingswettbewerb Züri-Oberland 2022 fand vom 9. bis 12.
          November 2022 in Uster statt. Alle Projekte mit Bildern und Videos
          sind unter{" "}
          <a
            href="http://www.lwzo.ch/online"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.lwzo.ch/online
          </a>{" "}
          zu finden. Hier sind noch einige{" "}
          <a
            href="https://photos.google.com/share/AF1QipNuEVRFoOJkYB9P2901CfsdA9mZh0D3m633ivkvtbSjjUfP13805doWZkeWcIo5EQ?key=QnhJVFE1dVc0QW1HUUd5WHlrTVFHSklvTkpiQ3lR"
            target="_blank"
            rel="noopener noreferrer"
          >
            Impressionen
          </a>{" "}
          der Ausstellungstage zu finden.
        </p>
        <div className="row">
          <div className="col-12">
            <figure className="figure">
              <a
                href="https://photos.app.goo.gl/G9PHjYn8ueh5R1b78"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://lehrlingswettbewerb.ch/fileadmin/_processed_/3/c/csm_20221113_LWZO_2022_61905fcee1.jpg"
                  className="figure-img img-fluid"
                  alt=""
                />
              </a>
            </figure>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <p>
          Zum{" "}
          <a
            href="https://zueriost.ch/lifestyle/technik-mobilitaet/2022-11-14/ausgezeichnete-handwerkskunst-am-lehrlingswettbewerb"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bericht mit Interview
          </a>{" "}
          vom Züriost
        </p>
      </div>

      <div className="mb-4">
        <h4>Preisträger</h4>
        <p>Den "Preis der Jury" wurde von der Jury vergeben an die Projekte:</p>
        <ul>
          <li>Kunststofftrennung mit Teraherzstrahlung</li>
          <li>Medienwand</li>
          <li>Rostige Schönheit</li>
          <li>Skyfall Miniatur</li>
          <li>Wald Weihnachten Inspiration</li>
        </ul>
        <p>
          Der "Teilnehmerpreis" wurde vergeben durch die anderen Teilnehmenden
          an die Projekte:
        </p>
        <ul>
          <li>E-Scooter</li>
          <li>Fass bar</li>
          <li>Feuerzeug</li>
          <li>Kart Restauration</li>
          <li>Kunstofftrennung mit teraherzstrahlung</li>
          <li>Medienwand</li>
          <li>Rostige Schönheit</li>
          <li>Skyfall Miniatur</li>
          <li>Wald Weinachten Inspiration</li>
          <li>Wekasten</li>
        </ul>
      </div>

      <div className="mb-4">
        <h3>LWZO Online?! (2021)</h3>
        <p>
          Der Lehrlingswettbewerb Züri-Oberland fand vom 10. bis 14. November
          2021 Online statt. Alle Projekte mit Bildern und Videos sind unter{" "}
          <a
            href="http://www.lwzo.ch/online"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.lwzo.ch/online
          </a>{" "}
          zu finden.
        </p>
        <div className="row">
          <div className="col-12">
            <figure className="figure">
              <img
                src="https://lehrlingswettbewerb.ch/fileadmin/_processed_/1/e/csm_20211115_LWZO_2021_d878b4a53c.png"
                className="figure-img img-fluid"
                alt=""
              />
            </figure>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <h4>Preisträger</h4>
        <p>
          Aus 26 Projekten (Einzel- und Gruppenarbeiten) wählte die Jury fünf
          Arbeiten aus, die prämiert wurden. Herzliche Gratulation!
        </p>
        <ul>
          <li>
            Schreibtisch - <strong>Elisa Spano</strong> - Schreinerin EFZ im 3.
            Lehrjahr
          </li>
          <li>
            Strassenumleitung - <strong>Fabian Dreier</strong> -
            Unterhaltspraktiker EBA im 2. Lehrjahr
          </li>
          <li>
            3D Druck Filament Extruder -{" "}
            <strong>
              Samuel Baumgartner, Joel Frehner, Yanik Germann, Siljan Maurer
            </strong>{" "}
            - Konstrukteure EFZ im 2. Lehrjahr
          </li>
          <li>
            Modellflugzeugturbine - <strong>Niklas Scherrer</strong> -
            Polymechaniker EFZ im 2. Lehrjahr
          </li>
          <li>
            Covid Game - <strong>Nils Hindermann</strong> - Informatiker EFZ im
            2. Lehrjahr
          </li>
        </ul>
      </div>

      <div className="mb-4">
        <h4>Begrüssungsvideo</h4>
      </div>

      <div className="mb-4">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/kxP0cZBSgsM"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="mb-4">
        <h4>Abschluss-Video</h4>
      </div>

      <div className="mb-4">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/-MK8msZhmTE"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="mb-4">
        <h4>Interview vor dem Start</h4>
      </div>

      <div className="mb-4">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/qFzIKs4ESsk"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <div className="mb-4">
        <h3>LWZO Online?! (2020)</h3>
        <p>
          Der Lehrlingswettbewerb Züri-Oberland fand vom 4. bis 8. November 2020
          Online statt. Alle Bilder der Ausstellung finden Sie{" "}
          <a
            href="https://photos.app.goo.gl/J7S1faLjWQifKT7r5"
            target="_blank"
            rel="noopener noreferrer"
          >
            hier
          </a>{" "}
          und weiter unten die Videos. Alle Projekte sind unter{" "}
          <a
            href="http://www.lwzo.ch/online"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.lwzo.ch/online
          </a>{" "}
          zu finden.
        </p>
        <div className="row">
          <div className="col-12">
            <figure className="figure">
              <a
                href="https://photos.app.goo.gl/J7S1faLjWQifKT7r5"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://lehrlingswettbewerb.ch/fileadmin/_processed_/d/d/csm_20201203_lwzo-2020_10a8b0b1ea.png"
                  className="figure-img img-fluid"
                  alt=""
                />
              </a>
            </figure>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <h3>Meilensteine</h3>
      </div>

      <div className="mb-4">
        <p>
          <strong>2010</strong> (ungefähr)
          <br /> Neues Logo welches das Frage und Ausrufezeichen kombiniert.
        </p>
        <div className="row">
          <div className="col-12">
            <figure className="figure">
              <img
                src="https://lehrlingswettbewerb.ch/fileadmin/user_upload/20180216_LWZO_Logo_mit_Text-links_502x87.png"
                className="figure-img img-fluid"
                alt=""
              />
            </figure>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <p>
          <strong>2008</strong>
          <br /> Gründung des Verein Lehrlingswettbewerb Züri-Oberland.
        </p>
      </div>

      <div className="mb-4">
        <p>
          <strong>2000</strong> (ungefähr)
          <br /> Neues Logo bei dem das Fragezeichen sich zu einem
          Ausrufezeichen wandelt.
        </p>
        <div className="row">
          <div className="col-12">
            <figure className="figure">
              <img
                src="https://lehrlingswettbewerb.ch/fileadmin/_processed_/c/7/csm_Logo_c49e23b03b.png"
                className="figure-img img-fluid"
                alt=""
              />
            </figure>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <p>
          <strong>1881</strong>
          <br /> Erster dokumentierter Lehrlingswettbewerb im Züri-Oberland.
        </p>
      </div>
    </div>
  );
};

export default GaleriePage;