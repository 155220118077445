import React from "react";

const BewertungUndPreisePage = () => {
  return (
    <div className="container mb-5 pt-5">
      <h1 className="text-primary mb-4">Bewertung und Preise</h1>

      {/* Auszeichnungen/Preise Section */}
      <section className="mb-5">
        <h2 className="h3 mb-4">Auszeichnungen/Preise</h2>

        <div className="mb-4">
          <h3 className="h4">Preis der Jury</h3>
          <p>
            Die Jury setzt sich aus 3 Mitgliedern aus verschiedenen Branchen
            zusammen und wählt 5 Gewinnerprojekte.
          </p>
        </div>

        <div className="mb-4">
          <h3 className="h4">Zusatzpreise</h3>
          <ul className="list-unstyled">
            <li className="mb-3">
              <strong>Social-Media-Preis</strong> – wer erhält für sein
              Projektpräsentations-Video auf unserer Plattform die meisten
              «Klicks»
              <br />
              <small className="text-muted">3 Gewinner</small>
            </li>
            <li className="mb-3">
              <strong>Sofortpreis</strong> – Verlosung an der Schlussfeier unter
              den anwesenden Teilnehmenden
              <br />
              <small className="text-muted">15 Gewinner</small>
            </li>
          </ul>
        </div>

        <p className="text-muted">
          <em>Hinweis: Änderungen vorbehalten</em>
        </p>
      </section>

      {/* Bewertung Section */}
      <section>
        <h2 className="h3 mb-4">Bewertung</h2>

        <p className="mb-4">
          Jede Projektarbeit wird bewertet, dies heisst bei Gruppenarbeiten
          erhalten alle Teilnehmenden die gleiche Note. Die einzelnen Projekt
          werden durch 2 Fachexperten der jeweiligen Berufsgattung bewertet.
          ExpertInnen sind in der Regel LAP/QV-PrüfungsexpertInnen in den
          entsprechenden Berufen. Die ExpertInnen leisten diese Arbeit
          ehrenamtlich ohne Entschädigung. Für diesen Einsatz danken wir Ihnen
          und wünschen immer wieder interessante Fachgespräche und Begegnungen
          mit unseren Teilnehmenden.
        </p>

        <p className="mb-4">
          In der Regel werden Präsentation der Arbeiten und Fachgespräche mit
          den Experten während den Öffnungszeiten durchgeführt und sind
          öffentlich. Die Besprechung zwischen Experten und Teilnehmenden findet
          im geschlossenen Rahmen statt.
        </p>

        <h3 className="h4 mb-3">Bewertet werden:</h3>
        <div className="ps-3">
          <div className="mb-3">
            <h4 className="h5">Idee:</h4>
            <p>
              Kreativität dem Ausbildungsstand entsprechend und praxisbezogen.
            </p>
          </div>

          <div className="mb-3">
            <h4 className="h5">Ausführung der Arbeit:</h4>
            <p>
              Fachgerechte und saubere Ausführung mit Einhaltung der
              Aufgabenstellung und Schwierigkeit der Arbeit.
            </p>
          </div>

          <div className="mb-3">
            <h4 className="h5">Präsentation der Arbeit:</h4>
            <p>
              Standgestaltung, Informationsgehalt und Attraktivität für
              Besucher.
            </p>
          </div>

          <div className="mb-3">
            <h4 className="h5">Fachgespräch mit Experten:</h4>
            <p>
              Einstieg, Gliederung, methodischer Aufbau, Fachkompetenz und
              Verständlichkeit.
            </p>
          </div>

          <div className="mb-3">
            <h4 className="h5">Dokumentation:</h4>
            <p>
              Vollständig, Aufbau und Gliederung, Zeitplan der Arbeit
              entsprechend.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BewertungUndPreisePage;
