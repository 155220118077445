import React from "react";

const WirSuchenPage = () => {
  return (
    <div className="container mb-5 pt-5">
      <div className="span9">
        <h2 className="text-primary mb-4">Wir suchen ... dich?!</h2>

        <p>
          Der Lehrlingswettbewerb Züri-Oberland (LWZO) bietet verschiedene
          Möglichkeiten, wie du dich engagieren kannst. Zeitaufwand, Themen und
          Aufgaben können mitgestaltet werden. Mit der Neuorganisation Anfangs
          2022 ergibt sich auch eine neue Struktur, die im{" "}
          <a
            href="https://docs.google.com/document/d/1A9ZFPZiafmmgbpZQnWNLLa2qKsIK78QWKntStK4fIuw/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Organigramm
          </a>{" "}
          abgebildet ist. Nebst der Organisation ist auch zu sehen, ob eine
          Position noch frei ist. Nachfolgend sind die wichtigsten
          Beschreibungen der verschiedenen Tätigkeitsfelder.
        </p>

        <h3 className="mt-4">
          <strong>Vorstand</strong>
        </h3>
        <ul>
          <li>Ressort Präsidium (Besetzt)</li>
          <li>Ressort Eventsupport (Besetzt)</li>
          <li>Ressort Öffentlichkeitsarbeit (Besetzt)</li>
          <li>Ressort Finanzen (Besetzt)</li>
          <li>Ressort Standortleitung Wetzikon (Besetzt)</li>
          <li>
            <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2022/Wir_suchen/Ressort_Standortleitung_Bezirk.pdf">
              Ressort Standortleitung Pfäffikon ZH
            </a>{" "}
            (offen)
          </li>
          <li>Ressort Standortleitung Uster (Besetzt)</li>
        </ul>

        <h3 className="mt-4">
          <strong>Funktionen</strong>
        </h3>
        <ul>
          <li>
            <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2022/Wir_suchen/Funktion_Ausbildung.pdf">
              Funktion Ausbildung
            </a>{" "}
            (Gesucht)
          </li>
          <li>
            <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2022/Wir_suchen/Funktion_Gestaltung.pdf">
              Funktion Gestaltung
            </a>{" "}
            (Besetzt und für Mithilfe offen)
          </li>
          <li>
            <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2022/Wir_suchen/Funktion_Informatik.pdf">
              Funktion Informatik
            </a>{" "}
            (Besetzt und für Mithilfe offen)
          </li>
          <li>
            <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2022/Wir_suchen/Funktion_Marketing_und_Kommunikation.pdf">
              Funktion Marketing und Kommunikation
            </a>{" "}
            (Gesucht)
          </li>
          <li>
            <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2022/Wir_suchen/Funktion_Material_und_Transport.pdf">
              Funktion Material und Transport
            </a>{" "}
            (Besetzt und für Mithilfe offen)
          </li>
          <li>
            <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2023/Wir_suchen/Funktion_Projektcoach.pdf">
              Funktion Projektcoach
            </a>{" "}
            (Gesucht)
          </li>
          <li>
            <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2022/Wir_suchen/Funktion_Social_Media.pdf">
              Funktion Sozial Media
            </a>{" "}
            (Besetzt und für Mithilfe offen)
          </li>
          <li>
            <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2022/Wir_suchen/Funktion_Sponsoring.pdf">
              Funktion Sponsoring
            </a>{" "}
            (Gesucht)
          </li>
        </ul>

        <h3 className="mt-4">
          <strong>Standortfunktion</strong>
        </h3>
        <ul>
          <li>Standortfunktion Signalethik</li>
          <li>Standortfunktion Auf- und Abbau</li>
          <li>Standortfunktion Festwirtschaft</li>
          <li>Standortfunktion ...</li>
        </ul>

        <div className="mt-4">
          <p>
            Hast du Interesse? Nimm doch mal unverbindlich mit uns Kontakt auf.
            <br />
            Wir freuen uns auf deine Kontaktaufnahme.
          </p>
          <p>
            Simon Boller
            <br />
            Präsident
            <br />
            079 328 32 38
            <br />
            <a href="mailto:simon.boller@lwzo.ch">simon.boller@lwzo.ch</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default WirSuchenPage;