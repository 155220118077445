import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { ComponentSpec, getComponent } from ".";

export const GENERIC_COMPONENT = "generic_component";
export const GENERIC_ITEM = "generic_item";

export interface IComponent<S> {
  component: React.FC<{ spec: S }>;
}

function DisplayError(props: FallbackProps) {
  return (
    <>
      <p>Component has encountered an error</p>
      <button onClick={props.resetErrorBoundary}>Reload</button>
    </>
  );
}

export const Component = (props: { spec: ComponentSpec }) => {
  const Comp = getComponent(props.spec.type).component;
  return (
    <ErrorBoundary FallbackComponent={DisplayError}>
      <Comp spec={props.spec} />
    </ErrorBoundary>
  );
};
