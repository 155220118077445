import {
  faCaretDown,
  faCaretRight,
  faHeading,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { useDrag } from "react-dnd";
import styled from "styled-components";
import { GENERIC_COMPONENT } from "./Component";
import {
  ComponentItemProps,
  DeleteItemButton,
  ExpandButton,
  TreeItem,
  TreeItemList,
} from "./ComponentTree";
import { DragSpec, IEditableComponent } from "./EditableComponent";
import { NewItem } from "./NewComponents";
import { PageContext } from "../PageContext";
import { Container, SectionComponentSpec, SECTION_COMPONENT } from "./Section";
import { newEmptyVertical } from "./Vertical";
import { Vertical, VerticalItem } from "./VerticalEdit";

const InlineEditTextbox = styled.input`
  display: inherit;
  position: relative;
  min-width: inherit;
  max-width: inherit;
  vertical-align: top;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit;
  font: inherit;
  resize: none;
  background: none;
  outline: none;
  border: none;
  padding: 0;
`;

const TitlePreview = styled.span`
  color: gray;
`;

const EditComponent = observer((props: { spec: SectionComponentSpec }) => {
  const { isEditing } = useContext(PageContext);
  const [isCompEditing, setIsEditing] = useState(false);
  return (
    <Container>
      {isCompEditing ? (
        <h1>
          <InlineEditTextbox
            type="text"
            value={props.spec.title}
            onChange={(event) => (props.spec.title = event.currentTarget.value)}
            onBlur={() => setIsEditing(false)}
          />
        </h1>
      ) : (
        <h1 onClick={isEditing ? () => setIsEditing(true) : undefined}>
          {props.spec.title}
        </h1>
      )}
      <Vertical spec={props.spec.child} name={props.spec.title} />
    </Container>
  );
});

const Properties = observer((props: { spec: SectionComponentSpec }) => {
  return <div>Component has no properties</div>;
});

const Item = observer((props: ComponentItemProps<SectionComponentSpec>) => {
  const [isExpanded, setExpanded] = useState(true);
  const parentRefs = computed(() => [...props.parentRefs, props.spec]);
  return (
    <>
      <TreeItem onClick={props.onSelect} isActive={props.selected} isExpandable>
        <ExpandButton onClick={() => setExpanded(!isExpanded)}>
          <FontAwesomeIcon icon={isExpanded ? faCaretDown : faCaretRight} />
        </ExpandButton>
        <FontAwesomeIcon icon={faHeading} />
        <span> Section </span>
        <TitlePreview>{props.spec.title}</TitlePreview>
        <DeleteItemButton onClick={() => props.onDelete()}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </DeleteItemButton>
      </TreeItem>
      {isExpanded ? (
        <TreeItemList>
          <VerticalItem
            spec={props.spec.child}
            treeState={props.treeState}
            parentRefs={parentRefs.get()}
          />
        </TreeItemList>
      ) : null}
    </>
  );
});

const New = (props: {}) => {
  const [, drag] = useDrag<DragSpec, unknown, unknown>(() => ({
    type: GENERIC_COMPONENT,
    item: {
      spec: {
        type: SECTION_COMPONENT,
        title: "New Section Title",
        child: newEmptyVertical(),
      },
    },
  }));
  return (
    <NewItem
      isActive={false}
      ref={(elem) => {
        drag(elem);
      }}
    >
      <FontAwesomeIcon icon={faHeading} />
      <span> Section </span>
    </NewItem>
  );
};

export const SectionComponent: IEditableComponent<SectionComponentSpec> = {
  component: EditComponent,
  properties: Properties,
  item: Item,
  new: New,
};
