import { observer } from "mobx-react-lite";
import React from "react";
import { ComponentItemProps } from "./ComponentTree";
import { ComponentSpec } from ".";
import { getComponent } from "./editable_index";

export interface IEditableComponent<S> {
  component: React.FC<{ spec: S }>;
  properties: React.FC<{ spec: S }>;
  item: React.FC<ComponentItemProps<S>>;
  new?: React.FC<{}>;
}

export interface DragSpec {
  spec: ComponentSpec;
}

export const Component = observer((props: { spec: ComponentSpec }) => {
  const Comp = getComponent(props.spec.type).component;
  return <Comp spec={props.spec} />;
});

export const ComponentProps = observer((props: { spec: ComponentSpec }) => {
  const Comp = getComponent(props.spec.type).properties;
  return <Comp spec={props.spec} />;
});

export const ComponentItem = observer(
  (props: ComponentItemProps<ComponentSpec>) => {
    const Comp = getComponent(props.spec.type).item;
    return <Comp {...props} />;
  }
);
