import {
  faCaretDown,
  faCaretRight,
  faHeading,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { useDrag } from "react-dnd";
import styled from "styled-components";
import { GENERIC_COMPONENT } from "./Component";
import {
  ComponentItemProps,
  DeleteItemButton,
  ExpandButton,
  TreeItem,
  TreeItemList,
} from "./ComponentTree";
import { DragSpec, IEditableComponent } from "./EditableComponent";
import { NewItem } from "./NewComponents";
import { ContainerSpec, CONTAINER_COMPONENT, StyledCard } from "./Container";
import { newEmptyVertical } from "./Vertical";
import { Vertical, VerticalItem } from "./VerticalEdit";
import { Card } from "react-bootstrap";
import { BarBoolProperty } from "./PropertiesBar";

const EditComponent = observer((props: { spec: ContainerSpec }) => {
  const { spec } = props;

  return (
    <StyledCard
      className={`mb-4 shadow-sm position-static ${
        spec.background ? "bg-light" : ""
      }`}
      hasBorderLeft={spec.borderLeft}
      border={spec.border}
    >
      <Card.Body>
        <Vertical spec={spec.child} />
      </Card.Body>
    </StyledCard>
  );
});

const Properties = observer((props: { spec: ContainerSpec }) => {
  return (
    <div>
      <BarBoolProperty
        name="Background"
        value={props.spec.background}
        onChange={(newVal) => (props.spec.background = newVal)}
      />
      <BarBoolProperty
        name="Border Left"
        value={props.spec.borderLeft}
        onChange={(newVal) => (props.spec.borderLeft = newVal)}
      />
      <BarBoolProperty
        name="Border"
        value={props.spec.border}
        onChange={(newVal) => (props.spec.border = newVal)}
      />
    </div>
  );
});

const Item = observer((props: ComponentItemProps<ContainerSpec>) => {
  const [isExpanded, setExpanded] = useState(true);
  const parentRefs = computed(() => [...props.parentRefs, props.spec]);
  return (
    <>
      <TreeItem onClick={props.onSelect} isActive={props.selected} isExpandable>
        <ExpandButton onClick={() => setExpanded(!isExpanded)}>
          <FontAwesomeIcon icon={isExpanded ? faCaretDown : faCaretRight} />
        </ExpandButton>
        <FontAwesomeIcon icon={faHeading} />
        <span> Container </span>
        <DeleteItemButton onClick={() => props.onDelete()}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </DeleteItemButton>
      </TreeItem>
      {isExpanded ? (
        <TreeItemList>
          <VerticalItem
            spec={props.spec.child}
            treeState={props.treeState}
            parentRefs={parentRefs.get()}
          />
        </TreeItemList>
      ) : null}
    </>
  );
});

const New = (props: {}) => {
  const [, drag] = useDrag<DragSpec, unknown, unknown>(() => ({
    type: GENERIC_COMPONENT,
    item: {
      spec: {
        type: CONTAINER_COMPONENT,
        child: newEmptyVertical(),
        background: false,
        borderLeft: false,
        border: true,
      },
    },
  }));
  return (
    <NewItem
      isActive={false}
      ref={(elem) => {
        drag(elem);
      }}
    >
      <FontAwesomeIcon icon={faHeading} />
      <span> Container </span>
    </NewItem>
  );
};

export const ContainerComponent: IEditableComponent<ContainerSpec> = {
  component: EditComponent,
  properties: Properties,
  item: Item,
  new: New,
};
