import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { Link as RouterLink } from "../../utils/router";
import { IComponent } from "./Component";

export const LINK_LIST = "link_list";

export interface Link {
  name: string;
  url: string;
}

export interface LinkListSpec {
  type: typeof LINK_LIST;
  links: Link[];
  vertical: boolean;
  dense: boolean;
  internal?: boolean;
}

export const Container = styled.div<{ vertical: boolean }>`
  display: flex;
  flex-wrap: wrap;
  ${(props) => (props.vertical ? "flex-flow: column;" : "")}
`;

export const LinkContainer = styled.div<{ dense: boolean; internal?: boolean }>`
  padding: ${(props) => (props.dense ? "5px" : "10px")};

  padding-left: ${(props) => (props.internal ? "15px" : "")};
  padding-right: ${(props) => (props.internal ? "15px" : "")};

  background: ${(props) =>
    props.dense ? "none" : props.internal ? "blue" : "white"};
  ${(props) =>
    props.dense
      ? ""
      : props.internal
      ? "box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)"
      : "box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 1px 3px 1px rgba(60, 64, 67, 0.16)"};

  margin-top: 15px;
  margin-right: 20px;
  margin-bottom: 5px;
  text-decoration: none;
  color: ${(props) => (props.internal ? "white" : "inherit")};
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 3px;
  max-width: fit-content;
`;

export const NameContainer = styled.span`
  min-width: 30px;
  min-height: 12px;
  display: inline-block;
`;

export const LinkIcon = styled(FontAwesomeIcon).attrs({
  icon: faExternalLinkAlt,
})`
  margin-right: 8px;
  color: blue;
`;

const ExtLinkBase = (props: {
  spec: Link;
  dense: boolean;
  internal?: boolean;
}) => {
  return (
    <RouterLink href={props.spec.url}>
      <LinkContainer dense={props.dense} internal={props.internal}>
        <NameContainer>
          {props.internal ? null : <LinkIcon />}
          {props.spec.name}
        </NameContainer>
      </LinkContainer>
    </RouterLink>
  );
};

const Component = (props: { spec: LinkListSpec }) => {
  return (
    <Container vertical={props.spec.vertical}>
      {props.spec.links.map((link) => (
        <ExtLinkBase
          spec={link}
          dense={props.spec.dense}
          internal={props.spec.internal}
        />
      ))}
    </Container>
  );
};

export const LinkList: IComponent<LinkListSpec> = {
  component: Component,
};
