import React from "react";
import { Container, Row, Col, Card, Alert, Button } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faUserPlus,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

const PageTitle = styled.h1`
  color: #007bff;
  margin-bottom: 1.5rem;
`;

const StyledCard = styled(Card)`
  border-top: 4px solid #007bff;
  margin-bottom: 1.5rem;
`;

const AnmeldungPage = () => {
  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col lg={10}>
          <PageTitle>Anmeldung</PageTitle>

          <StyledCard className="shadow-sm">
            <Card.Body>
              <h2>Für den Lehrlingswettbewerb anmelden</h2>
              <p className="lead mb-4">
                Es freut uns, dass du dich für den Lehrlingswettbewerb
                interssierst.
              </p>

              <Card className="bg-light border-0 mb-4">
                <Card.Body>
                  <h5 className="text-primary mb-3">
                    <FontAwesomeIcon icon={faUserPlus} className="me-2" />
                    So einfach geht's
                  </h5>
                  <ol className="mb-0">
                    <li className="mb-2">
                      Um dein Projekt anzumelden, brauchst du ein Login für
                      diese Webseite. Dies kannst du über Login in der
                      Navigationsleiste erstellen.
                    </li>
                    <li className="mb-2">
                      Klicke anschliessend auf den Button "Login" und dann unten
                      am weissen Fenster auf "Registrieren". Gib deine
                      Mailadresse ein und wähle anschliessend ein sicheres
                      Passwort. Das Tool untersützt dich dabei.
                    </li>
                    <li className="mb-0">
                      Sobald du Fortfahren geklickt hast, wird dein Konto
                      erstellt und du automatisch in der Platform eingeloggt.
                      Dort wirst du weiter durch die Anmeldung geführt.
                    </li>
                  </ol>
                </Card.Body>
              </Card>

              <div className="text-center mb-4">
                <Card className="mb-3 border-0">
                  <Card.Body>
                    <img
                      src="https://backend.lwzo.ch/assets/register.png"
                      alt="Registrierungs-Formular"
                      className="img-fluid shadow-sm"
                      style={{ maxWidth: "500px", borderRadius: "8px" }}
                    />
                  </Card.Body>
                </Card>

                <Button variant="primary" href="/login" className="mb-3">
                  Zur Anmeldung
                  <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                </Button>
              </div>

              <Alert
                variant="warning"
                className="d-flex align-items-start mb-0"
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="me-2 mt-1"
                  size="lg"
                />
                <div>
                  <strong>WICHTIG:</strong> Mit dem Erstellen des Logins und der
                  Anmeldung bist du mit dem Reglement zum Lehrlingswettbewerb
                  einverstanden und erlaubst dem Lehrlingswettbewerb die Daten
                  im Rahmen der Tätigkeiten rund um den Wettbewerb zu nutzen.
                </div>
              </Alert>
            </Card.Body>
          </StyledCard>

          <Card className="shadow-sm">
            <Card.Body className="text-center p-4">
              <h5 className="text-primary mb-3">Noch Fragen zur Anmeldung?</h5>
              <p className="mb-3">
                Wir helfen dir gerne weiter. Kontaktiere uns bei Unklarheiten
                oder technischen Problemen.
              </p>
              <Button variant="outline-primary" href="/ueber-uns/kontakt">
                Zum Kontaktformular
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AnmeldungPage;
