import registryFactory from "callback-registry";
import { useEffect, useState } from "react";
import { ComponentSpec } from "./components";
import { ComponentTypes } from "./components/index";
import { getIDToken } from "../utils/auth";

export interface IAMPolicy {
  allowViewFromGroups: string[];
  allowEditFromGroups: string[];
}

export interface Page {
  path?: string;
  title: string;
  description: string;
  accessPolicy: IAMPolicy | null;
  publishTime: number;
  content: ComponentSpec | null;
  revision: number;
}

export interface GetPageRequest {
  path: string;
}

export interface SetPageRequest {
  path: string;
  page: Page;
}

export interface DeletePageRequest {
  path: string;
}

interface PageStatus {
  loading: boolean;
  error: UnavailableError | PermissionDeniedError | NotFoundError | null;
  page: Page | null;
}

const API_BASE = "/cms/";

export class PermissionDeniedError extends Error {
  constructor() {
    super("Permission Denied");
  }
}
export class NotFoundError extends Error {
  constructor() {
    super("Not Found");
  }
}
export class UnavailableError extends Error {}

export async function getPage(req: GetPageRequest): Promise<Page> {
  const headers = new Headers();
  headers.set("Content-Type", "application/json; charset=utf-8");
  const idToken = getIDToken();
  if (idToken !== null) {
    headers.set("Authorization", "Bearer " + idToken);
  }
  const res = await fetch(API_BASE + "get_page", {
    method: "POST",
    headers,
    body: JSON.stringify(req)
  });
  if (!res.ok) {
    if (res.status === 403) {
      throw new PermissionDeniedError();
    }
    if (res.status === 404) {
      throw new NotFoundError();
    }
    throw new UnavailableError("getPage request failed");
  }
  const data = await res.json();
  return data as Page;
}

const pageCache: {
  [url: string]: Page;
} = {};

const initialPageData = document.getElementById("page-data")?.textContent;
if (initialPageData) {
  pageCache[window.location.pathname] = JSON.parse(initialPageData);
}

const pageNotifyRegistry = registryFactory();

const metaNotifyRegistry = registryFactory();

export function usePage(path: string): PageStatus {
  const [pageStatus, setPage] = useState<PageStatus>({
    error: null,
    loading: true,
    page: null
  });
  useEffect(() => {
    if (pageCache[path] !== undefined) {
      setPage({
        error: null,
        loading: true,
        page: pageCache[path]
      });
    } else {
      setPage((old) => ({
        ...old,
        loading: true
      }));
    }
    getPage({ path }).then(
      page => {
        setPage((oldPage) => ({ error: null, loading: false, page: (oldPage.page?.revision === page.revision ? oldPage.page : page)}));
        if(pageCache[path]?.revision !== page.revision)
          pageCache[path] = page;
      },
      err => setPage({ error: err, loading: false, page: null })
    );
    const unregister = pageNotifyRegistry.add(path, () => {
      setPage({
        error: null,
        loading: false,
        page: pageCache[path]
      });
    });
    return unregister;
  }, [path]);
  return pageStatus;
}

export async function setPage(req: SetPageRequest) {
  pageCache[req.path] = req.page;
  pageNotifyRegistry.execute(req.path);
  const headers = new Headers();
  headers.set("Content-Type", "application/json; charset=utf-8");
  const idToken = getIDToken();
  if (idToken !== null) {
    headers.set("Authorization", "Bearer " + idToken);
  }
  const res = await fetch(API_BASE + "set_page", {
    method: "POST",
    headers,
    body: JSON.stringify(req)
  });
  if (!res.ok) {
    throw new Error("setPage request failed");
  }
  metaNotifyRegistry.execute("meta-update");
}

export async function deletePage(req: DeletePageRequest) {
  pageNotifyRegistry.execute(req.path);
  const headers = new Headers();
  headers.set("Content-Type", "application/json; charset=utf-8");
  const idToken = getIDToken();
  if (idToken !== null) {
    headers.set("Authorization", "Bearer " + idToken);
  }
  const res = await fetch(API_BASE + "del_page", {
    method: "POST",
    headers,
    body: JSON.stringify(req)
  });
  if (!res.ok) {
    throw new Error("delPage request failed");
  }
  metaNotifyRegistry.execute("meta-update");
}

interface ListPageMetaRequest {
  prefix: string;
  includeContent: boolean;
}

type ListPageMetaResponse = Page[];

export async function listPageMeta(req: ListPageMetaRequest) {
  const headers = new Headers();
  headers.set("Content-Type", "application/json; charset=utf-8");
  const idToken = getIDToken();
  if (idToken !== null) {
    headers.set("Authorization", "Bearer " + idToken);
  }
  const res = await fetch(API_BASE + "list_pages", {
    method: "POST",
    headers,
    body: JSON.stringify(req)
  });
  if (!res.ok) {
    throw new Error("listPageMeta request failed");
  }
  return (await res.json()) as ListPageMetaResponse;
}

interface PageMetaListStatus {
  error: Error | null;
  loading: boolean;
  pages: Page[] | null;
}

export function usePageMeta(req: ListPageMetaRequest): PageMetaListStatus {
  const [pageMetaStatus, setPageMetaStatus] = useState<PageMetaListStatus>({
    error: null,
    loading: true,
    pages: null
  });
  useEffect(() => {
    listPageMeta(req).then(
      pages => {
        setPageMetaStatus({ error: null, loading: false, pages });
      },
      err => setPageMetaStatus({ error: err, loading: false, pages: null })
    );
    const unregister = metaNotifyRegistry.add("meta-update", () => {
      listPageMeta(req).then(
        pages => {
          setPageMetaStatus({ error: null, loading: false, pages });
        },
        err => setPageMetaStatus({ error: err, loading: false, pages: null })
      );
    });
    return unregister;
  }, [req]);
  return pageMetaStatus;
}

interface PageType {
  pageType: ComponentTypes | null;
}

export interface PageTreeItem extends Omit<Page, "content">, PageType {
  children: PageTreeItem[];
}

function isPrefix(prefix: string[], arr: string[]): boolean {
  if (prefix.length > arr.length) return false;
  return prefix.every((val, i) => val === arr[i]);
}

function pathToArray(path: string): string[] {
  if (!path.startsWith("/")) {
    return [];
  }
  const pathWithoutSlash = path.slice(1);
  const pathParts = pathWithoutSlash.split("/");
  return ["/", ...pathParts];
}

export function pageMetaListToTree(pages: Page[]): PageTreeItem {
  // The first page is always /
  const tree: PageTreeItem = {
    ...pages[0],
    children: [],
    pageType: null,
  };
  let currentPathParts: string[] = ["/"];
  let currentPathRefs: PageTreeItem[] = [tree];
  pages.slice(1).forEach(page => {
    const path = pathToArray(page.path!);
    while (1) {
      if (isPrefix(currentPathParts, path)) {
        while (true) {
          if (currentPathParts.length + 1 === path.length) {
            currentPathParts.push(path[path.length - 1]);
            const item = { ...page, children: [], pageType: page.content?.type ?? null };
            currentPathRefs[currentPathRefs.length - 1].children.push(item);
            currentPathRefs.push(item);
            return;
          } else {
            const itemName = path[currentPathParts.length];
            currentPathParts.push(itemName);
            const item: PageTreeItem = {
              accessPolicy: null,
              children: [],
              description: "",
              publishTime: 0,
              title: itemName,
              path: "/" + currentPathParts.slice(1).join("/"),
              revision: 0,
              pageType: null,
            };
            currentPathRefs[currentPathRefs.length - 1].children.push(item);
            currentPathRefs.push(item);
          }
        }
      } else {
        if (currentPathParts.length <= 1) {
          console.error("Converter tried to jump out of tree");
          return;
        }
        currentPathParts.pop();
        currentPathRefs.pop();
      }
    }
  });
  return tree;
}

export function slugize(str: string): string {
  return encodeURIComponent(
    str
      .toLowerCase()
      .normalize("NFD") // replace special characters with latin alternatives
      .replace("ł", "l") // replace additional spcial characters missed by normalize()
      .replace(/[\u0300-\u036f]/gu, "") // remove
      .replace(/\s/g, "-") // replace spaces with dashes
      .replace(/\./g, "") // remove periods
      .replace(/[^a-zA-Z0-9]/gu, "-") // replace non-alphanumerical characters with dashes
      .replace(/-{2,}/g, "-") // condense consecutive dashes
      .replace(/^-+/g, "") // trim slugs beginning
      .replace(/-+$/g, "") // trim slugs ending
  );
}

export async function submitForm(req: any) {
  const headers = new Headers();
  const idToken = getIDToken();
  if (idToken !== null) {
    headers.set("Authorization", "Bearer " + idToken);
  }
  const valEncoded = new FormData();
  console.log(Object.keys(req));
  Object.keys(req).map(x => valEncoded.append(x, req[x]));
  const res = await fetch(API_BASE + "submit_component", {
    method: "POST",
    headers,
    body: valEncoded
  });
  if (!res.ok) {
    throw new Error("submit failed request failed");
  }
}

export async function processVideo(pagePath: string, videoURL: string, videoID: string) {
  console.log("Processing Video: ", videoID, videoURL, pagePath)

  const headers = new Headers();
  const idToken = getIDToken();
  if (idToken !== null) {
    headers.set("Authorization", "Bearer " + idToken);
  }
  const valEncoded = new FormData();
  valEncoded.append("path", pagePath)
  valEncoded.append("url", videoURL)
  valEncoded.append("id", videoID)
  const res = await fetch(API_BASE + "process_video", {
    method: "POST",
    headers,
    body: valEncoded
  });
  if (!res.ok) {
    throw new Error("process video request failed");
  }
}
