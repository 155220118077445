import React, { JSX, useMemo, useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Blurhash } from "react-blurhash";
import styled from "styled-components";
import useResizeObserver from "use-resize-observer";
import { Image } from "../../lib/generated/src/api/proto/api";
import { getAppropriateImage } from "../../lib/api";

const ImageContainer = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
`;

export const ImageComponent = (props: {
  img: Image;
  lightboxEnabled?: boolean;
  customMaxWidth?: string;
  customMaxHeight?: string;
  objectFit?: "cover" | "contain";
  fill?: boolean;
}) => {
  const [lighboxOpen, setLightboxOpen] = useState(false);

  if (props.img.BaseURL) {
    return (
      <ImageContainer
        style={{ cursor: props.lightboxEnabled ? "pointer" : "auto" }}
      >
        <ImageRenderer
          image={props.img}
          maxWidth={props.customMaxWidth}
          maxHeight={props.customMaxHeight}
          onClick={() => (props.lightboxEnabled ? setLightboxOpen(true) : null)}
          objectFit={props.objectFit}
          fill={props.fill}
        />
        {/* @ts-ignore */}
        <ModalGateway>
          {lighboxOpen ? (
            <Modal onClose={() => setLightboxOpen(false)}>
              <Carousel
                views={[
                  {
                    source: {
                      download: props.img.BaseURL,
                      regular: getAppropriateImage(
                        props.img as any, // TODO: Types are mess with this, but it works currently
                        window.innerWidth,
                        window.innerHeight
                      ),
                      fullscreen: getAppropriateImage(
                        props.img as any,
                        window.screen.availWidth,
                        window.screen.availHeight
                      ),
                    },
                  },
                ]}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </ImageContainer>
    );
  }
  return <div>Missing Image</div>;
};

function ImageRenderer(props: {
  image: Image;
  maxHeight?: string;
  maxWidth?: string;
  onClick?: (e: React.MouseEvent) => void;
  onlyBlurhash?: boolean;
  objectFit?: "cover" | "contain";
  fill?: boolean;
}): JSX.Element {
  if (props.image.DimensionsProcessed.length > 0) {
    return <ProcessedImageRenderer {...props} />;
  }
  return (
    <img
      src={props.image.BaseURL}
      onClick={props.onClick}
      style={{
        maxHeight: props.maxHeight,
        maxWidth: props.maxWidth,
        width: props.image.Width ? props.image.Width : "100%",
        height: props.image.Height ? props.image.Height : "100%",
        objectFit: props.objectFit ? props.objectFit : "cover",
      }}
      alt=""
    />
  );
}

function ProcessedImageRenderer(props: {
  image: Image;
  maxHeight?: string;
  maxWidth?: string;
  onClick?: (e: React.MouseEvent) => void;
  onlyBlurhash?: boolean;
  fill?: boolean;
  objectFit?: "cover" | "contain";
}): JSX.Element {
  const { ref, width, height } = useResizeObserver<HTMLDivElement>();
  const { image, maxHeight, maxWidth, onClick, onlyBlurhash } = props;

  const imageSection = useMemo(() => {
    if (width === undefined || height === undefined) {
      return <></>;
    }

    return (
      <img
        src={getAppropriateImage(image, width, height)}
        height={height}
        width={width}
        style={{
          objectFit: props.objectFit ? props.objectFit : undefined,
        }}
        alt=""
      />
    );
  }, [width, height, image, props.objectFit]);

  return (
    <div
      ref={ref}
      onClick={onClick}
      style={{
        position: "relative",
        aspectRatio:
          props.image.Width && props.image.Height
            ? `${props.image.Width} / ${props.image.Height}`
            : "auto",
        overflow: "hidden",
        maxWidth: maxWidth === "" ? undefined : maxWidth,
        maxHeight: maxHeight === "" ? undefined : maxHeight,
        width: props.fill ? "100%" : "",
        height: props.fill ? "100%" : "",
        objectFit: props.objectFit ? props.objectFit : undefined,
      }}
    >
      <div style={{ position: "absolute" }}>
        {width && height && props.image.BlurHash ? (
          <Blurhash hash={props.image.BlurHash} width={width} height={height} />
        ) : null}
      </div>
      {onlyBlurhash ? null : (
        <div style={{ position: "absolute" }}>{imageSection}</div>
      )}
    </div>
  );
}