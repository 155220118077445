import React from "react";
import { Container, Row, Col, Card, Alert, Badge } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";

const DatenschutzPage = () => {
  return (
    <Container className="py-5">
      <Row className="mb-4">
        <Col>
          <h1 className="display-5 text-primary border-bottom pb-3">Datenschutz</h1>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col md={8}>
          <Card className="shadow-sm mb-4" id="datenschutz">
            <Card.Body>
              <Card.Title as="h2" className="h4 mb-3 text-primary">Datenschutzerklärung</Card.Title>
              <Card.Text>
                Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und die
                datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz,
                DSG) hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf
                Schutz vor Missbrauch ihrer persönlichen Daten. Wir halten diese
                Bestimmungen ein. Persönliche Daten werden streng vertraulich
                behandelt und weder an Dritte verkauft noch weiter gegeben.
              </Card.Text>
              <Card.Text>
                Beim Zugriff auf unsere Webseiten werden folgende Daten in Logfiles
                gespeichert: IP-Adresse, Datum, Uhrzeit, Browser-Anfrage und allg.
                übertragene Informationen zum Betriebssystem resp. Browser.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="shadow-sm mb-4" id="analytics">
            <Card.Body>
              <Card.Title as="h2" className="h4 mb-3 text-primary">
                Datenschutzerklärung für die Nutzung von Plausible Analytics
              </Card.Title>
              <Card.Text>
                Diese Website verwendet Plausible Analytics, ein selbst gehostetes,
                leichtgewichtiges und quelloffenes Webanalyse-Tool. Plausible
                Analytics setzt keine Cookies ein und führt alle Auswertungen
                vollständig anonym durch. Es werden keine personenbezogenen Daten
                gesammelt, keine persistenten Identifikatoren verwendet und kein
                Cross-Site- oder Cross-Device-Tracking durchgeführt.
              </Card.Text>
              <Card.Text>
                Die Analyse dient ausschließlich der Trenderkennung und Überwachung.
                Die Server sind privat und befinden sich in der Schweiz. Plausible
                beschreibt sich selbst wie folgt:
              </Card.Text>
              <blockquote className="blockquote">
                <p className="font-italic">
                  Plausible is intuitive, lightweight and open source web analytics.
                  No cookies and fully compliant with GDPR, CCPA and PECR. Made and
                  hosted in the EU, powered by European-owned cloud infrastructure 🇪🇺
                </p>
              </blockquote>
              <Card.Text>
                Es ist kein Cookie-Banner oder eine Zustimmung gemäß DSGVO
                erforderlich, da Plausible datenschutzfreundlich ist. Alle Messungen
                erfolgen anonym, und die Daten verlassen niemals die EU oder die
                Schweiz.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="shadow-sm mb-4" id="impressum">
            <Card.Body>
              <Card.Title as="h2" className="h4 mb-3 text-primary">Impressum</Card.Title>
              <Card.Text>
                Dieser Webauftritt ist eine Dienstleistung des Vereins
                Lehrlingswettbewerb Züri-Oberland.
              </Card.Text>
              <h3 className="h5 mt-4 mb-3">Kontakt</h3>
              <Alert variant="light" className="border">
                <Row>
                  <Col sm={4} className="fw-bold">Webmaster:</Col>
                  <Col sm={8}>webmaster@lwzo.ch</Col>
                </Row>
                <Row className="mt-2">
                  <Col sm={4} className="fw-bold">Sekretariat:</Col>
                  <Col sm={8}>info@lwzo.ch</Col>
                </Row>
              </Alert>
            </Card.Body>
          </Card>

          <Card className="shadow-sm mb-4" id="haftung">
            <Card.Body>
              <Card.Title as="h2" className="h4 mb-3 text-primary">Haftungsausschluss</Card.Title>
              <Card.Text>
                Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen
                Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und
                Vollständigkeit der Informationen.
              </Card.Text>
              <Card.Text>
                Haftungsansprüche gegen den Autor wegen Schäden materieller oder
                immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw.
                Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der
                Verbindung oder durch technische Störungen entstanden sind, werden
                ausgeschlossen.
              </Card.Text>
              <Card.Text>
                Alle Angebote sind unverbindlich. Der Autor behält es sich
                ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
                gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
                Veröffentlichung zeitweise oder endgültig einzustellen.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="shadow-sm mb-4" id="links">
            <Card.Body>
              <Card.Title as="h2" className="h4 mb-3 text-primary">Haftung für Links</Card.Title>
              <Card.Text>
                Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres
                Verantwortungsbereichs. Es wird jegliche Verantwortung für solche
                Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten
                erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="shadow-sm mb-4" id="urheberrechte">
            <Card.Body>
              <Card.Title as="h2" className="h4 mb-3 text-primary">Urheberrechte</Card.Title>
              <Card.Text>
                Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder
                anderen Dateien auf der Website gehören ausschliesslich dem Verein
                oder den speziell genannten Rechtsinhabern. Für die Reproduktion
                jeglicher Elemente ist die schriftliche Zustimmung der
                Urheberrechtsträger im Voraus einzuholen.
              </Card.Text>
              <Alert variant="info" className="mt-3">
                <p className="mb-0">
                  <strong>Hinweis zu Teilnehmerprojekten:</strong> Die Urheberrechte für Projekte, 
                  Bilder und Texte, welche von Teilnehmern auf diese Website hochgeladen werden, 
                  verbleiben bei den jeweiligen Teilnehmern. Mit dem Hochladen erteilen die Teilnehmer 
                  dem Verein Lehrlingswettbewerb Züri-Oberland jedoch das Recht, diese Inhalte im Rahmen 
                  der Website zu präsentieren und zu archivieren.
                </p>
              </Alert>
            </Card.Body>
          </Card>

          <Card className="shadow-sm mb-4" id="normen">
            <Card.Body>
              <Card.Title as="h2" className="h4 mb-3 text-primary">Normen</Card.Title>
              <Card.Text>
                lehrlingswettbewerb.ch versucht die Normen für moderne Web-Standards
                einzuhalten. Solltest du eine Seite finden, die nicht den Standards
                entspricht, so schreib Sie bitte dem Webmaster: webmaster@lwzo.ch.
              </Card.Text>
            </Card.Body>
          </Card>

          <Row className="gy-4 mb-5">
            <Col md={6}>
              <Card className="shadow-sm h-100" id="hosting">
                <Card.Body>
                  <Card.Title as="h2" className="h4 mb-3 text-primary">
                    <i className="bi bi-server me-2"></i>Hosting
                  </Card.Title>
                  <Card.Text>
                    Das Hosting unserer Website erfolgt auf privaten Servern in der Schweiz. 
                    Dies garantiert höchste Datensicherheit und Einhaltung der Schweizer Datenschutzbestimmungen.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            
            <Col md={6}>
              <Card className="shadow-sm h-100" id="software">
                <Card.Body>
                  <Card.Title as="h2" className="h4 mb-3 text-primary">
                    <i className="bi bi-code-slash me-2"></i>Software
                  </Card.Title>
                  <Card.Text>
                    Diese Website wurde mit modernen Webtechnologien entwickelt:
                  </Card.Text>
                  <div>
                    <Badge bg="secondary" className="me-2 mb-2">React</Badge>
                    <Badge bg="secondary" className="me-2 mb-2">TypeScript</Badge>
                    <Badge bg="secondary" className="me-2 mb-2">Bootstrap 5</Badge>
                    <Badge bg="secondary" className="me-2 mb-2">ESBuild</Badge>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          
          <div className="text-end">
            <p className="text-muted fst-italic">Stand: 17.03.2025</p>
          </div>
        </Col>
        
        <Col md={4}>
          <div className="position-sticky" style={{ top: "2rem" }}>
            <Card className="shadow-sm bg-light">
              <Card.Body>
                <h3 className="h5 mb-3">Inhaltsverzeichnis</h3>
                <ul className="list-unstyled">
                  <li className="mb-2"><a href="#datenschutz" className="text-decoration-none">Datenschutzerklärung</a></li>
                  <li className="mb-2"><a href="#analytics" className="text-decoration-none">Plausible Analytics</a></li>
                  <li className="mb-2"><a href="#impressum" className="text-decoration-none">Impressum</a></li>
                  <li className="mb-2"><a href="#haftung" className="text-decoration-none">Haftungsausschluss</a></li>
                  <li className="mb-2"><a href="#links" className="text-decoration-none">Haftung für Links</a></li>
                  <li className="mb-2"><a href="#urheberrechte" className="text-decoration-none">Urheberrechte</a></li>
                  <li className="mb-2"><a href="#normen" className="text-decoration-none">Normen</a></li>
                  <li className="mb-2"><a href="#hosting" className="text-decoration-none">Hosting</a></li>
                  <li className="mb-2"><a href="#software" className="text-decoration-none">Software</a></li>
                </ul>
              </Card.Body>
            </Card>
            
            <Alert variant="primary" className="mt-4 shadow-sm">
              <h4 className="h5">Datenschutz ist uns wichtig</h4>
              <p className="mb-0">
                Wir sammeln nur die notwendigsten Daten und verwenden datenschutzfreundliche 
                Technologien, die Ihre Privatsphäre respektieren.
              </p>
            </Alert>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DatenschutzPage;