"use client";

import React from "react";
import styled from "styled-components";
import { IComponent } from "./Component";
import { NotFoundComponentSpec } from "./standardcomponents";
import { Link } from "../../utils/router";

// Add a wrapper to ensure minimum full height of viewport
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Container = styled.div`
  text-align: center;
  padding: 4rem 1rem;
  flex: 1 0 auto; // This makes the container grow to fill available space
`;

const Title = styled.h1`
  font-size: 6rem;
  color: #007bff;
  margin-bottom: 1rem;
`;

const Subtitle = styled.h2`
  font-size: 2rem;
  color: #6c757d;
  margin-bottom: 2rem;
`;

const Message = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

const StyledLink = styled(Link)`
  font-size: 1.2rem;
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

interface NotFoundProps {
  path?: string;
  showLink?: boolean;
}

// Standalone component version
export function NotFound({ path, showLink = true }: NotFoundProps) {
  return (
    <PageWrapper>
      <Container>
        <Title>404</Title>
        <Subtitle>Seite nicht gefunden</Subtitle>
        <Message>
          {path
            ? `Keine Seite unter ${path} gefunden`
            : "Entschuldigung, die von Ihnen gesuchte Seite existiert nicht oder wurde verschoben."}
          {!path && (
            <>
              <br />
              Bitte überprüfen Sie die eingegebene URL oder kehren Sie zur
              Startseite zurück.
            </>
          )}
        </Message>
        {showLink && <StyledLink href="/">Zurück zur Startseite</StyledLink>}
      </Container>
    </PageWrapper>
  );
}

// CMS component wrapper version
const Component = (props: { spec: NotFoundComponentSpec }) => {
  return <NotFound path={props.spec.path} />;
};

export const NotFoundComponent: IComponent<NotFoundComponentSpec> = {
  component: Component,
};
