import React, { useState } from "react";

const KontaktPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    role: "besucher",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Here you would typically handle the form submission
    console.log(formData);
  };

  return (
    <div className="container mb-5 pt-5">
      <h1 className="text-primary mb-4">Kontakt</h1>

      <div className="row">
        <div className="col-md-6 mb-4">
          <h2>Direkter Kontakt</h2>
          <p>Simon Boller</p>
          <p>
            <a href="tel:+41793283238">079 328 32 38</a>
          </p>
          <p>
            <a href="mailto:info@lwzo.ch">info@lwzo.ch</a>
          </p>
        </div>

        <div className="col-md-6">
          <h2>Kontaktformular</h2>
          <p className="mb-4">
            Für Fragen, Anregungen und Feedback melden Sie sich bei uns. Wir
            freuen uns, von Ihnen zu lesen!
          </p>

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Vor- und Nachname
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="role" className="form-label">
                Rolle
              </label>
              <select
                className="form-select"
                id="role"
                name="role"
                value={formData.role}
                onChange={handleChange}
                required
              >
                <option value="besucher">Besucher</option>
                <option value="teilnehmer">Teilnehmer</option>
                <option value="helfer">Helfer</option>
                <option value="anderes">Anderes</option>
              </select>
            </div>

            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                E-Mail
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Telefon
              </label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Nachricht
              </label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows={5}
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>

            <button type="submit" className="btn btn-primary">
              Nachricht senden
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default KontaktPage;