import {
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { IComponent } from "./Component";

export const FILES_COMPONENT = "file_list";

export interface FileItem {
  name: string;
  url: string;
  type: string;
}

export interface FilesComponentSpec {
  type: typeof FILES_COMPONENT;
  files: FileItem[];
  vertical: boolean;
  dense: boolean;
}

export const BaseContainer = styled.div<{ vertical: boolean }>`
  display: flex;
  flex-wrap: wrap;
  ${(props) => (props.vertical ? "flex-flow: column;" : "")}
  margin-top: 15px;
`;

export const FileContainer = styled.a<{ dense: boolean }>`
  ${(props) => (props.dense ? "padding: 5px;" : "padding: 10px;")}
  ${(props) => (props.dense ? "margin-bottom: 5px;" : "margin-bottom: 20px;")}
${(props) => (props.dense ? "" : "background: white;")}
${(props) =>
    props.dense
      ? ""
      : "box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.08), 0 1px 3px 1px rgba(60, 64, 67, 0.16);"}

  margin-right: 20px;
  text-decoration: none;
  color: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: fit-content;
`;

function mimeTypeWithoutTypeToFontAwesomeIcon(
  mimeType: string
): IconDefinition {
  switch (mimeType) {
    case "audio":
      return faFileAudio;

    case "image":
      return faFileImage;

    case "video":
      return faFileVideo;

    default:
      return faFile;
  }
}

export function mimeTypeToFontAwesomeIcon(mimeType: string): IconDefinition {
  // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml

  switch (mimeType) {
    // Word
    case "application/vnd.openxmlformats-officedocument.wordprocessingml":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
    case "application/msword":
    case "application/msword-template":
    case "application/vnd.ms-word":
    case "application/vnd.oasis.opendocument.text":
      return faFileWord;

    // Excel
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
    case "application/vnd.oasis.opendocument.spreadsheet":
      return faFileExcel;

    // Powerpoint
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "application/vnd.openxmlformats-officedocument.presentationml.template":
    case "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
    case "application/vnd.oasis.opendocument.presentation":
      return faFilePowerpoint;

    // PDF
    case "application/pdf":
      return faFilePdf;

    // Code
    case "text/html":
    case "application/javascript":
    case "application/json":
      return faFileCode;

    // Alternative / Text
    case "text/csv":
    case "text/plain":
    case "text/richtext":
    case "text/rtf":
      return faFileAlt;

    // Archives
    case "application/zip":
    case "application/gzip":
    case "application/x-zip-compressed":
      return faFileArchive;

    default:
      const parts = mimeType.split("/");
      if (parts.length === 2) {
        return mimeTypeWithoutTypeToFontAwesomeIcon(parts[0]);
      }
      return faFileArchive;
  }
}

export const BaseExtFile = (props: FileItem & { dense: boolean }) => {
  return (
    <FileContainer href={props.url} dense={props.dense}>
      <FontAwesomeIcon
        icon={mimeTypeToFontAwesomeIcon(props.type)}
        color="blue"
      />{" "}
      {props.name}
    </FileContainer>
  );
};

const Component = (props: { spec: FilesComponentSpec }) => {
  return (
    <BaseContainer vertical={props.spec.vertical}>
      {props.spec.files.map((file: FileItem, i) => (
        <BaseExtFile key={file.url} {...file} dense={props.spec.dense} />
      ))}
    </BaseContainer>
  );
};

export const FilesComponent: IComponent<FilesComponentSpec> = {
  component: Component,
};
