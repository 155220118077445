import { SiteMapComponent, SiteMapSpec, SITE_MAP } from "../SiteMap";
import {
  LoginCallbackSpec,
  LOGIN_CALLBACK,
  NotAuthorizedComponentSpec,
  NotFoundComponentSpec,
  NOT_AUTHORIZED_COMPONENT,
  NOT_FOUND_COMPONENT,
} from "./standardcomponents";
import {
  ColumnLayoutComponent,
  ColumnLayoutSpec,
  COLUMN_LAYOUT,
} from "./ColumnLayout";
import { IComponent } from "./Component";
import { FilesComponent, FilesComponentSpec, FILES_COMPONENT } from "./Files";
import { GALLERY, GalleryComponent, GallerySpec } from "./Gallery";
import { ImageComponent, ImageSpec, IMAGE_COMPONENT } from "./Image";
import { ImageStackComponent, ImageStackSpec, IMAGE_STACK } from "./ImageStack";
import { LinkList, LinkListSpec, LINK_LIST } from "./LinkList";
import { NewsComponent, NewsSpec, NEWS_COMPONENT } from "./News";
import { NotAuthorizedComponent } from "./NotAuthorized";
import { NotFoundComponent } from "./NotFound";
import { PageHome, PageHomeSpec, PAGE_HOME } from "../sites/PageHome";
import { PageNormal, PageNormalSpec, PAGE_NORMAL } from "../sites/PageNormal";
import {
  RichTextComponent,
  RichTextComponentSpec,
  RICH_TEXT_COMPONENT,
} from "./RichText";
import {
  SectionComponent,
  SectionComponentSpec,
  SECTION_COMPONENT,
} from "./Section";
import { TabsComponent, TabsSpec, TABS_COMPONENT } from "./Tabs";
import { UnknownComponent } from "./Unknown";
import { VideoComponent, VideoSpec, VIDEO_COMPONENT } from "./video/Video";
import { CONTAINER_COMPONENT, ContainerComponent, ContainerSpec } from "./Container";

export type ComponentSpec =
  | ColumnLayoutSpec
  | LinkListSpec
  | FilesComponentSpec
  | PageNormalSpec
  | PageHomeSpec
  | ImageSpec
  | RichTextComponentSpec
  | SectionComponentSpec
  | NotFoundComponentSpec
  | NotAuthorizedComponentSpec
  | LoginCallbackSpec
  | TabsSpec
  | NewsSpec
  | ImageStackSpec
  | GallerySpec
  | SiteMapSpec
  | VideoSpec
  | ContainerSpec;

export type ComponentTypes =
  | typeof COLUMN_LAYOUT
  | typeof LINK_LIST
  | typeof FILES_COMPONENT
  | typeof PAGE_NORMAL
  | typeof PAGE_HOME
  | typeof IMAGE_COMPONENT
  | typeof RICH_TEXT_COMPONENT
  | typeof SECTION_COMPONENT
  | typeof NOT_FOUND_COMPONENT
  | typeof NOT_AUTHORIZED_COMPONENT
  | typeof LOGIN_CALLBACK
  | typeof TABS_COMPONENT
  | typeof NEWS_COMPONENT
  | typeof IMAGE_STACK
  | typeof GALLERY
  | typeof SITE_MAP
  | typeof VIDEO_COMPONENT
  | typeof CONTAINER_COMPONENT;

// Resolves complicated circular dependency
let componentCache: any = null;

function initComponentCache() {
  componentCache = {
    [COLUMN_LAYOUT]: ColumnLayoutComponent,
    [LINK_LIST]: LinkList,
    [PAGE_NORMAL]: PageNormal,
    [PAGE_HOME]: PageHome,
    [SECTION_COMPONENT]: SectionComponent,
    [FILES_COMPONENT]: FilesComponent,
    [RICH_TEXT_COMPONENT]: RichTextComponent,
    [IMAGE_COMPONENT]: ImageComponent,
    [NOT_FOUND_COMPONENT]: NotFoundComponent,
    [NOT_AUTHORIZED_COMPONENT]: NotAuthorizedComponent,
    [SITE_MAP]: SiteMapComponent,
    [TABS_COMPONENT]: TabsComponent,
    [IMAGE_STACK]: ImageStackComponent,
    [GALLERY]: GalleryComponent,
    [NEWS_COMPONENT]: NewsComponent,
    [VIDEO_COMPONENT]: VideoComponent,
    [CONTAINER_COMPONENT]: ContainerComponent,
  };
}

export function getComponent(type: ComponentTypes): IComponent<ComponentSpec> {
  if (componentCache === null) {
    initComponentCache();
  }
  // This is perfectly type-safe, but TS can't see control-flow dependant types and thus can't
  // understand that the map access above acts as a smart form of type refinement.
  if (componentCache[type] === undefined) {
    return UnknownComponent as any;
  }
  return componentCache[type] as any;
}
