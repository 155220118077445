import React from "react";
import styled from "styled-components";

const NewsletterContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
`;

const Description = styled.div`
  margin-bottom: 2rem;
  line-height: 1.6;
  color: #212529;
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 850px;
  margin-bottom: 2rem;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const NewsletterPage = () => {
  return (
    <>
      <NewsletterContainer>
        <h1>Newsletter</h1>
        <Description>
          <p>
            Bleiben Sie stets informiert über die neuesten Entwicklungen und
            Aktivitäten des Lehrlingswettbewerbs Züri-Oberland. Mit unserem
            Newsletter erhalten Sie regelmässig spannende Einblicke in unsere
            Projekte und Veranstaltungen.
          </p>
          <p>
            Hinweis: Sie können sich jederzeit vom Newsletter abmelden. Nutzen
            Sie dazu einfach den Abmelde-Link, den Sie in jeder
            Newsletter-Ausgabe am Ende der E-Mail finden.
          </p>
        </Description>
        <IframeContainer>
          <StyledIframe
            src="https://newsletter.lehrlingswettbewerb.ch/subscription/form"
            title="Newsletter Anmeldung"
          />
        </IframeContainer>
      </NewsletterContainer>
    </>
  );
};

export default NewsletterPage;