import React, {
  JSX,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useUser } from "../utils/auth";
import {
  NotFoundError,
  PermissionDeniedError,
  UnavailableError,
  usePage,
} from "./api";
import { ComponentSpec } from "./components";
import { Component } from "./components/Component";
import { PageContext } from "./PageContext";
import {
  NOT_AUTHORIZED_COMPONENT,
  NOT_FOUND_COMPONENT,
} from "./components/standardcomponents";
import { PAGE_NORMAL } from "./sites/PageNormal";
import styled from "styled-components";

const EditPage = React.lazy(() => import("./EditPage"));

export default function RenderPage({ path }: { path: string }): JSX.Element {
  const pageState = usePage(path);

  const pageTitle = pageState.page?.title;
  useEffect(() => {
    if (pageTitle) {
      document.title = pageTitle + " - LWZO";
    }
  }, [pageTitle]);
  const user = useUser();
  const canEdit = !!pageState.page?.accessPolicy && user !== null;
  const [isEditing, setEditing] = useState(false);
  const contextValue = useMemo(
    () => ({
      isEditing: pageState.page !== null && isEditing,
      setEditing,
      canEdit,
      origin: window.location.origin,
    }),
    [pageState.page, isEditing, canEdit]
  );

  let errorRender: JSX.Element | null = null;
  if (pageState.error instanceof UnavailableError) {
    errorRender = (
      <PageComp
        spec={makeSystemPage({
          type: NOT_FOUND_COMPONENT,
          path: path,
        })}
      />
    );
  }
  if (pageState.error instanceof NotFoundError) {
    errorRender = (
      <PageComp
        spec={makeSystemPage({
          type: NOT_FOUND_COMPONENT,
          path: path,
        })}
      />
    );
  }
  if (pageState.error instanceof PermissionDeniedError) {
    errorRender = (
      <PageComp
        spec={makeSystemPage({
          type: NOT_AUTHORIZED_COMPONENT,
          sufficientAMRs: [],
        })}
      />
    );
  }

  return (
    <PageContext.Provider value={contextValue}>
      {pageState.page ? (
        isEditing ? (
          <Suspense fallback={<PageComp spec={pageState.page.content} />}>
            <EditPage page={pageState.page} />
          </Suspense>
        ) : (
          <PageComp spec={pageState.page.content} />
        )
      ) : (
        errorRender
      )}
    </PageContext.Provider>
  );
}

const PageContainer = styled.div`
  min-height: calc(100vh - 141px);
`;

export const PageComp = React.memo(
  (props: { spec: ComponentSpec }): JSX.Element => {
    return (
      <PageContainer>
        <Component spec={props.spec} />
      </PageContainer>
    );
  }
);

export function makeSystemPage(contentSpec: ComponentSpec): ComponentSpec {
  return {
    type: PAGE_NORMAL,
    content: {
      children: [contentSpec],
    },
  };
}
