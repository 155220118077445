import React, { useState } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import styled from "styled-components";
import { IComponent } from "./Component";
import { getAppropriateImage } from "./GetImage";
import { ImageRenderer } from "./ImageRenderer";

export const IMAGE_COMPONENT = "image";
export interface ImageSpec {
  type: typeof IMAGE_COMPONENT;
  baseURL: string | null;
  customMaxWidth?: number;
  customMaxHeight?: number;
  aspectRatioNum?: number;
  aspectRatioDenom?: number;
  lightboxEnabled?: boolean;
}

export const ImageContainer = styled.div`
  display: block;
  margin-top: 15px;
`;

export const AspectRatioContainer = styled.div<{ aspectRatio: number }>`
  position: relative;
  ::after {
    display: block;
    content: "";
    padding-bottom: ${(props) => props.aspectRatio * 100}%;
  }
  img,
  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Component = (props: { spec: ImageSpec }) => {
  const [lighboxOpen, setLightboxOpen] = useState(false);

  if (props.spec.baseURL) {
    return (
      <ImageContainer
        style={{ cursor: props.spec.lightboxEnabled ? "pointer" : "auto" }}
      >
        <ImageRenderer
          image={props.spec as any}
          maxWidth={
            props.spec.customMaxWidth === 0
              ? undefined
              : props.spec.customMaxWidth
          }
          maxHeight={
            props.spec.customMaxHeight === 0
              ? undefined
              : props.spec.customMaxHeight
          }
          onClick={() =>
            props.spec.lightboxEnabled ? setLightboxOpen(true) : null
          }
        />
        {/* @ts-ignore */}
        <ModalGateway>
          {lighboxOpen ? (
            <Modal onClose={() => setLightboxOpen(false)}>
              <Carousel
                views={[
                  {
                    source: {
                      download: props.spec.baseURL,
                      regular: getAppropriateImage(
                        props.spec as any, // TODO: Types are mess with this, but it works currently
                        window.innerWidth,
                        window.innerHeight
                      ),
                      fullscreen: getAppropriateImage(
                        props.spec as any,
                        window.screen.availWidth,
                        window.screen.availHeight
                      ),
                    },
                  },
                ]}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </ImageContainer>
    );
  }
  return <div>Missing Image</div>;
};

export const ImageComponent: IComponent<ImageSpec> = {
  component: Component,
};
