import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

const ContentContainer = styled(Container)`
  flex: 1 0 auto;
  padding: 2rem 1rem;
  max-width: 800px;
`;

const Section = styled.section`
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  color: #007bff;
  margin-bottom: 1.5rem;
`;

const Subtitle = styled.h2`
  color: #495057;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  line-height: 1.6;
  color: #212529;
  margin-bottom: 1rem;
`;

const InfoList = styled.ul`
  list-style-type: disc;
  margin-left: 2rem;
  color: #212529;
`;

const LehrstellenInfoPage = () => {
  return (
    <div className="container mb-5 pt-5">
      <Title>Berufs- und Lehrstelleninformationen</Title>

      <Section>
        <Subtitle>biz Uster</Subtitle>
        <Text>
          Das biz in Uster bietet Berufsberatung an. Sie haben Informationen zur
          Berufswahl und Lehrstellen. Komm am Stand des biz Uster vorbei und
          stelle deine Fragen zu Beruf und Ausbildung.
        </Text>
      </Section>

      <Section>
        <Subtitle>berufswahl.zh.ch</Subtitle>
        <Text>
          Viele Informationen zum "Schnuppern und Lehrstellen" lassen sich hier
          finden.
        </Text>
      </Section>

      <Section>
        <Subtitle>berufsberatung.ch</Subtitle>
        <Text>
          Viele Informationen zu verschiedenen Berufen bietet das Portal für
          Berufswahl, Studiumwahl und Laufbahnberatung.
        </Text>
      </Section>

      <Section>
        <Subtitle>Yousty</Subtitle>
        <Text>
          Yousty bietet Informationen zu verschiedenen Berufen,
          Erfahrungsberichte von Lernenden und zeigt auch Lehrstellen einiger
          Lehrfirmen an.
        </Text>
      </Section>

      <Section>
        <Subtitle>Lehrstell.ch</Subtitle>
        <Text>
          Lehrstell.ch bietet Informationen zu verschiedenen Berufen,
          Erfahrungsberichte von Lernenden und zeigt auch Lehrstellen einiger
          Lehrfirmen an.
        </Text>
      </Section>
    </div>
  );
};

export default LehrstellenInfoPage;