import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

const ContentContainer = styled(Container)`
  flex: 1 0 auto;
  padding: 2rem 1rem;
  max-width: 800px;
`;

const Section = styled.section`
  margin-bottom: 2rem;
`;

const Subtitle = styled.h2`
  color: #495057;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  line-height: 1.6;
  color: #212529;
  margin-bottom: 1rem;
`;

const InfoList = styled.ul`
  list-style-type: disc;
  margin-left: 2rem;
  color: #212529;
`;

const VideoContainer = styled.div`
  position: relative;
  width: 80%;
  padding-bottom: 45%; /* 80% of 56.25% (16:9 Aspect Ratio) */
  height: 0;
  overflow: hidden;
  margin-bottom: 2rem;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const InfosFuerSchulklassenPage = () => {
  return (
    <div className="container mb-5 pt-5">
      <h1>Infos für Lehrpersonen und Schüler</h1>

      <Section>
        <Subtitle>Schulklassenbesuche</Subtitle>
        <Text>
          Der Lehrlingswettbewerb Züri-Oberland (LWZO) bietet eine
          ausgezeichnete Gelegenheit für erste Einblicke in die Berufswelt.
          SchülerInnen können verschiedene Berufe kennenlernen und erhalten von
          den anwesenden Lernenden wertvolle Einblicke und Informationen zur
          Ausbildung.
        </Text>
      </Section>

      <Section>
        <Subtitle>Organisation der Klassenbesuche</Subtitle>
        <Text>
          Die Organisation der Klassenbesuche erfolgt durch das biz Uster. Wir
          freuen uns, wenn Sie mit Ihrer Klasse den Lehrlingswettbewerb
          besuchen.
        </Text>
      </Section>

      <Section>
        <Subtitle>Einblicke in Schulklassenbesuche</Subtitle>
        <Text>Sehen Sie sich an, wie Schulklassen den LWZO erleben:</Text>
        <VideoContainer>
          <iframe
            src="https://www.youtube.com/embed/dZDdqJkcHLA"
            title="Schulklassen am LWZO"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </VideoContainer>
      </Section>

      <Section>
        <Subtitle>Vorbereitung für den Besuch</Subtitle>
        <Text>
          Für einen erfolgreichen Besuch empfehlen wir folgende
          Vorbereitungsmöglichkeiten:
        </Text>
        <InfoList>
          <li>Durchführung von Interviews mit Lernenden über:</li>
          <ul>
            <li>
              Berufsspezifische Fragen (Vor- und Nachteile, erforderliche
              Fähigkeiten)
            </li>
            <li>Erfahrungen in der Berufsschule</li>
            <li>Praktische Tipps zur Lehrstellensuche</li>
          </ul>
          <li>Bewertung von Lehrlingsprojekten durch die SchülerInnen</li>
          <li>
            Flexible Zeiteinteilung aufgrund der Berufsschulzeiten der Lernenden
          </li>
        </InfoList>
      </Section>

      <Section>
        <Subtitle>Anmeldung</Subtitle>
        <Text>
          Die Anmeldephase beginnt Ende August. Für weitere Informationen und
          Anmeldungen können Sie sich an folgende Stelle wenden:
        </Text>
        <Text>
          biz Uster
          <br />
          Brunnenstrasse 1<br />
          8610 Uster
          <br />
          Tel: 043 258 49 00
          <br />
          E-Mail: biz.uster@ajb.zh.ch
        </Text>
      </Section>

      <Section>
        <Subtitle>Wichtiger Hinweis</Subtitle>
        <Text>
          Wir ermutigen die SchülerInnen ausdrücklich dazu, auch Berufe und
          Projekte kennenzulernen, die möglicherweise nicht zu ihren primären
          Berufswünschen gehören. Dies ermöglicht eine breitere Perspektive auf
          die Berufswelt und kann neue interessante Möglichkeiten aufzeigen.
        </Text>
      </Section>
    </div>
  );
};

export default InfosFuerSchulklassenPage;
