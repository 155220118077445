import {
  faFont,
  faListOl,
  faListUl,
  faQuoteRight,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  convertFromRaw,
  convertToRaw,
  DefaultDraftBlockRenderMap,
  DraftEditorCommand,
  Editor,
  EditorState,
  getDefaultKeyBinding,
  getVisibleSelectionRect,
  Modifier,
  RawDraftContentState,
  RichUtils,
} from "draft-js";
import { Map as ImmutableMap } from "immutable";
import { observer } from "mobx-react-lite";
import { nanoid } from "nanoid";
import React, {
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDrag } from "react-dnd";
import styled from "styled-components";
import { useStateWithDeps } from "use-state-with-deps";
import { GENERIC_COMPONENT } from "./Component";
import { ComponentItemProps, DefaultItem } from "./ComponentTree";
import { DragSpec, IEditableComponent } from "./EditableComponent";
import { NewItem } from "./NewComponents";
import { PageContext } from "../PageContext";
import {
  EditorStyle,
  RichTextComponentSpec,
  RICH_TEXT_COMPONENT,
} from "./RichText";
import "./RichTextExt.css";

type CustomDraftEditorCommand = DraftEditorCommand | "soft-insert";

const blockRenderMap = ImmutableMap({
  unstyled: {
    element: "p",
  },
});

// Include 'paragraph' as a valid block and updated the unstyled element but
// keep support for other draft default block types
const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

const ToolbarContainer = styled.div<{ left: number; top: number }>`
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  transform: translate(-50%) scale(1);
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
  background: black;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  padding-left: 12px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  padding-right: 12px;

  z-index: 2;
  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: black;
    border-width: 4px;
    margin-left: -4px;
  }
  &:before {
    border-color: rgba(221, 221, 221, 0);
    border-top-color: #ddd;
    border-width: 6px;
    margin-left: -6px;
  }
`;

const ToolbarButton = styled.button`
  color: white;
  background: black;
  border: none;
  font-size: 16px;
  padding: 6px;
  &:hover {
    background: #2f2f2f;
  }
`;

interface toolbarTypes {
  label: string;
  blockType?: string;
  inlineStyle?: string;
  icon?: IconDefinition;
}

const TOOL_BAR_TYPES: toolbarTypes[] = [
  { label: "F", inlineStyle: "BOLD" },
  { label: "K", inlineStyle: "ITALIC" },
  { label: "H1", blockType: "header-one" },
  { label: "H2", blockType: "header-two" },
  { label: "H3", blockType: "header-three" },
  { label: "H4", blockType: "header-four" },
  { label: "UL", blockType: "unordered-list-item", icon: faListUl },
  { label: "OL", blockType: "ordered-list-item", icon: faListOl },
  { label: "Blockquote", blockType: "blockquote", icon: faQuoteRight },
];

const RichText = observer((props: { spec: RichTextComponentSpec }) => {
  if (props.spec.content.blocks[0]?.key === "A") {
    props.spec.content.blocks[0].key = nanoid(5);
  }
  if (props.spec.id === undefined) {
    props.spec.id = nanoid(5);
  }
  const [editorState, setEditorState] = useStateWithDeps(
    EditorState.createWithContent(convertFromRaw(props.spec.content)),
    [props.spec.id]
  );

  const handleKeyBinding = useCallback((e: React.KeyboardEvent) => {
    if (e.shiftKey && e.key === "Enter") {
      return "soft-insert";
    } else if (e.key === "Enter") {
    }
    return getDefaultKeyBinding(e);
  }, []);

  const handleKeyCommand = useCallback(
    (command: CustomDraftEditorCommand, editorState: EditorState) => {
      //let newState;
      if (command === "soft-insert") {
        const newState = RichUtils.insertSoftNewline(editorState);
        if (newState) {
          setEditorState(newState);
          return "handled";
        }
      } else if (command === "split-block") {
        console.log("split block");

        // get current Block
        let content = editorState.getCurrentContent();
        const currentBlock = content.getBlockForKey(
          editorState.getSelection().getStartKey()
        );

        // Note: if the previous block is of header type
        if (currentBlock.getType().includes("header")) {
          console.log("changing format");

          // Add new empty block
          const contentState = Modifier.splitBlock(
            editorState.getCurrentContent(),
            editorState.getSelection()
          );
          const newState = EditorState.push(
            editorState,
            contentState,
            "split-block"
          );

          // change new block to unstyled
          const contentNew = newState.getCurrentContent();
          const contentFinal = Modifier.setBlockType(
            contentNew,
            newState.getSelection(),
            "unstyled"
          );
          const finalState = EditorState.push(
            newState,
            contentFinal,
            "change-block-type"
          );

          setEditorState(finalState);
          return "handled";
        }
      } else {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
          setEditorState(newState);
          return "handled";
        }
      }
      return "not-handled";
    },
    []
  );

  const editor = React.useRef<Editor>(null);

  const handleBlur = useCallback(() => {
    props.spec.content = convertToRaw(editorState.getCurrentContent());
  }, [editorState, props.spec.content]);

  const [toolbarState, setToolbarState] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const selection = editorState.getSelection();
  useEffect(() => {
    setTimeout(() => {
      if (selection.isCollapsed() || !selection.getHasFocus())
        return setToolbarState(null);
      const editorRef = editor.current;
      const editorNode = editorRef?.editorContainer;
      if (!editorNode) return setToolbarState(null);
      const editorRootRect = editorNode.getBoundingClientRect();
      const selectionRect = getVisibleSelectionRect(window);
      if (!selectionRect) return;
      const extraTopOffset = -40;
      setToolbarState({
        top:
          editorRootRect.top +
          window.pageYOffset +
          (selectionRect.top - editorRootRect.top) +
          extraTopOffset,
        left:
          editorRootRect.left +
          (selectionRect.left - editorRootRect.left) +
          selectionRect.width / 2,
      });
    });
  }, [selection]);

  const handleInlineClick = useCallback(
    (inlineType: string) => {
      setEditorState(RichUtils.toggleInlineStyle(editorState, inlineType));
    },
    [editorState, setEditorState]
  );

  const handleBlockClick = useCallback(
    (blockType: string) => {
      setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    },
    [editorState, setEditorState]
  );

  const BlockStyleControls = () => {
    return (
      <div>
        {TOOL_BAR_TYPES.map((type) => (
          <ToolbarButton
            onMouseDown={() => {
              if (type.blockType) {
                handleBlockClick(type.blockType);
              } else if (type.inlineStyle) {
                handleInlineClick(type.inlineStyle);
              }
            }}
          >
            {type.icon ? <FontAwesomeIcon icon={type.icon} /> : type.label}
          </ToolbarButton>
        ))}
      </div>
    );
  };

  const { isEditing } = useContext(PageContext);

  const onEsc = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      editor.current?.blur();
    }
  };

  console.log(toolbarState);

  return (
    <div onKeyDown={onEsc}>
      <EditorStyle>
        <Editor
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          readOnly={!isEditing}
          onChange={setEditorState}
          onBlur={handleBlur}
          blockRenderMap={extendedBlockRenderMap}
          ref={editor}
          keyBindingFn={handleKeyBinding}
        />
      </EditorStyle>
      {toolbarState ? (
        <ToolbarContainer
          top={toolbarState.top}
          left={toolbarState.left}
          onMouseDown={(e) => e.preventDefault()}
        >
          <BlockStyleControls />
        </ToolbarContainer>
      ) : null}
    </div>
  );
});

const RichTextComponentProps = observer(() => {
  return <div>This component has no properties</div>;
});

const TextComponentItem = observer(
  (props: ComponentItemProps<RichTextComponentSpec>) => {
    return <DefaultItem {...props} name="Rich Text" icon={faFont} />;
  }
);

const New = (props: {}) => {
  const baseRawContent: RawDraftContentState = {
    blocks: [
      {
        key: "A",
        text: "Enter text here",
        type: "unstyled",
        depth: 0,
        entityRanges: [],
        inlineStyleRanges: [],
      },
    ],
    entityMap: {},
  };

  const [, drag] = useDrag<DragSpec, unknown, unknown>(() => ({
    type: GENERIC_COMPONENT,
    item: {
      spec: {
        type: RICH_TEXT_COMPONENT,
        content: baseRawContent,
      },
    },
  }));
  return (
    <NewItem
      isActive={false}
      ref={(elem) => {
        drag(elem);
      }}
    >
      <FontAwesomeIcon icon={faFont} />
      <span> Rich Text </span>
    </NewItem>
  );
};

export const RichTextComponent: IEditableComponent<RichTextComponentSpec> = {
  component: RichText,
  properties: RichTextComponentProps,
  item: TextComponentItem,
  new: New,
};
