import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import styled from "styled-components";

const PageTitle = styled.h1`
  color: #007bff;
  margin-bottom: 1.5rem;
`;

const EventCard = styled(Card)`
  margin-bottom: 1rem;
  border-left: 4px solid #007bff;
`;

const BesucherInfoPage = () => {
  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col lg={10}>
          <PageTitle>Informationen</PageTitle>

          <Card className="mb-4 shadow-sm">
            <Card.Body>
              <h2>Der Lehrlingswettbewerb Züri-Oberland</h2>
              <p className="mb-3">
                Am Lehrlingswettbewerb Züri-Oberland (LWZO) erwarten Sie
                vielseitige Projekte von Teilnehmenden aus verschiedensten
                Berufen. Der LWZO ist ein beliebter Treffpunkt im und für das
                Züri-Oberland und zieht jedes Jahr zahlreiche Besucher und
                Schulklassen an.
              </p>
            </Card.Body>
          </Card>

          <Card className="mb-4 shadow-sm">
            <Card.Body>
              <h2>Datum</h2>
              <p className="mb-3">6. bis 9. November 2024</p>

              <h2>Öffnungszeiten & Anlässe</h2>

              <EventCard>
                <Card.Body>
                  <h4 className="h5 text-primary mb-2">
                    Mittwoch, 6. November 2024
                  </h4>
                  <p className="mb-1 ms-3">18.00 - 19.00 Uhr Eröffnungsfeier</p>
                  <p className="mb-1 ms-3">19.00 - 21.00 Uhr</p>
                </Card.Body>
              </EventCard>

              <EventCard>
                <Card.Body>
                  <h4 className="h5 text-primary mb-2">
                    Donnerstag, 7. November 2024
                  </h4>
                  <p className="mb-1 ms-3">08.00 bis 12.00 Uhr</p>
                  <p className="mb-1 ms-3">13.00 bis 18.30 Uhr</p>
                  <p className="mb-1 ms-3">
                    18.30 bis 20.30 Uhr Podiumsgespräch
                  </p>
                </Card.Body>
              </EventCard>

              <EventCard>
                <Card.Body>
                  <h4 className="h5 text-primary mb-2">
                    Freitag, 8. November 2024
                  </h4>
                  <p className="mb-1 ms-3">08.00 bis 12.00 Uhr</p>
                  <p className="mb-1 ms-3">13.00 bis 18.30 Uhr</p>
                </Card.Body>
              </EventCard>

              <EventCard>
                <Card.Body>
                  <h4 className="h5 text-primary mb-2">
                    Samstag, 9. November 2024
                  </h4>
                  <p className="mb-1 ms-3">11.00 - 15.00 Uhr</p>
                  <p className="mb-1 ms-3">15.00 - 16.15 Uhr Abschlussfeier</p>
                </Card.Body>
              </EventCard>
            </Card.Body>
          </Card>

          <Card className="mb-4 shadow-sm">
            <Card.Body>
              <Row>
                <Col md={6}>
                  <h2>Veranstaltungsort</h2>
                  <p className="mb-3">
                    Mettlenstrasse 19c, Gebäude G, 8330 Pfäffikon ZH
                  </p>
                </Col>
                <Col md={6}>
                  <h2>Kostenloser Zutritt</h2>
                  <p className="mb-3">
                    Dank unseren Sponsoren und Partnern ist der Zutritt
                    kostenlos.
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="mb-4 shadow-sm">
            <Card.Body>
              <h2>Anreise</h2>
              <Row className="gy-3">
                <Col sm={6}>
                  <Card className="h-100 border-0 bg-light">
                    <Card.Body>
                      <h5 className="text-primary mb-2">
                        Mit öffentlichen Verkehrsmitteln
                      </h5>
                      <p>
                        Mit dem ÖV bis Bushaltestelle Mettlenstrasse oder zu
                        Fuss vom Bahnhof Pfäffikon, Fussweg ca. 15 Min.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={6}>
                  <Card className="h-100 border-0 bg-light">
                    <Card.Body>
                      <h5 className="text-primary mb-2">Mit dem Auto</h5>
                      <p>
                        Mit dem Auto bis Sporthalle Mettlen, kostenpflichtige
                        Parkplätze.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BesucherInfoPage;
