import React from "react";
import styled from "styled-components";
import { IComponent } from "../Component";

export interface UnknownSpec {
  type: string;
}

const Container = styled.div`
  border: 1px dashed orange;
  padding: 5px;
  background-color: white;
  border-radius: 10px;
`;

export const Component = (props: { spec: UnknownSpec }) => {
  return (
    <Container>Unknown component: {props.spec.type ?? "unknown"}</Container>
  );
};

export const UnknownComponent: IComponent<UnknownSpec> = {
  component: Component,
};
