import React from "react";
import styled from "styled-components";
import { IComponent } from "./Component";
import { Vertical, VerticalSpec } from "./Vertical";
import { Card } from "react-bootstrap";

export const CONTAINER_COMPONENT = "container";
export interface ContainerSpec {
  type: typeof CONTAINER_COMPONENT;
  child: VerticalSpec;
  background: boolean;
  borderLeft: boolean;
  border: boolean;
}

export const StyledCard = styled(Card)<{
  hasBorderLeft: boolean;
  border: boolean;
}>`
  ${(props) => !props.border && "border: 0px;"}
  ${(props) => props.hasBorderLeft && "border-left: 4px solid #007bff;"}
`;

const ContainerRender = (props: { spec: ContainerSpec }) => {
  const { spec } = props;

  return (
    <StyledCard
      className={`mb-4 shadow-sm ${spec.background ? "bg-light" : ""}`}
      hasBorderLeft={spec.borderLeft}
      border={spec.border}
    >
      <Card.Body>
        <Vertical spec={spec.child} />
      </Card.Body>
    </StyledCard>
  );
};

export const ContainerComponent: IComponent<ContainerSpec> = {
  component: ContainerRender,
};
