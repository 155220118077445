import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faCaretDown,
  faCaretRight,
  faColumns,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useDrag } from "react-dnd";
import { GENERIC_COMPONENT } from "./Component";
import {
  ComponentItemProps,
  DeleteItemButton,
  ExpandButton,
  TreeItem,
  TreeItemList,
} from "./ComponentTree";
import { DragSpec, IEditableComponent } from "./EditableComponent";
import { NewItem } from "./NewComponents";
import { BarBoolProperty, BarButton, BarNumberProperty } from "./PropertiesBar";
import { ColumnLayout, ColumnLayoutSpec, COLUMN_LAYOUT } from "./ColumnLayout";
import { newEmptyVertical, verticalDI } from "./Vertical";
import { Vertical, VerticalItem } from "./VerticalEdit";

const ColumnLayoutEdit = observer(verticalDI(ColumnLayout, Vertical));

const Properties = observer((props: { spec: ColumnLayoutSpec }) => {
  return (
    <div>
      <BarBoolProperty
        name="Dynamically Sized"
        value={props.spec.dynamicSized}
        onChange={(newVal) => (props.spec.dynamicSized = newVal)}
      />
      <BarBoolProperty
        name="Vertical Separators"
        value={props.spec.verticalSeparators}
        onChange={(newVal) => (props.spec.verticalSeparators = newVal)}
      />
      <BarBoolProperty
        name="Center Separators"
        value={props.spec.centerSeparator}
        onChange={(newVal) => (props.spec.centerSeparator = newVal)}
      />
      <BarNumberProperty
        name="Space between columns"
        value={props.spec.spaceBetween}
        onChange={(newVal) => (props.spec.spaceBetween = newVal)}
      />
      <div>
        <BarButton onClick={() => props.spec.columns.push(newEmptyVertical())}>
          Add Column
        </BarButton>
        <BarButton onClick={() => props.spec.columns.pop()}>
          Remove Column
        </BarButton>
      </div>
    </div>
  );
});

const Item = observer((props: ComponentItemProps<ColumnLayoutSpec>) => {
  const [isExpanded, setExpanded] = useState(true);
  const parentRefs = computed(() => [...props.parentRefs, props.spec]);
  return (
    <>
      <TreeItem onClick={props.onSelect} isActive={props.selected} isExpandable>
        <ExpandButton onClick={() => setExpanded(!isExpanded)}>
          <FontAwesomeIcon icon={isExpanded ? faCaretDown : faCaretRight} />
        </ExpandButton>
        {"  "}
        <FontAwesomeIcon icon={faColumns} />
        <span> {props.spec.columns.length} Columns </span>
        <DeleteItemButton
          onClick={() => {
            props.onDelete();
          }}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </DeleteItemButton>
      </TreeItem>
      {isExpanded ? (
        <TreeItemList paddingLeft={8}>
          {props.spec.columns.map((child, i) => (
            <VerticalItem
              key={child.id}
              spec={child}
              name={`Column ${i + 1}`}
              treeState={props.treeState}
              parentRefs={parentRefs.get()}
            />
          ))}
        </TreeItemList>
      ) : null}
    </>
  );
});

const New = (props: {}) => {
  const [, drag] = useDrag<DragSpec, unknown, unknown>(() => ({
    type: GENERIC_COMPONENT,
    item: {
      spec: {
        type: COLUMN_LAYOUT,
        columns: [newEmptyVertical(), newEmptyVertical()],
        dynamicSized: false,
        spaceBetween: 10,
        verticalSeparators: false,
        centerSeparator: false,
      },
    },
  }));
  return (
    <NewItem
      isActive={false}
      ref={(elem) => {
        drag(elem);
      }}
    >
      <FontAwesomeIcon icon={faColumns} />
      <span> Columns </span>
    </NewItem>
  );
};

// TODO: create edit component

export const ColumnLayoutComponent: IEditableComponent<ColumnLayoutSpec> = {
  component: ColumnLayoutEdit,
  item: Item,
  properties: Properties,
  new: New,
};
