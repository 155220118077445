import React from "react";
import styled from "styled-components";

const VideoContainer = styled.div`
  position: relative;
  width: 80%;
  padding-bottom: 45%; /* 80% of 56.25% (16:9 Aspect Ratio) */
  height: 0;
  overflow: hidden;
  margin-bottom: 2rem;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const LehrbetriebePage = () => {
  return (
    <div className="container mb-5 pt-5">
      <h1 className="text-primary mb-4">Lehrbetriebe</h1>

      <div className="mb-4">
        <p>
          Der Lehrlingswettbewerb Züri-Oberland bietet Lernenden die Plattform,
          ihr Projekt, den Beruf und Lehrbetrieb zu präsentieren. Es sind alle
          Berufe herzlich willkommen und die Ausstellung lebt von der Vielfalt.
          Beispiele von vergangenen Projekten sind unter{" "}
          <a
            href="https://digital.lwzo.ch"
            target="_blank"
            rel="noopener noreferrer"
          >
            digital.lwzo.ch
          </a>{" "}
          zu finden.
        </p>
      </div>

      <VideoContainer>
        <iframe
          src="https://www.youtube-nocookie.com/embed/5Vmj6sVNMAI"
          title="LWZO Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoContainer>

      <div className="mb-4">
        <h2 className="h4 mb-3">
          Wie sollen die Lernenden unterstützt werden?
        </h2>
        <ul className="list-unstyled">
          <li>• Informieren und motivieren für die Teilnahme</li>
          <li>• Unterstützung bei der Findung der Projektidee</li>
          <li>• Zeit für die Erarbeitung des Projektes</li>
          <li>• Material und Maschinen für die Realisation</li>
          <li>
            • Zeit für die Präsenz an den Ausstellungstage, so können die
            Lernenden zum Projekt, Beruf und Lehrbetrieb Auskunft geben
          </li>
          <li>• Begleiten der Lernenden während der gesamten Projektzeit</li>
          <li>• Mitfeiern bei der Eröffnungs- und Schlussfeier</li>
        </ul>
      </div>

      <div className="mb-4">
        <p>
          Bei Fragen, Ideen und so weiter nehmen sie ungeniert Kontakt auf mit:
        </p>
        <p>
          Simon Boller
          <br />
          079 328 32 38
          <br />
          <a href="mailto:simon.boller@lwzo.ch">simon.boller@lwzo.ch</a>
        </p>
      </div>

      <div className="mb-4">
        <h2 className="h4 mb-3">
          Finanzielle Unterstützung für Betriebe und Verbände
        </h2>
        <p>
          Der Berufsbildungsfonds des Kantons Zürich unterstützt Zürcher
          Lehrbetriebe und Verbände finanziell, die ihre leistungsstarken
          Lernenden während der Lehre besonders fördern. Die Lehrbetriebe
          erbringen ein erhöhtes Engagement oder stellen Arbeitszeit zur
          Verfügung, wenn sie ihre talentierten Lernenden unterstützen. Die
          Kriterien und Voraussetzungen für eine finanzielle Unterstützung sowie
          Informationen zur Talentförderung sind auf der Webseite{" "}
          <a
            href="https://www.talentfoerderungplus.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Talentförderung Plus
          </a>{" "}
          nachzulesen.
        </p>
        <p>
          Nächste Eingabefrist: 30. September 2024, dabei ist das
          Antragsformular online verfügbar.
        </p>
      </div>

      <div>
        <p>
          Fragen rund um die Talentförderung beantwortet Ihnen gerne die
          Kontaktstelle:
        </p>
        <address className="mb-0">
          Kanton Zürich
          <br />
          Bildungsdirektion
          <br />
          Mittelschul- und Berufsbildungsamt
          <br />
          Abteilung Fachstellen, Entwicklung und Projekte
          <br />
          <br />
          Isabelle Sterchi Pelizzari
          <br />
          Projekte Berufsbildung
          <br />
          Ausstellungsstrasse 80
          <br />
          8090 Zürich
          <br />
          Telefon <a href="tel:+41432597856">043 259 78 56</a>
          <br />
          Präsenzzeit Mo+Do
          <br />
          <a href="mailto:isabelle.sterchi@mba.zh.ch">
            isabelle.sterchi@mba.zh.ch
          </a>
          <br />
          <a
            href="https://www.talentfoerderungplus.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.talentfoerderungplus.ch
          </a>
        </address>
      </div>
    </div>
  );
};

export default LehrbetriebePage;
