import {
  faArrowsAlt,
  faFile,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { arrayMoveMutable } from "array-move";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useDrag } from "react-dnd";
import { useDropzone } from "react-dropzone";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import styled from "styled-components";
import { GENERIC_COMPONENT } from "./Component";
import { ComponentItemProps, DefaultItem } from "./ComponentTree";
import { DragSpec, IEditableComponent } from "./EditableComponent";
import { NewItem } from "./NewComponents";
import { BarBoolProperty } from "./PropertiesBar";
import {
  BaseContainer,
  FileContainer,
  FileItem,
  FilesComponentSpec,
  FILES_COMPONENT,
  mimeTypeToFontAwesomeIcon,
} from "./Files";

const InlineEditTextbox = styled.input`
  display: inline-block;
  position: relative;
  min-width: inherit;
  max-width: inherit;
  vertical-align: top;
  text-transform: inherit;
  letter-spacing: inherit;
  color: inherit;
  font: inherit;
  resize: none;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  :focus {
    outline: dashed gray 1px;
  }
`;

export const Container = SortableContainer<any>(BaseContainer);

const DragHandle = SortableHandle((props: {}) => (
  <FontAwesomeIcon icon={faArrowsAlt} />
));

export const ExtFile = SortableElement<any>(
  observer(
    (props: { spec: FileItem; dense: boolean; onDelete?: () => void }) => {
      const [isEditing, setEditing] = useState(false);
      return (
        <FileContainer dense={props.dense}>
          <DragHandle />{" "}
          <FontAwesomeIcon
            icon={mimeTypeToFontAwesomeIcon(props.spec.type)}
            color="blue"
          />{" "}
          {isEditing ? (
            <InlineEditTextbox
              type="text"
              value={props.spec.name}
              onChange={(event) =>
                (props.spec.name = event.currentTarget.value)
              }
              onBlur={() => setEditing(false)}
            />
          ) : (
            <span onClick={() => setEditing(true)}>{props.spec.name}</span>
          )}{" "}
          <FontAwesomeIcon icon={faTrash} onClick={props.onDelete} />
        </FileContainer>
      );
    }
  )
);

function removeExtension(filename: string): string {
  return filename.substr(0, filename.lastIndexOf(".")) || filename;
}

const EditableFileComponent = observer(
  (props: { spec: FilesComponentSpec }) => {
    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        acceptedFiles.forEach(async (f) => {
          const url = await (
            await fetch(
              "/api/upload?" +
                new URLSearchParams({
                  filename: f.name,
                })
            )
          ).text();
          // TODO: Placeholder, retry and status
          const res = await fetch(url, {
            method: "PUT",
            body: f,
          });
          console.log(res);
          // TODO: check res
          const downloadURL = url.split("?")[0];
          props.spec.files.push({
            name: removeExtension(f.name),
            url: downloadURL,
            type: f.type,
          });
        });
      },
      [props.spec.files]
    );
    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      multiple: true,
    });
    const onSortEnd = useCallback(
      ({ oldIndex, newIndex }: any) => {
        runInAction(() => {
          arrayMoveMutable(props.spec.files, oldIndex, newIndex);
        });
      },
      [props.spec.files]
    );
    return (
      <Container
        onSortEnd={onSortEnd}
        axis="xy"
        useDragHandle
        vertical={props.spec.vertical}
      >
        {props.spec.files.map((file: FileItem, i) => (
          <ExtFile
            key={file.url}
            index={i}
            spec={file}
            onDelete={() => props.spec.files.splice(i, 1)}
            dense={props.spec.dense}
          />
        ))}
        <FileContainer {...getRootProps()} dense={props.spec.dense}>
          <FontAwesomeIcon icon={faPlus} />
          <input {...getInputProps()} />
        </FileContainer>
      </Container>
    );
  }
);

const Properties = observer((props: { spec: FilesComponentSpec }) => {
  return (
    <div>
      <BarBoolProperty
        name="Vertical Items"
        value={props.spec.vertical}
        onChange={(newVal) => (props.spec.vertical = newVal)}
      />

      <BarBoolProperty
        name="Dense"
        value={props.spec.dense}
        onChange={(newVal) => (props.spec.dense = newVal)}
      />
    </div>
  );
});

const Item = observer((props: ComponentItemProps<FilesComponentSpec>) => {
  return <DefaultItem {...props} icon={faFile} name="Files" />;
});

const New = (props: {}) => {
  const [, drag] = useDrag<DragSpec, unknown, unknown>(() => ({
    type: GENERIC_COMPONENT,
    item: {
      spec: {
        type: FILES_COMPONENT,
        files: [],
        vertical: false,
        dense: false,
      },
    },
  }));
  return (
    <NewItem isActive={false} ref={(elem) => {drag(elem)}}>
      <FontAwesomeIcon icon={faFile} />
      <span> File List </span>
    </NewItem>
  );
};

export const FilesComponent: IEditableComponent<FilesComponentSpec> = {
  component: EditableFileComponent,
  properties: Properties,
  item: Item,
  new: New,
};
