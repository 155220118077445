import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PubAPI } from "../../../lib/api";
import { ProjectList } from "../../../lib/generated/src/api/proto/api";
import { ProjectCard } from "../ProjectOverview";
import PlaceIcon from "./icon_place_blue.png";
import Hallenplan from "./hallenplan.png";
import { Link } from "../../../utils/router";

const Jumbotron = styled.section`
  background-color: #fff;
`;

const Location = styled.div<{ $x: number; $y: number }>`
  position: absolute;
  top: calc(${(props) => props.$y + "%"} - 35px);
  left: calc(${(props) => props.$x + "%"} - 17.5px);
  z-index: 100;
`;

// pin size is hardcoded as offset in the location calc above so that the tip is at the specified position
const Pin = styled.img`
  width: 35px;
  height: 35px;
`;

const LageplanPage = () => {
  return (
    <>
      <div className="text-center">
        {/* <BesucherSection /> */}
        <LagePlan />
        {/* <KontaktSection /> */}
      </div>
    </>
  );
};

const LagePlan = () => {
  const [project, setProject] = useState<ProjectList[]>([]);
  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await PubAPI.GetProjects({
          year: 2024,
        });
        if (!ignore) {
          setProject(res.projects);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  const pLocation = project.map((p) => {
    if (p.PosX === 0 && p.PosY === 0) {
      return null;
    }
    return (
      <Link href={`/projekte/${p.ID}`} key={p.ID}>
        <Location $x={p.PosX} $y={p.PosY}>
          <Pin src={PlaceIcon}></Pin>
        </Location>
      </Link>
    );
  });

  return (
    <div className="container text-center mb-5">
      <h1>Lageplan</h1>
      <p className="lead text-muted">Finde alle Stände auf einen Blick.</p>
      <div style={{ position: "relative" }}>
        {pLocation}
        <img src={Hallenplan} style={{ width: "100%" }} alt="Lageplan"></img>
      </div>
    </div>
  );
};

function BesucherSection() {
  const [project, setProject] = useState<ProjectList[]>([]);
  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await PubAPI.GetProjects({
          limit: 3,
          year: 2024,
          withCoverImage: true,
          projectType: [1, 2, 3], // Einzelarbeit = 1, Gruppenarbeit = 2, Spezialfall = 3
        });
        if (!ignore) {
          setProject(res.projects);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div className="container mb-5">
      <h1>Willkommen auf LWZO Online?!</h1>
      <br></br>
      <h2>So funktionierts...</h2>
      <p className="lead text-muted">
        Staune über die vielfältigen Projekte, welche mit Bildern, Videos und
        Dokumenten vorgestestellt werden.
      </p>

      <div className="album py-2">
        <div className="container">
          <div className="row">
            {project.map((proj) => {
              return (
                <ProjectCard
                  title={proj.Title}
                  img={proj.CoverImage}
                  projectID={proj.ID}
                  key={proj.ID}
                  year={proj.Year}
                  typ={proj.ProjectType}
                ></ProjectCard>
              );
            })}
          </div>
        </div>
      </div>

      <Link href="/projekte">
        <button className="btn btn-primary my-2 mx-2">
          Hier geht es zu allen anderen Projekten
        </button>
      </Link>
      <br></br>
      <br></br>
      <p className="lead text-muted">
        Finde Lernende via Lehrberuf, Lehrfirma, Name.
      </p>
      <Link href="/projekte/personen">
        <button className="btn btn-primary my-2 mx-2">Zu den Lernenden</button>
      </Link>
      <br></br>
      <br></br>
      <p className="lead text-muted">Mache dir eine Übersicht.</p>
      <Link href="/projekte/mix-ansicht">
        <button className="btn btn-primary my-2 mx-2">Zur Mix-Ansicht</button>
      </Link>
    </div>
  );
}

const KontaktSection = () => {
  return (
    <div className="container mb-5">
      <h2>Kontakt</h2>
      <p>
        Die Geschäftsstelle des Lehrlingswettbewerb Züri-Oberland unterstützt
        dich gerne bei anderen Fragen.
        <br></br>
        Melde dich bei via <a href="mailto:info@lwzo.ch">info@lwzo.ch</a>
      </p>
    </div>
  );
};

export default LageplanPage;
