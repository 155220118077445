import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { ComponentSpec } from "./components";
import { ComponentItem } from "./EditableComponent";
import { TreeContext } from "./ItemContext";

export const TreeItem = styled.div<{
  isActive: boolean;
  isExpandable?: boolean;
}>`
  padding: 5px 45px 5px 10px;
  ${(props) => (props.isExpandable ? "margin-left: -25px;" : "")}
  background-color: ${(props) => (props.isActive ? "#4b565c" : "none")};
  box-sizing: content-box;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const TreeItemList = styled.ul<{ paddingLeft?: number }>`
  list-style-type: none;
  padding-left: ${(props) => `${props.paddingLeft ?? 12}px`};
  margin-top: 0;
  margin-bottom: 0;
`;

export const ExpandButton = styled.button`
  background: none;
  border: none;
  border-radius: 5px;
  width: 20px;
  color: white;
  :hover {
    background: rgba(255, 255, 255, 0.2);
  }
  :active {
    background: rgba(255, 255, 255, 0.1);
  }
  :focus {
    outline: none;
  }
`;

export const DeleteItemButton = styled.button`
  background: none;
  border: none;
  border-radius: 5px;
  position: absolute;
  right: 20px;
  top: 2px;
  height: 25px;
  color: white;
  :hover {
    background: rgba(255, 255, 255, 0.2);
  }
  :active {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const DefaultItem = observer(
  <T extends {}>(props: {
    icon: IconProp;
    name: string;
    onDelete: (newComponent?: ComponentSpec) => void;
    onSelect: () => void;
    selected: boolean;
  }) => {
    return (
      <>
        <TreeItem onClick={props.onSelect} isActive={props.selected}>
          <FontAwesomeIcon icon={props.icon} />
          <span> {props.name} </span>
          <DeleteItemButton
            onClick={() => {
              props.onDelete();
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </DeleteItemButton>
        </TreeItem>
      </>
    );
  }
);

export interface TreeState {
  selectedComponent: ComponentSpec | null;
}

export interface ComponentItemProps<S> {
  spec: S;
  onSelect: () => void;
  selected: boolean;
  onDelete: (newComponent?: ComponentSpec) => void;
  treeState: TreeState;
  parentRefs: ComponentSpec[];
}

export const ComponentTree = (props: {
  spec: ComponentSpec;
  treeState: TreeState;
}): React.ReactElement => {
  return (
    <TreeItemList paddingLeft={0}>
      <ComponentItem
        spec={props.spec}
        onSelect={() => (props.treeState.selectedComponent = props.spec)}
        selected={props.treeState.selectedComponent === props.spec}
        onDelete={(newComponent) => undefined}
        treeState={props.treeState}
        parentRefs={[]}
      />
    </TreeItemList>
  );
};
