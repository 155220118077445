import React, { MutableRefObject } from "react";
import shaka from "shaka-player/dist/shaka-player.ui";
import "@fontsource/material-icons-rounded";
import "./video.css";

/**
 * A React component for shaka-player.
 * @param {string} src
 * @param {shaka.extern.PlayerConfiguration} config
 * @param {boolean} autoPlay
 * @param {number} width
 * @param {number} height
 * @param ref
 * @returns {*}
 * @constructor
 */

export default React.forwardRef(
  ({ src, config, chromeless, className, onError, ...rest }: any, ref) => {
    const uiContainerRef = React.useRef<HTMLDivElement>(null);
    const videoRef = React.useRef<HTMLVideoElement>(null);

    const [player, setPlayer] = React.useState<shaka.Player | null>(null);
    const [ui, setUi] = React.useState<shaka.ui.Overlay | null>(null);
    const [available, setAvailable] = React.useState<boolean>(true);
    // Effect to handle component mount & mount.
    // Not related to the src prop, this hook creates a shaka.Player instance.
    // This should always be the first effect to run.
    React.useEffect(() => {
      const player = new shaka.Player(videoRef.current);
      setPlayer(player);

      let ui: { destroy: () => void } | null = null;
      if (
        !chromeless &&
        uiContainerRef.current !== null &&
        videoRef.current !== null
      ) {
        ui = new shaka.ui.Overlay(
          player,
          uiContainerRef.current,
          videoRef.current
        );
        setUi(ui);
      }

      return () => {
        player.destroy();
        if (ui) {
          ui.destroy();
        }
      };
    }, []);

    // Keep shaka.Player.configure in sync.
    React.useEffect(() => {
      if (player && config) {
        player.configure(config);
      }
    }, [player, config]);

    // Load the source url when we have one.
    React.useEffect(() => {
      if (player && src) {
        player.load(src).catch((err: any) => {
          console.log(err);
          setAvailable(false);
        });
      }
    }, [player, src]);

    // Define a handle for easily referencing Shaka's player & ui API's.
    React.useImperativeHandle(
      ref,
      () => ({
        get player() {
          return player;
        },
        get ui() {
          return ui;
        },
        get videoElement() {
          return videoRef.current;
        },
      }),
      [player, ui]
    );

    if (!available) {
      if (onError) {
        onError("Es ist ein Fehler aufgetreten beim Laden des Videos.");
        return <></>;
      } else {
        return <h3>Es ist ein Fehler aufgetreten beim Laden des Videos.</h3>;
      }
    }
    return (
      <div ref={uiContainerRef} className={className}>
        <video
          ref={videoRef}
          style={{
            maxWidth: "100%",
            width: "100%",
            height: "100%",
          }}
          {...rest}
        />
      </div>
    );
  }
);
