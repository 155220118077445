import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faArrowsAlt, faTh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { arrayMoveMutable } from "array-move";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { CSSProperties, PropsWithChildren, useCallback } from "react";
import { useDrag } from "react-dnd";
import { useDropzone } from "react-dropzone";
import Gallery, { PhotoProps, RenderImageProps } from "react-photo-gallery";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import styled from "styled-components";
import { GENERIC_COMPONENT } from "./Component";
import { ComponentItemProps, DefaultItem } from "./ComponentTree";
import { DragSpec, IEditableComponent } from "./EditableComponent";
import { NewItem } from "./NewComponents";
import {
  GALLERY,
  GallerySpec,
  ImageContainer,
  ImagePlaceholder,
} from "./Gallery";

const EditingOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  margin: 2px;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

const IconButton = styled.button`
  display: block;
  margin: 10px;
  background: none;
  border: none;
  height: 40px;
  width: 40px;
  color: white;
  :hover {
    color: #ccc;
  }
`;

const PhotoHandle = SortableHandle(() => (
  <IconButton>
    <FontAwesomeIcon icon={faArrowsAlt} size="2x" />
  </IconButton>
));

const imgWithClick = { cursor: "pointer" };

function photoFixup<T extends object>(
  photo: PhotoProps<T>
): Omit<Omit<PhotoProps<T>, "sizes">, "srcSet"> & {
  sizes?: string;
  srcSet?: string;
} {
  if (Array.isArray(photo.sizes) || Array.isArray(photo.srcSet)) {
    return {
      ...photo,
      sizes: Array.isArray(photo.sizes) ? photo.sizes.join(",") : photo.sizes,
      srcSet: Array.isArray(photo.srcSet)
        ? photo.srcSet.join(",")
        : photo.srcSet,
    };
  }
  // TS is not smart enough to use guard-inferred types for non-conditional things
  return photo as any;
}

export const Photo = ({
  index,
  onClick,
  photo,
  margin,
  direction,
  top,
  left,
  onDelete,
}: PropsWithChildren<RenderImageProps<{}>> & {
  onDelete: () => void;
}) => {
  const imgStyle: CSSProperties = { margin: margin, display: "block" };
  if (direction === "column") {
    imgStyle.position = "absolute";
    imgStyle.left = left;
    imgStyle.top = top;
  }

  return (
    <div style={{ position: "relative" }}>
      <EditingOverlay>
        <PhotoHandle />
        <IconButton onClick={onDelete}>
          <FontAwesomeIcon icon={faTrashAlt} size="2x" />
        </IconButton>
      </EditingOverlay>
      <img
        style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
        {...photoFixup(photo)}
        onClick={(event) => {
          onClick?.(event, { index });
        }}
        alt="img"
      />
    </div>
  );
};

const SortablePhoto = SortableElement(Photo);
const SortableGallery = SortableContainer<any>(Gallery);

export const EditComponent = observer((props: { spec: GallerySpec }) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach(async (f) => {
      const url = await (await fetch("/api/upload")).text();
      const size = await new Promise<{
        width: number;
        height: number;
      }>((resolve, reject) => {
        const img = new Image();
        img.onload = function () {
          const sizes = {
            //@ts-ignore
            width: this.width,
            //@ts-ignore
            height: this.height,
          };
          //@ts-ignore
          URL.revokeObjectURL(this.src);
          resolve(sizes);
        };
        img.onerror = function () {
          reject(new Error("Failed to load image"));
        };
        const objectURL = URL.createObjectURL(f);
        img.src = objectURL;
      });
      // TODO: Placeholder, retry and status
      const res = await fetch(url, { method: "PUT", body: f });
      // TODO check res
      console.log(res);
      const downloadURL = url.split("?")[0];
      props.spec.images.push({
        baseURL: downloadURL,
        ...size,
      });
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
  });
  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: any) => {
      runInAction(() => {
        arrayMoveMutable(props.spec.images, oldIndex, newIndex);
      });
    },
    [props.spec.images]
  );
  if (props.spec.images.length >= 1) {
    return (
      <ImageContainer>
        <SortableGallery
          targetRowHeight={150}
          photos={props.spec.images.map((x) => ({
            src: x.dimensionsProcessed
              ? x.baseURL +
                "-" +
                (x.dimensionsProcessed.find(
                  (y) =>
                    y >=
                    150 *
                      window.devicePixelRatio *
                      ((x.aspectRatio ?? 1) > 1 ? x.aspectRatio ?? 1 : 1)
                ) ?? x.dimensionsProcessed[x.dimensionsProcessed.length - 1])
              : x.baseURL,
            width: x.width,
            height: x.height,
          }))}
          renderImage={(innerProps: any) => (
            <SortablePhoto
              onDelete={() => props.spec.images.splice(innerProps.index, 1)}
              {...innerProps}
            />
          )}
          axis="xy"
          onSortEnd={onSortEnd}
          useDragHandle
        />
        <div style={{ paddingBottom: "20px" }} />
        <ImagePlaceholder {...getRootProps()}>
          <div>
            <div>
              <FontAwesomeIcon
                icon={faTh}
                size="3x"
                style={{ marginBottom: "10px" }}
              />
            </div>
            <div>Select or drag images here to append to gallery</div>
            <input {...getInputProps()} />
          </div>
        </ImagePlaceholder>
      </ImageContainer>
    );
  }
  return (
    <ImagePlaceholder {...getRootProps()}>
      <div>
        <div>
          <FontAwesomeIcon
            icon={faTh}
            size="3x"
            style={{ marginBottom: "10px" }}
          />
        </div>
        <div>Select or drag images here</div>
        <input {...getInputProps()} />
      </div>
    </ImagePlaceholder>
  );
});

const Properties = observer((props: { spec: GallerySpec }) => {
  return (
    <div>
      <div>Editing not yet available</div>
    </div>
  );
});

const Item = observer((props: ComponentItemProps<GallerySpec>) => {
  return <DefaultItem {...props} icon={faTh} name="Gallery" />;
});

const New = (props: {}) => {
  const [, drag] = useDrag<DragSpec, unknown, unknown>(() => ({
    type: GENERIC_COMPONENT,
    item: {
      spec: {
        type: GALLERY,
        images: [],
      },
    },
  }));
  return (
    <NewItem
      isActive={false}
      ref={(elem) => {
        drag(elem);
      }}
    >
      <FontAwesomeIcon icon={faTh} />
      <span> Gallery </span>
    </NewItem>
  );
};

export const GalleryComponent: IEditableComponent<GallerySpec> = {
  component: EditComponent,
  properties: Properties,
  item: Item,
  new: New,
};
