import { DateTime } from "luxon";
import React, { useMemo } from "react";
import styled from "styled-components";
import { usePageMeta } from "../api";
import { IComponent } from "./Component";
import { Link } from "../../utils/router";

export const NEWS_COMPONENT = "news";
export interface NewsSpec {
  type: typeof NEWS_COMPONENT;
  prefix: string;
  limit: number;
}

const NewsItem = styled.div`
  margin: 10px 0;
`;

const DateView = styled.div`
  color: #4d4d4d;
  font-size: 14px;
`;

const NewsTitle = styled.div`
  font-weight: bold;
  font-size: 20px;
`;
const MoreLink = styled.div`
  font-size: 14px;
  color: blue;
`;

const NewsSnippet = styled.div``;

export const Component = (props: { spec: NewsSpec }) => {
  const prefix = props.spec.prefix;
  const req = useMemo(
    () => ({
      prefix: prefix,
      includeContent: false,
    }),
    [prefix]
  );
  const pageMetaStatus = usePageMeta(req);

  return (
    <div>
      <>
        {pageMetaStatus.loading ? <span>Loading...</span> : null}
        {pageMetaStatus.error ?? null}
        {pageMetaStatus.pages
          ? pageMetaStatus.pages
              .sort((a, b) => {
                if (a.publishTime > b.publishTime) return -1;
                if (a.publishTime < b.publishTime) return 1;
                return 0;
              })
              .filter((_, i) => i < props.spec.limit)
              .map((page) => (
                <NewsItem>
                  <DateView>
                    {DateTime.fromSeconds(page.publishTime).toLocaleString(
                      {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      },
                      { locale: "de-CH" }
                    )}
                  </DateView>
                  <NewsTitle>
                    <Link href={page.path!}>{page.title}</Link>
                  </NewsTitle>
                  <NewsSnippet>{page.description}</NewsSnippet>
                  <MoreLink>
                    <Link href={page.path!}>Mehr...</Link>
                  </MoreLink>
                </NewsItem>
              ))
          : null}
      </>
    </div>
  );
};

export const NewsComponent: IComponent<NewsSpec> = {
  component: Component,
};
