import { ComponentSpec, ComponentTypes } from ".";
import { IEditableComponent } from "./EditableComponent";
import { SiteMapComponent, SITE_MAP } from "../SiteMap";
import { PAGE_HOME } from "../sites/PageHome";
import { PageHome } from "../sites/PageHomeEdit";
import { PAGE_NORMAL } from "../sites/PageNormal";
import { PageNormal } from "../sites/PageNormalEdit";
import { COLUMN_LAYOUT } from "./ColumnLayout";
import { ColumnLayoutComponent } from "./ColumnLayoutEdit";
import { FILES_COMPONENT } from "./Files";
import { FilesComponent } from "./FilesEdit";
import { GALLERY } from "./Gallery";
import { GalleryComponent } from "./GalleryEdit";
import { IMAGE_COMPONENT } from "./Image";
import { ImageComponent } from "./ImageEdit";
import { IMAGE_STACK } from "./ImageStack";
import { ImageStackComponent } from "./ImageStackEdit";
import { LINK_LIST } from "./LinkList";
import { LinkList } from "./LinkListEdit";
import { NEWS_COMPONENT } from "./News";
import { NewsComponent } from "./NewsEdit";
import { RICH_TEXT_COMPONENT } from "./RichText";
import { RichTextComponent } from "./RichTextEdit";
import { SECTION_COMPONENT } from "./Section";
import { SectionComponent } from "./SectionEdit";
import { TABS_COMPONENT } from "./Tabs";
import { TabsComponent } from "./TabsEdit";
import { UnknownComponent } from "./UnknownEdit";
import { VIDEO_COMPONENT } from "./video/Video";
import { VideoComponent } from "./video/VideoEdit";
import { CONTAINER_COMPONENT } from "./Container";
import { ContainerComponent } from "./ContainerEdit";

// Resolves complicated circular dependency
let componentCache: any = null;

function initComponentCache() {
  componentCache = {
    [COLUMN_LAYOUT]: ColumnLayoutComponent,
    [LINK_LIST]: LinkList,
    [PAGE_NORMAL]: PageNormal,
    [PAGE_HOME]: PageHome,
    [SECTION_COMPONENT]: SectionComponent,
    [FILES_COMPONENT]: FilesComponent,
    [RICH_TEXT_COMPONENT]: RichTextComponent,
    [IMAGE_COMPONENT]: ImageComponent,
    [SITE_MAP]: SiteMapComponent,
    [TABS_COMPONENT]: TabsComponent,
    [IMAGE_STACK]: ImageStackComponent,
    [GALLERY]: GalleryComponent,
    [NEWS_COMPONENT]: NewsComponent,
    [VIDEO_COMPONENT]: VideoComponent,
    [CONTAINER_COMPONENT]: ContainerComponent,
  };
}

export function getComponent(
  type: ComponentTypes
): IEditableComponent<ComponentSpec> {
  if (componentCache === null) {
    initComponentCache();
  }
  // This is perfectly type-safe, but TS can't see control-flow dependant types and thus can't
  // understand that the map access above acts as a smart form of type refinement.
  if (componentCache[type] === undefined) {
    return UnknownComponent as any;
  }
  return componentCache[type] as any;
}

// User-defined type guard to get TS to propagate the filter
function isNotUndefined<T>(item: T | undefined): item is T {
  return item !== undefined;
}

export function getNewComponents(): React.FC<{}>[] {
  if (componentCache === null) {
    initComponentCache();
  }
  return (
    Object.keys(componentCache)
      // TS is not smart enough to type Object.keys on known objects correctly
      .map((str) => (str as any) as ComponentTypes)
      .map((key) => componentCache[key].new)
      .filter(isNotUndefined)
  );
}
