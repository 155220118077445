import { faImages } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { arrayMoveMutable } from "array-move";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useDrag } from "react-dnd";
import { useDropzone } from "react-dropzone";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import styled from "styled-components";
import { GENERIC_COMPONENT } from "./Component";
import { ComponentItemProps, DefaultItem } from "./ComponentTree";
import { DragSpec, IEditableComponent } from "./EditableComponent";
import { NewItem } from "./NewComponents";
import {
  ImageContainer,
  ImagePlaceholder,
  ImageStackSpec,
  IMAGE_STACK,
} from "./ImageStack";
import { getAppropriateImage, Image } from "./GetImage";
import { ImageRenderer } from "./ImageRenderer";

const sortContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const EditComponent = observer((props: { spec: ImageStackSpec }) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach(async (f) => {
      const url = await (await fetch("/api/upload")).text();
      // TODO: Placeholder, retry and status
      const res = await fetch(url, { method: "PUT", body: f });
      // TODO: check res
      console.log(res);
      const downloadURL = url.split("?")[0];
      props.spec.images.push({
        baseURL: downloadURL,
      });
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
  });
  if (props.spec.images.length >= 1) {
    return (
      <ImageContainer>
        {props.spec.images.length > 2 ? (
          <picture
            style={{ height: "25px", width: "80%", opacity: 0.4, zIndex: 0 }}
          >
            <ImageRenderer image={props.spec.images[2]} />
          </picture>
        ) : null}
        {props.spec.images.length > 1 ? (
          <picture
            style={{ height: "25px", width: "90%", opacity: 0.8, zIndex: 1 }}
          >
            <ImageRenderer image={props.spec.images[1]} />
          </picture>
        ) : null}
        <picture style={{ opacity: 1, zIndex: 2 }}>
          <ImageRenderer image={props.spec.images[0]} />
        </picture>
      </ImageContainer>
    );
  }
  return (
    <ImagePlaceholder {...getRootProps()}>
      <div>
        <div>
          <FontAwesomeIcon
            icon={faImages}
            size="3x"
            style={{ marginBottom: "10px" }}
          />
        </div>
        <div>Select or drag images here</div>
        <input {...getInputProps()} />
      </div>
    </ImagePlaceholder>
  );
});

const SortablePhotoContainer = SortableContainer<any>(sortContainer);

const SortablePhoto = SortableElement<any>(
  observer((props: { image: Image }) => {
    return (
      <div>
        <img
          src={getAppropriateImage(props.image, 0, 80)}
          style={{ height: "80px" }}
          alt=""
        />
      </div>
    );
  })
);

const Properties = observer((props: { spec: ImageStackSpec }) => {
  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: any) => {
      runInAction(() => {
        arrayMoveMutable(props.spec.images, oldIndex, newIndex);
      });
    },
    [props.spec.images]
  );
  return (
    <div>
      <SortablePhotoContainer onSortEnd={onSortEnd} axis="xy">
        {props.spec.images.map((img, i) => (
          <SortablePhoto image={img} index={i} />
        ))}
      </SortablePhotoContainer>
    </div>
  );
});

const Item = observer((props: ComponentItemProps<ImageStackSpec>) => {
  return <DefaultItem {...props} icon={faImages} name="Image Stack" />;
});

const New = (props: {}) => {
  const [, drag] = useDrag<DragSpec, unknown, unknown>(() => ({
    type: GENERIC_COMPONENT,
    item: {
      spec: {
        type: IMAGE_STACK,
        images: [],
      },
    },
  }));
  return (
    <NewItem
      isActive={false}
      ref={(elem) => {
        drag(elem);
      }}
    >
      <FontAwesomeIcon icon={faImages} />
      <span> Image Stack </span>
    </NewItem>
  );
};

export const ImageStackComponent: IEditableComponent<ImageStackSpec> = {
  component: EditComponent,
  properties: Properties,
  item: Item,
  new: New,
};
