import React from "react";

const PresseUndDownloadsPage = () => {
  return (
    <div className="container mb-5 pt-5">
      <h1 className="text-primary mb-4">Presse und Downloads</h1>

      <div className="row">
        <div className="col-12">
          <h2>Downloads</h2>

          <div className="mt-4">
            <h3>LWZO Logo</h3>
            <ul>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/user_upload/20180216_LWZO_Logo_mit_Text-links_940x163.png">
                  Logo ohne Hintergrund (Format PNG, 940x163)
                </a>
              </li>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/user_upload/20180216_LWZO_Logo_mit_Text-links_Hintergrund_940x163.png">
                  Logo mit Hintergrund (Format PNG, 940x163)
                </a>
              </li>
            </ul>

            <h3>LWZO 2024</h3>
            <ul>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/user_upload/20240304_Flyer_Save_the_date.pdf">
                  20240304_Flyer_Save_the_date.pdf
                </a>
              </li>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/user_upload/20240502_Ausschreibung.pdf">
                  20240502_Ausschreibung.pdf
                </a>
              </li>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2024/Flyer_A5_BruefsLAB_2024.pdf">
                  20240625_Flyer A5 BruefsLAB 2024
                </a>
              </li>
            </ul>
          </div>

          <h2 className="mt-5">Medienbereichte</h2>

          <div className="mt-4">
            <h3>2023</h3>
            <ul>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2023/Auszug_Zuercher_Oberlaender_-_Seite_5_vom_15112023.pdf">
                  Auszug Zürcher Oberländer, Seite 5 vom 15.11.2023
                </a>
              </li>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2023/Onlineartikel_von_Zuerioberland24_vom_13.11.2023.pdf">
                  Onlineartikel von Zürioberland24 vom 13.11.2023
                </a>
              </li>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2023/20231112_zuerioberland24.pdf">
                  Onlineartikel von Zürioberland24 vom 12.11.2023
                </a>
              </li>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2023/Zuerioberland_Magazin_02_2023_Auszug_LWZO.pdf">
                  Auszug MAGAZIN Zürioberland, Seite 25 und 26 vom 27.10.2023
                </a>
              </li>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/user_upload/20231006_wetzikerpost_01_2023__Auszug_mit_LWZO_Inhalt_.pdf">
                  Auszug Wetziker Post, Seite 1 bis 3 vom 6.10.2023
                </a>
              </li>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2023/20230414_Regionalwirtschaft.pdf">
                  Auszug Regionalwertschaft, Seite 10 vom 14.04.2023
                </a>
              </li>
            </ul>

            <h3>2022</h3>
            <ul>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2022/Medienberichte/LWZO_2022_-_Wirtschaftsraum_Zuerich.pdf">
                  Artikel der Webseite Wirtschaftsraum Zürich vom 25.11.2022
                </a>
              </li>
              <li>
                <a
                  href="https://lehrlingswettbewerb.ch/https://lehrlingswettbewerb.ch/fileadmin/lwzo/2022/Medienberichte/20220506_Auszug_Regionalwirtschaft_Seite_8_unten.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Auszug Regionalwertschaft, Seite 8 vom 06.05.2022
                </a>
              </li>
            </ul>

            <h3>2021</h3>
            <ul>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2021/Zuercher_Oberlaender_Samstag_2._Oktober_2021_-_2.pdf">
                  Regionalverein will Lehrlingswettbewerb verbessen am 2.10.2024
                </a>
              </li>
            </ul>

            <h3>2020</h3>
            <ul>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2020/20201029_02_zol_60.pdf">
                  Statt in der Turnhalle präsentieren Lehrlinge ihre Projekte
                  nun online vom 29.10.2020
                </a>
              </li>
            </ul>

            <h3>2019</h3>
            <ul>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2019/05_zol_09_2019-11-13.pdf">
                  Artikel Spitaldirektor lobt kreative Lernende vom 13.11.2019
                </a>
              </li>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2019/07_zol_13_2019-11-08.pdf">
                  Artikel Voller Stolz auf den eigenen Lehrberuf vom 08.11.2019
                </a>
              </li>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2019/09_zol_13_2019-11-04.pdf">
                  Erfahrungen für die berufliche Zukunft sammeln vom 01.11.2019
                </a>
              </li>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2019/09_zol_13_2019-11-09.pdf">
                  Berufslehre oder Gymi? Sie wissen es vom 09.11.2019
                </a>
              </li>
            </ul>

            <h3>2018</h3>
            <ul>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2016/20181113_ZO_Beitrag.pdf">
                  Artikel Zürcher Oberländer vom 13.11.2018
                </a>
              </li>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2016/20181110_Zeitungsbericht_RegionalWirtschaft.pdf">
                  Regional Wirtschaft vom 13.11.2018
                </a>
              </li>
            </ul>

            <h3>2017</h3>
            <ul>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2017/Lehrlingswettbewerb_mit_STOKYS.pdf">
                  Lehrlingswettbewerb mit STOKYS
                </a>
              </li>
            </ul>

            <h3>2016</h3>
            <ul>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2016/20171015___zueriost.pdf">
                  Gelungene Premiere in der Ustermer Landihalle vom 05.11.2019
                </a>
              </li>
            </ul>

            <h3>2011</h3>
            <ul>
              <li>
                <a href="https://lehrlingswettbewerb.ch/fileadmin/lwzo/2011/20111031_Zeitungsbereicht_in_der_NZZ.pdf">
                  Zeitungsbericht in der NZZ
                </a>
              </li>
            </ul>

            <h3>2010</h3>
            <ul>
              <li>
                <a
                  href="https://www.srf.ch/play/tv/schweiz-aktuell/video/lehrlinge-bauen-kuh-und-haus?urn=urn:srf:video:39753093-2b46-42b6-bb5c-f84a60ce0e77"
                  target="_blank"
                  rel="noreferrer"
                >
                  Schweiz aktuell am 11.11.2010
                </a>
              </li>
            </ul>

            <h3>älter</h3>
            <ul>
              <li>
                <a
                  href="https://www.srf.ch/play/tv/antenne/video/lehrlings-wettbewerb?urn=urn:srf:video:7e164eea-fb83-47bb-b166-8c7e197fd643"
                  target="_blank"
                  rel="noreferrer"
                >
                  Lehrlings-Show in Zoffingen vom 25.10.1965
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresseUndDownloadsPage;