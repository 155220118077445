import React, { useState } from "react";
import styled from "styled-components";
import { IComponent } from "./Component";
import { Vertical, VerticalSpec } from "./Vertical";

export const TABS_COMPONENT = "tabs";

export interface TabSpec {
  name: string;
  content: VerticalSpec;
}
export interface TabsSpec {
  type: typeof TABS_COMPONENT;
  tabs: TabSpec[];
}

export const TabsContainer = styled.div`
  margin-top: 15px;
`;

export const BaseTabsHeader = styled.div`
  display: flex;
  overflow-x: auto;
`;

export const TabContent = styled.div`
  background-color: white;
  padding: 20px;
  padding-top: 5px;
`;

export const TabItemContainer = styled.div<{ selected: boolean }>`
  background-color: ${(props) => (props.selected ? `white` : `inherit`)};
  padding: 10px;
  border-top: ${(props) => (props.selected ? `1px solid blue` : `none`)};
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: ${(props) => (props.selected ? `inherit` : `pointer`)};
`;

const Tab = (props: {
  spec: TabSpec;
  selected: boolean;
  onClick?: () => void;
}) => {
  return (
    <TabItemContainer selected={props.selected} onClick={props.onClick}>
      {props.spec.name}
    </TabItemContainer>
  );
};

const Component = (props: { spec: TabsSpec }) => {
  const [selectedTab, setSelectedTab] = useState(props.spec.tabs[0]);

  return (
    <TabsContainer>
      <BaseTabsHeader>
        {props.spec.tabs.map((tab) => (
          <Tab
            spec={tab}
            selected={selectedTab === tab}
            onClick={() => setSelectedTab(tab)}
          />
        ))}
      </BaseTabsHeader>
      <TabContent>
        <Vertical spec={selectedTab.content} />
      </TabContent>
    </TabsContainer>
  );
};

export const TabsComponent: IComponent<TabsSpec> = {
  component: Component,
};
