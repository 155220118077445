import { faSpinner, faTimes, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { GENERIC_COMPONENT } from "../Component";
import { ComponentItemProps, DefaultItem } from "../ComponentTree";
import { DragSpec, IEditableComponent } from "../EditableComponent";
import { NewItem } from "../NewComponents";
import { BarNumberProperty, BarTextProperty } from "../PropertiesBar";
import {
  VideoContainer,
  VideoPlaceholder,
  VideoSpec,
  VIDEO_COMPONENT,
} from "./Video";
import ShakaPlayer from "./shaka-player";
import { processVideo } from "../../api";
import { useLocation } from "../../../utils/router";

export interface processStatus {
  status: "done" | "processing" | "error" | "manual";
  error?: string;
}

// check if manifest
// check if id
// check if mpd file
// check if error
// magic

export const EditComponent = observer((props: { spec: VideoSpec }) => {
  const location = useLocation();
  const [state, setState] = useState<
    "done" | "processing" | "error" | "manual" | "empty"
  >("empty");

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setState("processing");
    acceptedFiles.forEach(async (f) => {
      const url = await (await fetch("/api/upload")).text();
      // TODO: Placeholder, retry and status
      const res = await fetch(url, { method: "PUT", body: f });
      // TODO check res
      console.log(res);
      const downloadURL = url.split("?")[0];
      props.spec.videoURL = downloadURL;

      const id = downloadURL.substring(downloadURL.lastIndexOf("/") + 1);
      processVideo(location, downloadURL, id).catch((err) => {
        console.log(err);
      });
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "video/*": [] },
    multiple: false,
    maxFiles: 1,
  });

  useEffect(() => {
    // check if manual manifest is specified
    if (props.spec.dashManifest) {
      setState("manual");
    } else if (props.spec.videoURL) {
      let unloaded = false;
      const checkStatus = () => {
        // check if file is uploaded
        fetch(props.spec.videoURL + ".mpd")
          .then((res) => {
            // check if processed
            if (res.status === 200) {
              setState("done");
            } else {
              // check if error
              fetch(props.spec.videoURL + ".error")
                .then((res) => {
                  if (unloaded) {
                    return;
                  }
                  if (res.status === 200) {
                    setState("error");
                  } else if (res.status === 404) {
                    setState("processing");
                    setTimeout(checkStatus, 10 * 1000);
                  } else {
                    setState("error");
                    console.log("request error");
                  }
                })
                .catch((err) => {
                  setState("error");
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            setState("error");
            console.log(err);
          });
      };
      checkStatus();
      return () => {
        unloaded = true;
      };
    }
  }, [props.spec.dashManifest, props.spec.videoURL]);

  switch (state) {
    case "manual":
      return (
        <VideoContainer
          maxWidth={props.spec.customMaxWidth}
          maxHeight={props.spec.customMaxHeight}
        >
          <ShakaPlayer src={props.spec.dashManifest} />
        </VideoContainer>
      );
    case "done":
      return (
        <VideoContainer
          maxWidth={props.spec.customMaxWidth}
          maxHeight={props.spec.customMaxHeight}
        >
          <ShakaPlayer src={props.spec.videoURL + ".mpd"} />
        </VideoContainer>
      );
    case "processing": // TODO spinner
      return (
        <VideoPlaceholder>
          <div>
            <div>
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="3x"
                style={{ marginBottom: "10px" }}
              />
            </div>
            <div>Das Video wird verarbeitet. Dies kann einige zeit dauern</div>
          </div>
        </VideoPlaceholder>
      );
    case "error": // TODO fix error
      return (
        <VideoPlaceholder>
          <div>
            <div>
              <FontAwesomeIcon
                icon={faTimes}
                size="3x"
                style={{ marginBottom: "10px" }}
              />
            </div>
            <div>Es ist ein Fehler beim Verarbeiten aufgetreten</div>
            <a href={(props.spec.videoURL ?? "") + ".error"}>
              Log Herunterladen
            </a>
          </div>
        </VideoPlaceholder>
      );
    case "empty":
      return (
        <VideoPlaceholder {...getRootProps()}>
          <div>
            <div>
              <FontAwesomeIcon
                icon={faVideo}
                size="3x"
                style={{ marginBottom: "10px" }}
              />
            </div>
            <div>Select or drag an Video here</div>
            <input {...getInputProps()} />
          </div>
        </VideoPlaceholder>
      );
    default:
      return <h1>Magic happened</h1>;
  }
});

const Properties = observer((props: { spec: VideoSpec }) => {
  return (
    <div>
      <BarNumberProperty
        name="Max With"
        value={props.spec.customMaxWidth ?? 0}
        onChange={(newVal) => (props.spec.customMaxWidth = newVal)}
      />
      <BarNumberProperty
        name="Max Height"
        value={props.spec.customMaxHeight ?? 0}
        onChange={(newVal) => (props.spec.customMaxHeight = newVal)}
      />
      <div>Achtung überschreibt hochgeladenes video:</div>
      <BarTextProperty
        name="Dash Manifest URL"
        value={props.spec.dashManifest ?? ""}
        onChange={(newValue) => (props.spec.dashManifest = newValue)}
      />
    </div>
  );
});

const Item = observer((props: ComponentItemProps<VideoSpec>) => {
  return <DefaultItem {...props} icon={faVideo} name="Video" />;
});

const New = () => {
  const [, drag] = useDrag<DragSpec, unknown, unknown>(() => ({
    type: GENERIC_COMPONENT,
    item: {
      spec: {
        type: VIDEO_COMPONENT,
        dashManifest: null,
        customMaxWidth: 0,
        customMaxHeight: 0,
        videoURL: null,
      },
    },
  }));
  return (
    <NewItem
      isActive={false}
      ref={(elem) => {
        drag(elem);
      }}
    >
      <FontAwesomeIcon icon={faVideo} />
      <span> Video </span>
    </NewItem>
  );
};

export const VideoComponent: IEditableComponent<VideoSpec> = {
  component: EditComponent,
  properties: Properties,
  item: Item,
  new: New,
};
