import { nanoid } from "nanoid";
import React from "react";
import styled from "styled-components";
import { ComponentSpec } from ".";
import { Component } from "./Component";

export const Container = styled.div`
  width: 100%;
`;

export interface VerticalSpec {
  id?: string;
  children: ComponentSpec[];
}

export const Vertical: React.FC<{ spec: VerticalSpec; name?: string }> = (
  props
) => {
  return (
    <Container key={props.spec.id}>
      {props.spec.children.map((spec, i) => (
        // Using i as a key is fine as the ID is specified above
        <div key={props.spec.id ? i : undefined}>
          <Component spec={spec} />
        </div>
      ))}
    </Container>
  );
};

export interface VerticalDI<T> {
  spec: T;
  Vertical: typeof Vertical;
}

export function verticalDI<T>(
  c: React.FC<VerticalDI<T>>,
  v: typeof Vertical
): React.FC<{ spec: T }> {
  return (props) => c({ spec: props.spec, Vertical: v });
}

export function newEmptyVertical() {
  return {
    children: [],
    id: nanoid(5),
  };
}
