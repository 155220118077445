import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faUserTie, 
  faTrophy, 
  faInfoCircle, 
  faChalkboardTeacher,
  faSearch,
  faUsers
} from "@fortawesome/free-solid-svg-icons";

const PageTitle = styled.h1`
  color: #007bff;
  margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h2`
  color: #007bff;
  font-size: 1.75rem;
  margin-bottom: 1rem;
`;

const FeatureCard = styled(Card)`
  height: 100%;
  transition: transform 0.2s ease-in-out;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const ContactCard = styled(Card)`
  height: 100%;
  border-left: 3px solid #007bff;
`;

const DasIstDerLWZOPage = () => {
  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col lg={10}>
          <PageTitle>Das ist der LWZO</PageTitle>
          
          <Card className="mb-4 shadow-sm">
            <Card.Body>
              <p className="lead mb-0">
                Der Lehrlingswettbewerb Züri-Oberland ist der spannendste, jährlich
                durchgeführte Berufsbildungsanlass in den Bezirken Hinwil, Pfäffikon und
                Uster.
              </p>
            </Card.Body>
          </Card>
          
          <Row xs={1} md={2} className="g-4 mb-5">
            <Col>
              <FeatureCard className="shadow-sm h-100">
                <Card.Body className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-3">
                    <FontAwesomeIcon icon={faTrophy} className="text-primary me-3" size="2x" />
                    <h3 className="h5 text-primary mb-0">
                      Präsentation der Wettbewerbsarbeiten
                    </h3>
                  </div>
                  <p className="mb-0">
                    von Lernenden aus verschiedensten Berufsfeldern, Firmen und
                    Lehrjahren
                  </p>
                </Card.Body>
              </FeatureCard>
            </Col>

            <Col>
              <FeatureCard className="shadow-sm h-100">
                <Card.Body className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-3">
                    <FontAwesomeIcon icon={faChalkboardTeacher} className="text-primary me-3" size="2x" />
                    <h3 className="h5 text-primary mb-0">
                      Einblick in Berufsarbeiten und Information
                    </h3>
                  </div>
                  <p className="mb-0">
                    über Berufe an Oberstufenschüler und -klassen für ihre
                    Berufswahlvorbereitungen
                  </p>
                </Card.Body>
              </FeatureCard>
            </Col>

            <Col>
              <FeatureCard className="shadow-sm h-100">
                <Card.Body className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-3">
                    <FontAwesomeIcon icon={faSearch} className="text-primary me-3" size="2x" />
                    <h3 className="h5 text-primary mb-0">
                      Schaufenster über Leistungsfähigkeit und Ideenreichtum
                    </h3>
                  </div>
                  <p className="mb-0">
                    junger Berufsleute für Lehrmeister, Eltern, Angehörige und die
                    interessierte Öffentlichkeit
                  </p>
                </Card.Body>
              </FeatureCard>
            </Col>

            <Col>
              <FeatureCard className="shadow-sm h-100">
                <Card.Body className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-3">
                    <FontAwesomeIcon icon={faInfoCircle} className="text-primary me-3" size="2x" />
                    <h3 className="h5 text-primary mb-0">
                      Standortbestimmung
                    </h3>
                  </div>
                  <p className="mb-0">
                    für die Teilnehmenden durch Bewertung ihrer Arbeiten von
                    Fachexperten
                  </p>
                </Card.Body>
              </FeatureCard>
            </Col>

            <Col>
              <FeatureCard className="shadow-sm h-100">
                <Card.Body className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-3">
                    <FontAwesomeIcon icon={faChalkboardTeacher} className="text-primary me-3" size="2x" />
                    <h3 className="h5 text-primary mb-0">
                      Ausstellungen und Informationsplattform
                    </h3>
                  </div>
                  <p className="mb-0">
                    für BIZ, Berufsverbände und Berufsfachschulen
                  </p>
                </Card.Body>
              </FeatureCard>
            </Col>

            <Col>
              <FeatureCard className="shadow-sm h-100">
                <Card.Body className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-3">
                    <FontAwesomeIcon icon={faUsers} className="text-primary me-3" size="2x" />
                    <h3 className="h5 text-primary mb-0">
                      Begegnung und Erfahrungsaustausch
                    </h3>
                  </div>
                  <p className="mb-0">
                    für Teilnehmende und Besucher
                  </p>
                </Card.Body>
              </FeatureCard>
            </Col>
          </Row>
          
          <Card className="shadow-sm mb-5">
            <Card.Body>
              <SectionTitle>Organisation</SectionTitle>
              <p className="mb-4">
                Der Verein Lehrlingswettbewerb Züri-Oberland organisiert sich zusammen
                mit den jährlich wechselnden Standorten aus den verschiedenen
                Bezirken.
              </p>
              
              <h3 className="h4 text-primary mb-4">Vorstand</h3>
              
              <Row xs={1} md={2} className="g-4">
                <Col>
                  <ContactCard className="shadow-sm">
                    <Card.Body>
                      <div className="d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={faUserTie} className="text-primary me-3" />
                        <h4 className="h5 text-primary mb-0">Präsidium</h4>
                      </div>
                      <p className="mb-1">Simon Boller</p>
                      <p className="mb-1">079 328 32 38</p>
                      <p className="mb-0">
                        <a href="mailto:simon.boller@lwzo.ch" className="text-decoration-none">
                          simon.boller@lwzo.ch
                        </a>
                      </p>
                    </Card.Body>
                  </ContactCard>
                </Col>
                
                <Col>
                  <ContactCard className="shadow-sm">
                    <Card.Body>
                      <div className="d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={faUserTie} className="text-primary me-3" />
                        <h4 className="h5 text-primary mb-0">Standortleitung Wetzikon</h4>
                      </div>
                      <p className="mb-1">Gerhard Bertschi (Geri)</p>
                      <p className="mb-0">
                        <a href="mailto:gerhard.bertschi@lwzo.ch" className="text-decoration-none">
                          gerhard.bertschi@lwzo.ch
                        </a>
                      </p>
                    </Card.Body>
                  </ContactCard>
                </Col>
                
                <Col>
                  <ContactCard className="shadow-sm">
                    <Card.Body>
                      <div className="d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={faUserTie} className="text-primary me-3" />
                        <h4 className="h5 text-primary mb-0">Standortleitung Uster</h4>
                      </div>
                      <p className="mb-1">Nicole Hirsig</p>
                      <p className="mb-0">
                        <a href="mailto:nicole.hirsig@lwzo.ch" className="text-decoration-none">
                          nicole.hirsig@lwzo.ch
                        </a>
                      </p>
                    </Card.Body>
                  </ContactCard>
                </Col>
                
                <Col>
                  <ContactCard className="shadow-sm">
                    <Card.Body>
                      <div className="d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={faUserTie} className="text-primary me-3" />
                        <h4 className="h5 text-primary mb-0">Standortleitung Pfäffikon</h4>
                      </div>
                      <p className="mb-1">Sabine Schneider</p>
                      <p className="mb-0">
                        <a href="mailto:sabine.schneider@lwzo.ch" className="text-decoration-none">
                          sabine.schneider@lwzo.ch
                        </a>
                      </p>
                    </Card.Body>
                  </ContactCard>
                </Col>
                
                <Col>
                  <ContactCard className="shadow-sm">
                    <Card.Body>
                      <div className="d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={faUserTie} className="text-primary me-3" />
                        <h4 className="h5 text-primary mb-0">Finanzen</h4>
                      </div>
                      <p className="mb-1">Kathrin Huber</p>
                      <p className="mb-0">
                        <a href="mailto:kathrin.huber@lwzo.ch" className="text-decoration-none">
                          kathrin.huber@lwzo.ch
                        </a>
                      </p>
                    </Card.Body>
                  </ContactCard>
                </Col>
                
                <Col>
                  <ContactCard className="shadow-sm">
                    <Card.Body>
                      <div className="d-flex align-items-center mb-3">
                        <FontAwesomeIcon icon={faUserTie} className="text-primary me-3" />
                        <h4 className="h5 text-primary mb-0">Eventsupport</h4>
                      </div>
                      <p className="mb-1">Wolfram Fiedler (Wolle)</p>
                      <p className="mb-0">
                        <a href="mailto:wolfram.fiedler@lwzo.ch" className="text-decoration-none">
                          wolfram.fiedler@lwzo.ch
                        </a>
                      </p>
                    </Card.Body>
                  </ContactCard>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DasIstDerLWZOPage;